import { Link } from 'react-router-dom';

import { TableCellWithTooltip, TableRow } from '@/components/ui/Table';
import { useGetVisionProduct } from '@/hooks/useGetVisionProduct';

import { extractedAttributes } from '../../utils/extractedAttributes';

import { ROUTE_PATHS } from '@/config/constants';

interface ProductRowProps {
  productId: number;
  externalProductId: string;
}

export function ProductRow({ productId, externalProductId }: ProductRowProps) {
  const { product } = useGetVisionProduct(productId);

  const {
    productName,
    altDescriptionProduct,
    imagesAltText,
    productDescription,
    description,
    productTags,
  } = extractedAttributes(product?.productAttributes ?? {});

  return (
    <TableRow>
      <TableCellWithTooltip>{externalProductId}</TableCellWithTooltip>

      <TableCellWithTooltip>
        {productName ?? product?.name}
      </TableCellWithTooltip>

      <TableCellWithTooltip>
        <Link
          to={`${ROUTE_PATHS.VISION_ENRICHMENT}/${product?.id}`}
          target="_blank"
        >
          <img src={product?.images[0]} alt={product?.name} />
        </Link>
      </TableCellWithTooltip>

      <TableCellWithTooltip>
        {productDescription ?? description}
      </TableCellWithTooltip>

      <TableCellWithTooltip>{altDescriptionProduct}</TableCellWithTooltip>

      <TableCellWithTooltip>{productTags}</TableCellWithTooltip>

      {imagesAltText.map((altText, index) => (
        <TableCellWithTooltip key={index}>
          {typeof altText === 'string' && altText}

          {typeof altText === 'object' &&
            product?.images.map((img) => {
              if (img !== altText.url) {
                return null;
              }

              return altText.description;
            })}
        </TableCellWithTooltip>
      ))}
    </TableRow>
  );
}
