import { useMutation } from '@tanstack/react-query';

import CustomerService, { CreateCustomerDto } from '@/services/CustomerService';

export function useCreateCustomer() {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (createCustomerDto: CreateCustomerDto) =>
      CustomerService.create(createCustomerDto),
  });

  return {
    createCustomer: mutateAsync,
    isLoading: isPending,
  };
}
