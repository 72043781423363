import { useMutation, useQueryClient } from '@tanstack/react-query';

import SeoService, { SeoResponse, UpdateSeoDto } from '@/services/SeoService';

import { GET_SEO_QUERY_KEY } from './useGetSeo';

export function useUpdateSeo() {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (updateSeoConfigDto: UpdateSeoDto) =>
      SeoService.updateSeoConfig(updateSeoConfigDto),
    onSuccess: (data) => {
      queryClient.setQueryData<SeoResponse>(GET_SEO_QUERY_KEY, data);
    },
  });

  return {
    updateSeo: mutateAsync,
    isLoading: isPending,
  };
}
