import {
  ImageAltTextName,
  VisionProductAttributes,
} from '@/services/ProductsService';

export function extractedAttributes(
  visionProductAttributes: VisionProductAttributes,
) {
  const productName = visionProductAttributes?.['seo_content']?.find(
    (attribute) => attribute.property === 'product_name',
  )?.name;

  const productDescription = visionProductAttributes?.['seo_content']?.find(
    (attribute) => attribute.property === 'product_description',
  )?.name;

  const description = Object.values(visionProductAttributes)
    .flat()
    .find(
      (item) =>
        item.property.toLowerCase() === 'descrição' ||
        item.property.toLowerCase() === 'description',
    )?.name;

  const altDescriptionProduct = visionProductAttributes?.['seo_content']?.find(
    (attribute) => attribute.property === 'product_alt_description',
  )?.name;

  const productTags =
    (
      visionProductAttributes?.['seo_content']?.find(
        (attribute) => attribute.property === 'product_tags',
      )?.name as string[] | undefined
    )?.join(', ') ?? [];

  const imagesAltText =
    (visionProductAttributes?.['seo_content']?.find(
      (attribute) => attribute.property === 'images_alt_text',
    )?.name as string[] | ImageAltTextName[] | undefined) ?? [];

  return {
    productName,
    productDescription,
    description,
    altDescriptionProduct,
    productTags,
    imagesAltText,
  };
}
