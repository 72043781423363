import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { FormGroup } from '@/components/FormGroup';
import { RequiredLabel } from '@/components/RequiredLabel';
import { Button } from '@/components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select';
import { Switch } from '@/components/ui/Switch';
import { useCreateCustomer } from '@/hooks/useCreateCustomer';
import { Language, Platform } from '@/services/CustomerService';

const customerSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  platform: z.nativeEnum(Platform),
  url: z.string().optional(),
  language: z.nativeEnum(Language),
  seoLanguage: z.nativeEnum(Language),
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
  freemiumMaxProductsPerCategory: z
    .string()
    .optional()
    .transform((value) => (value ? Number(value) : undefined))
    .refine((value) => value === undefined || value > 0, {
      message: 'The number must be greater than 0',
    }),
  freemiumMaxTotalProducts: z
    .string()
    .optional()
    .transform((value) => (value ? Number(value) : undefined))
    .refine((value) => value === undefined || value > 0, {
      message: 'The number must be greater than 0',
    }),
  freemium: z.boolean(),
  hubspotToken: z.string().optional(),
  vtexAppKey: z.string().optional(),
  vtexAppToken: z.string().optional(),
  vtexUrl: z.string().optional(),
  vtexSalesChannel: z
    .string()
    .optional()
    .transform((value) => (value ? Number(value) : undefined)),
  vtexAffiliateId: z.string().optional(),
});

type CustomerFormData = z.infer<typeof customerSchema>;

interface FormProps {
  onSuccess: (value: boolean) => void;
}

export function Form({ onSuccess }: FormProps) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<CustomerFormData>({
    resolver: zodResolver(customerSchema),
    defaultValues: {
      platform: Platform.NONE,
      language: Language['PT-BR'],
      seoLanguage: Language['PT-BR'],
      freemium: true,
    },
  });

  const platform = watch('platform');
  const freemium = watch('freemium');

  const { createCustomer, isLoading } = useCreateCustomer();

  const onSubmit = handleSubmit(async (data: CustomerFormData) => {
    try {
      await createCustomer({
        email: data.email,
        freemium: data.freemium,
        freemiumMaxProductsPerCategory: data.freemiumMaxProductsPerCategory,
        freemiumMaxTotalProducts: data.freemiumMaxTotalProducts,
        language: data.language,
        seoLanguage: data.seoLanguage,
        name: data.name,
        password: data.password,
        platform: data.platform,
        url: data.url,
        hubspotToken: data.hubspotToken,
        vtexAffiliateId: data.vtexAffiliateId,
        vtexAppKey: data.vtexAppKey,
        vtexAppToken: data.vtexAppToken,
        vtexSalesChannel: data.vtexSalesChannel,
        vtexUrl: data.vtexUrl,
      });

      onSuccess(true);
      reset();
    } catch {
      toast.error('Failed to create customer. Please try again.');
    }
  });

  useEffect(() => {
    if (platform !== Platform.VTEX) {
      setValue('vtexAffiliateId', undefined);
      setValue('vtexAppKey', undefined);
      setValue('vtexAppToken', undefined);
      setValue('vtexSalesChannel', undefined);
      setValue('vtexUrl', undefined);
    }
  }, [platform, setValue]);

  useEffect(() => {
    if (!freemium) {
      setValue('freemiumMaxProductsPerCategory', undefined);
      setValue('freemiumMaxTotalProducts', undefined);
    }
  }, [freemium, setValue]);

  return (
    <Card className="mx-auto w-full max-w-2xl">
      <CardHeader>
        <CardTitle>Create New Customer</CardTitle>
      </CardHeader>

      <CardContent>
        <form onSubmit={onSubmit} className="space-y-4">
          <FormGroup error={{ message: errors.name?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="name">Name</RequiredLabel>
            <Input id="name" {...register('name')} />
          </FormGroup>

          <FormGroup error={{ message: errors.platform?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="platform">Platform</RequiredLabel>

            <Controller
              name="platform"
              control={control}
              render={({ field }) => (
                <Select
                  defaultValue={field.value}
                  onValueChange={field.onChange}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select platform" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(Platform).map((platform) => (
                      <SelectItem key={platform} value={platform}>
                        {platform}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </FormGroup>

          <FormGroup error={{ message: errors.url?.message, font: 'sm' }}>
            <Label htmlFor="url">URL</Label>
            <Input id="url" {...register('url')} />
          </FormGroup>

          <FormGroup error={{ message: errors.language?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="language">Language</RequiredLabel>

            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select language" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(Language).map((lang) => (
                      <SelectItem key={lang} value={lang}>
                        {lang}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </FormGroup>

          <FormGroup
            error={{ message: errors.seoLanguage?.message, font: 'sm' }}
          >
            <RequiredLabel htmlFor="seoLanguage">Seo Language</RequiredLabel>

            <Controller
              name="seoLanguage"
              control={control}
              render={({ field }) => (
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select seo language" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(Language).map((lang) => (
                      <SelectItem key={lang} value={lang}>
                        {lang}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </FormGroup>

          <FormGroup error={{ message: errors.email?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="email">Email</RequiredLabel>
            <Input id="email" type="email" {...register('email')} />
          </FormGroup>

          <FormGroup error={{ message: errors.password?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="password">Password</RequiredLabel>
            <Input id="password" type="password" {...register('password')} />
          </FormGroup>

          <FormGroup className="flex items-center space-x-2">
            <Controller
              name="freemium"
              control={control}
              render={({ field }) => (
                <Switch
                  id="freemium"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <Label htmlFor="freemium">Freemium</Label>
          </FormGroup>

          {freemium && (
            <>
              <FormGroup
                error={{
                  message: errors.freemiumMaxProductsPerCategory?.message,
                  font: 'sm',
                }}
              >
                <Label htmlFor="freemiumMaxProductsPerCategory">
                  Freemium Max Products Per Category
                </Label>
                <Input
                  id="freemiumMaxProductsPerCategory"
                  type="number"
                  {...register('freemiumMaxProductsPerCategory')}
                />
              </FormGroup>

              <FormGroup
                error={{
                  message: errors.freemiumMaxTotalProducts?.message,
                  font: 'sm',
                }}
              >
                <Label htmlFor="freemiumMaxTotalProducts">
                  Freemium Max Total Products
                </Label>
                <Input
                  id="freemiumMaxTotalProducts"
                  type="number"
                  {...register('freemiumMaxTotalProducts')}
                />
              </FormGroup>
            </>
          )}

          <FormGroup
            error={{ message: errors.hubspotToken?.message, font: 'sm' }}
          >
            <Label htmlFor="hubspotToken">HubSpot Token</Label>
            <Input id="hubspotToken" {...register('hubspotToken')} />
          </FormGroup>

          {platform === Platform.VTEX && (
            <>
              <FormGroup
                error={{ message: errors.vtexAppKey?.message, font: 'sm' }}
              >
                <Label htmlFor="vtexAppKey">VTEX App Key</Label>
                <Input id="vtexAppKey" {...register('vtexAppKey')} />
              </FormGroup>

              <FormGroup
                error={{ message: errors.vtexAppToken?.message, font: 'sm' }}
              >
                <Label htmlFor="vtexAppToken">VTEX App Token</Label>
                <Input id="vtexAppToken" {...register('vtexAppToken')} />
              </FormGroup>

              <FormGroup
                error={{ message: errors.vtexUrl?.message, font: 'sm' }}
              >
                <Label htmlFor="vtexUrl">VTEX URL</Label>
                <Input id="vtexUrl" {...register('vtexUrl')} />
              </FormGroup>

              <FormGroup
                error={{
                  message: errors.vtexSalesChannel?.message,
                  font: 'sm',
                }}
              >
                <Label htmlFor="vtexSalesChannel">VTEX Sales Channel</Label>
                <Input
                  id="vtexSalesChannel"
                  type="number"
                  {...register('vtexSalesChannel')}
                />
              </FormGroup>

              <FormGroup
                error={{ message: errors.vtexAffiliateId?.message, font: 'sm' }}
              >
                <Label htmlFor="vtexAffiliateId">VTEX Affiliate ID</Label>
                <Input id="vtexAffiliateId" {...register('vtexAffiliateId')} />
              </FormGroup>
            </>
          )}

          <p className="mt-4 text-sm text-gray-500">
            <span className="text-red-500">*</span> Required field
          </p>
          <Button
            type="submit"
            className="w-full"
            disabled={isSubmitting || isLoading}
          >
            {(isSubmitting || isLoading) && (
              <Loader2 className="animate-spin" />
            )}

            {!isSubmitting && !isLoading && 'Create Customer'}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
