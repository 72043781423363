import { useMutation, useQueryClient } from '@tanstack/react-query';

import TaxonomiesService, {
  TaxonomyResponse,
  UpdateTaxonomyDto,
} from '@/services/TaxonomiesService';

import { GET_TAXONOMIES_BY_CATEGORY_QUERY_KEY } from './useGetTaxonomiesByCategory';

export function useUpdateTaxonomy(taxonomyId: number, category: string) {
  const queryClient = useQueryClient();

  const getTaxonomiesByCategoryQueryKey =
    GET_TAXONOMIES_BY_CATEGORY_QUERY_KEY(category);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (updateTaxonomyDto: UpdateTaxonomyDto) =>
      TaxonomiesService.updateTaxonomy(taxonomyId, updateTaxonomyDto),
    onMutate: (variables) => {
      const previousTaxonomy = queryClient.getQueryData<TaxonomyResponse[]>(
        getTaxonomiesByCategoryQueryKey,
      );

      queryClient.setQueryData<TaxonomyResponse[]>(
        getTaxonomiesByCategoryQueryKey,
        (state) =>
          state?.map((taxonomy) =>
            taxonomyId === taxonomy.id
              ? { ...taxonomy, examples: variables.examples }
              : taxonomy,
          ),
      );

      return { previousTaxonomy };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData<TaxonomyResponse[]>(
        getTaxonomiesByCategoryQueryKey,
        context?.previousTaxonomy,
      );
    },
  });

  return {
    updateTaxonomy: mutateAsync,
    isLoading: isPending,
  };
}
