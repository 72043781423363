import { useFormContext } from 'react-hook-form';

import { CardDescription } from '@/components/ui/Card';
import { Label } from '@/components/ui/Label';
import { Textarea } from '@/components/ui/Textarea';

import { AudienceFormData } from '..';

export function CustomPreferences() {
  const { register, watch } = useFormContext<AudienceFormData>();

  const inputType = watch('inputType');

  if (!inputType || inputType !== 'CUSTOM') {
    return null;
  }

  return (
    <div className="space-y-2">
      <Label htmlFor="preferences-input">Custom Preferences</Label>

      <CardDescription className="mb-2">
        Describe target behaviors or fashion preferences. Based on your
        description, we will identify relevant purchase behavior and design
        attributes to generate an audience and product sets.
      </CardDescription>

      <Textarea
        id="preferences-input"
        placeholder="Enter your preferences here..."
        className="min-h-[100px]"
        {...register('inputValue')}
      />
    </div>
  );
}
