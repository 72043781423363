import { cn } from '@/lib/utils';

import { AppliedFilters, CalculatedProducts } from '..';

import { ProductListCarousel } from './ProductListCarousel';
import { ProductListFilters } from './ProductListFilters';
import { ProductListModal } from './ProductListModal';
import { Dispatch, SetStateAction } from 'react';

interface ProductListProps {
  unchangeCalculatedProducts: CalculatedProducts;
  calculatedProducts: CalculatedProducts;
  appliedFilters: AppliedFilters;
  isFiltering: boolean;
  onFiltering: Dispatch<SetStateAction<boolean>>;
  onActive: (id: number, active: boolean) => void;
  onCalculatedProducts: (products: CalculatedProducts) => void;
  onAppliedFilters: Dispatch<SetStateAction<AppliedFilters>>;
  onResetAppliedFilters: VoidFunction;
}

export function ProductList({
  unchangeCalculatedProducts,
  calculatedProducts,
  appliedFilters,
  isFiltering,
  onFiltering,
  onActive,
  onCalculatedProducts,
  onAppliedFilters,
  onResetAppliedFilters,
}: ProductListProps) {
  const inactiveProductsQuantity = calculatedProducts.filter(
    (products) => !products.active,
  ).length;

  function handleUndoProduct(productId: number) {
    onActive(productId, true);
  }

  function handleRemoveProduct(productId: number) {
    onActive(productId, false);
  }

  return (
    <div className="w-full space-y-6">
      <div className="mb-4 flex items-center justify-between">
        <div className="space-y-1">
          <h2 className="text-2xl font-semibold">Products in Set</h2>

          <div className="flex gap-2 text-sm text-muted-foreground">
            <span
              className={cn(inactiveProductsQuantity > 0 && 'line-through')}
            >
              {calculatedProducts.length} products
            </span>

            {inactiveProductsQuantity > 0 && (
              <span className="font-bold">
                {calculatedProducts.length - inactiveProductsQuantity} products
              </span>
            )}
          </div>
        </div>

        <div className="flex gap-2">
          <ProductListFilters
            unchangeCalculatedProducts={unchangeCalculatedProducts}
            appliedFilters={appliedFilters}
            isFiltering={isFiltering}
            onFiltering={onFiltering}
            onCalculatedProducts={onCalculatedProducts}
            onAppliedFilters={onAppliedFilters}
            onResetAppliedFilters={onResetAppliedFilters}
          />

          <ProductListModal
            calculatedProducts={calculatedProducts}
            isFiltering={isFiltering}
            onUndo={handleUndoProduct}
            onRemove={handleRemoveProduct}
          />
        </div>
      </div>

      <ProductListCarousel
        calculatedProducts={calculatedProducts}
        isFiltering={isFiltering}
        onUndo={handleUndoProduct}
        onRemove={handleRemoveProduct}
      />
    </div>
  );
}
