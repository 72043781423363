import { useStylistAiContext } from '../../contexts/StylistAiContext';

import { FiltersCard } from './FiltersCard';

export function Filters() {
  const { queryText, hasProducts } = useStylistAiContext();

  if (!queryText || !hasProducts) {
    return null;
  }

  return <FiltersCard />;
}
