import * as Sentry from '@sentry/react';
import { Frown } from 'lucide-react';
import { Component, ReactNode } from 'react';

import { ROUTE_PATHS } from '@/config/constants';

import { Button } from './ui/Button';

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  handleReset = () => {
    this.setState((state) => ({ ...state, hasError: false }));
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100">
          <div className="px-4 text-center">
            <div className="gap- mb-2 flex items-center justify-center gap-3 text-red-600">
              <h1 className="text-4xl font-bold">An error occurred</h1>
              <Frown size={30} />
            </div>

            <p className="mb-8 text-xl text-gray-600">
              We&apos;re sorry, but something went wrong on our end.
            </p>

            <div className="space-x-2">
              <Button onClick={this.handleReset}>Try again</Button>

              <Button variant="outline" onClick={this.handleReset} asChild>
                <a href={ROUTE_PATHS.HOME}>Return to Home</a>
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
