import { FashionaiLogo } from '@/components/FashionaiLogo';
import { Form } from './components/Form';

export function Login() {
  return (
    <div className="flex h-full w-full flex-col items-center">
      <header>
        <h1>
          <FashionaiLogo />
          <span className="sr-only">FashionAI</span>
        </h1>
      </header>

      <section className="flex w-full max-w-[402px] flex-1 flex-col items-center justify-center gap-[10px] px-[10px]">
        <div className="w-full">
          <Form />
        </div>
      </section>

      <footer>
        <p className="text-sm">
          Need an account?{' '}
          <a href="" className="underline hover:opacity-80">
            Contact Sales
          </a>
        </p>
      </footer>
    </div>
  );
}
