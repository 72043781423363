import { Form } from './Form';
import { Guide } from './Guide';
import { Tab } from '../Tab';

export function HubSpot() {
  return (
    <Tab
      title="HubSpot Settings"
      description="Manage your HubSpot integration settings and configure private apps"
    >
      <Guide />
      <Form />
    </Tab>
  );
}
