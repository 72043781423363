import { useState } from 'react';
import { toast } from 'sonner';
import { AxiosError } from 'axios';

import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { env } from '@/config/env';

import { Check, Copy } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { useGenerateVtexAffiliateURL } from '@/hooks/useGenerateVtexAffiliateUrl';

export function AffiliateURL() {
  const [isCopied, setIsCopied] = useState(false);

  const { user } = useAuth();

  const affiliateUrl = `${env.CATALOG_API}${user?.customer.vtexAffiliateUrl}`;

  const { generateVtexAffiliateURL, isLoading } = useGenerateVtexAffiliateURL();

  const isFreemium = user?.customer.freemium;

  async function handleGenerateVtexAffiliateURL() {
    try {
      await generateVtexAffiliateURL();
    } catch (error) {
      if (isFreemium && error instanceof AxiosError) {
        if (error.response?.status === 403) {
          toast.error('This feature is not available for freemium customers.');
          return;
        } else {
          toast.error('Error generate affiliate url.');
          return;
        }
      }
      toast.error('Error generate affiliate url.');
    }
  }

  function handleCopyVtexAffiliateUrl() {
    setIsCopied(true);
    navigator.clipboard.writeText(affiliateUrl);
    toast.success('Copied to clipboard!');
  }

  return (
    <div className="inline-flex items-center gap-2">
      <strong className="text-nowrap">Search Endpoint:</strong>

      {user?.customer.vtexAffiliateUrl && (
        <>
          <Input
            className="w-[360px]"
            value={affiliateUrl}
            onChange={() => {}}
          />

          <Button onClick={handleCopyVtexAffiliateUrl}>
            {isCopied ? <Check /> : <Copy />}
          </Button>
        </>
      )}

      {!user?.customer.vtexAffiliateUrl && (
        <Button disabled={isLoading} onClick={handleGenerateVtexAffiliateURL}>
          {isLoading ? 'Generating...' : 'Generate Link'}
        </Button>
      )}
    </div>
  );
}
