import { useQuery } from '@tanstack/react-query';

import AudiencesService from '@/services/AudiencesService';

export const GET_AUDIENCE_PRODUCTS_QUERY_KEY = (audienceId: number) => [
  'audience-products',
  audienceId,
];

export function useGetAudienceProducts(audienceId: number) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: GET_AUDIENCE_PRODUCTS_QUERY_KEY(audienceId),
    enabled: false,
    queryFn: () => AudiencesService.getAudienceProducts(audienceId),
  });

  return {
    audienceProducts: data,
    isLoading: isFetching,
    getAudienceProducts: refetch,
  };
}
