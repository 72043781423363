import { Search } from 'lucide-react';
import { ChangeEvent, FormEvent, useState } from 'react';

import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';

import { useStylistAiContext } from '../contexts/StylistAiContext';

export function SearchForm() {
  const [text, setText] = useState('');

  const {
    isLoading,
    queryText,
    setQueryTextCallback,
    setSelectedAttributesCallback,
    setSelectedCategoriesCallback,
  } = useStylistAiContext();

  const buttonIsDisabled = !text || isLoading || text === queryText;

  function onSetText(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setText(value);
  }

  function handleSearch(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setQueryTextCallback(text);
    setSelectedAttributesCallback([]);
    setSelectedCategoriesCallback([]);
  }

  return (
    <form className="flex gap-4" onSubmit={handleSearch}>
      <Input
        placeholder="What are you looking for?"
        className="flex-1"
        value={text}
        onChange={(event) => onSetText(event)}
      />

      <Button type="submit" disabled={buttonIsDisabled}>
        <Search className="mr-2 h-4 w-4" />
        {isLoading ? 'Searching...' : 'Search'}
      </Button>
    </form>
  );
}
