import { ReactNode } from 'react';

import { Label } from './ui/Label';

interface RequiredLabelProps {
  htmlFor: string;
  children: ReactNode;
}

export function RequiredLabel({ children, htmlFor }: RequiredLabelProps) {
  return (
    <Label htmlFor={htmlFor} className="flex items-center">
      {children}
      <span className="ml-1 text-red-500">*</span>
    </Label>
  );
}
