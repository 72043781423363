import { useGetCustomersInfo } from '@/hooks/useGetCustomersInfo';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/Select';
import { SelectProps } from '@radix-ui/react-select';

export function CustomerSelect(props: SelectProps) {
  const { customersInfo } = useGetCustomersInfo();

  const sortedCustomers = [...customersInfo].sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
  );

  return (
    <Select {...props}>
      <SelectTrigger>
        <SelectValue placeholder="Select customer" />
      </SelectTrigger>

      <SelectContent>
        {sortedCustomers.map((customer) => (
          <SelectItem key={customer.id} value={customer.id.toString()}>
            {customer.name} ({customer.id})
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
