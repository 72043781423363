import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { FormGroup } from '@/components/FormGroup';
import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { useAuth } from '@/contexts/AuthContext';
import { useUpdateHubspotToken } from '@/hooks/useUpdateHubspotToken';

const formSchema = z.object({
  token: z.string().min(1, { message: 'Token is required' }),
});

type FormData = z.infer<typeof formSchema>;

export function Form() {
  const { user } = useAuth();

  const [isEditable, setIsEditable] = useState<boolean>(() => {
    return !user?.customer.hubspotToken;
  });

  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const { updateHubspotToken } = useUpdateHubspotToken();

  function handleEdit() {
    setValue('token', '');
    setIsEditable(true);
  }

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateHubspotToken({
        hubspotToken: data.token,
      });

      toast.success('Successfully updated HubSpot Token.');
      setIsEditable(false);
    } catch {
      toast.error('Error updating HubSpot Token.');
    }
  });

  useEffect(() => {
    if (!isEditable) {
      setValue('token', '************************');
    }
  }, [isEditable, setValue]);

  return (
    <form
      className="flex h-full flex-col space-y-6"
      onSubmit={onSubmit}
      noValidate
    >
      <FormGroup
        className="space-y-2"
        error={{ message: errors.token?.message, font: 'sm' }}
      >
        <Label htmlFor="token">Token</Label>

        <Input
          id="token"
          type="password"
          placeholder="Enter your token"
          className="w-full"
          disabled={!isEditable}
          {...register('token')}
        />
      </FormGroup>

      {isEditable && (
        <Button
          type="submit"
          className="mt-auto w-full bg-black text-white transition-colors hover:bg-gray-800"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save HubSpot Token'}
        </Button>
      )}

      {!isEditable && (
        <Button
          type="button"
          className="mt-auto w-full bg-black text-white transition-colors hover:bg-gray-800"
          onClick={handleEdit}
        >
          Edit
        </Button>
      )}
    </form>
  );
}
