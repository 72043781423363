import { FormGroup } from '@/components/FormGroup';
import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { useAuth } from '@/contexts/AuthContext';
import { useUpdateStoreURL } from '@/hooks/useUpdateStoreURL';
import { zodResolver } from '@hookform/resolvers/zod';
import { Globe } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

const formSchema = z.object({
  url: z.string().min(1, { message: 'URL is required' }).url('Invalid URL'),
});

type FormData = z.infer<typeof formSchema>;

export function StoreForm() {
  const { user } = useAuth();

  const customerUrl = user?.customer.url;

  const [isEditable, setIsEditable] = useState(() => !customerUrl);

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: customerUrl ?? '',
    },
  });

  const { updateStoreURL } = useUpdateStoreURL();

  const onSubmit = handleSubmit(async ({ url }) => {
    try {
      await updateStoreURL({
        storeURL: url,
      });

      toast.success('Successfully updated Store URL.');
      setIsEditable(false);
    } catch {
      toast.error('Error updating Store URL.');
    }
  });

  return (
    <div className="space-y-2">
      {(!customerUrl || isEditable) && (
        <form className="space-y-2" onSubmit={onSubmit}>
          <FormGroup
            className="space-y-2"
            error={{ message: errors.url?.message, font: 'sm' }}
          >
            <Label htmlFor="storeURL" className="text-lg font-semibold">
              Store URL
            </Label>

            <Input
              id="storeURL"
              placeholder="Enter Store URL"
              disabled={!isEditable}
              {...register('url')}
            />

            {!errors.url?.message && (
              <p className="text-sm text-muted-foreground">
                The public URL of your VTEX store, where users can browse
                products and make purchases.
              </p>
            )}
          </FormGroup>

          <div className="space-x-2">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>

            {customerUrl && (
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsEditable(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      )}

      {customerUrl && !isEditable && (
        <div className="flex items-center gap-2">
          <div className="flex items-center space-x-2">
            <Globe className="h-4 w-4 text-muted-foreground" />

            <a
              href={user.customer.url}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-muted-foreground"
            >
              {user?.customer.url}
            </a>
          </div>

          <Button size="sm" onClick={() => setIsEditable(true)}>
            Edit
          </Button>
        </div>
      )}
    </div>
  );
}
