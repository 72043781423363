import { useQuery } from '@tanstack/react-query';

import FeedbacksService from '@/services/FeedbacksService';

export const GET_FEEDBACK_BY_PRODUCT_ID_QUERY_KEY = (productId: number) => [
  'feedback',
  productId,
];

export function useGetFeedbackByProductId(productId: number) {
  const { data, isFetching } = useQuery({
    queryKey: GET_FEEDBACK_BY_PRODUCT_ID_QUERY_KEY(productId),
    queryFn: () => FeedbacksService.getFeedbackByProductId(productId),
  });

  return {
    feedback: data ?? [],
    isLoading: isFetching,
  };
}
