import { zodResolver } from '@hookform/resolvers/zod';
import { Facebook, LoaderCircle, LogOut, Pencil, Save } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/Card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select';
import { useAuth } from '@/contexts/AuthContext';
import { useGetMetaAddAccounts } from '@/hooks/useGetMetaAdAccounts';
import { useGetMetaBusinessAccounts } from '@/hooks/useGetMetaBusinessAccounts';
import { useGetMetaCatalog } from '@/hooks/useGetMetaCatalog';
import { useGetMetaPages } from '@/hooks/useGetMetaPages';
import { useMetaConfig } from '@/hooks/useMetaConfig';
import { useMetaDisconnect } from '@/hooks/useMetaDisconnect';

const metaFormSchema = z.object({
  businessAccountId: z
    .string()
    .min(1, { message: 'Business Account ID is required.' }),
  catalogAccountId: z
    .string()
    .min(1, { message: 'Catalog Account ID is required.' }),
  page: z.string().min(1, { message: 'Page is required.' }),
  adAccount: z.string().min(1, { message: 'Ad Account is required.' }),
});

type MetaFormData = z.infer<typeof metaFormSchema>;

interface MetaAuthenticatedProps {
  customerBusinessAccountId: string | undefined;
  onDisconnect: () => void;
}

export function MetaAuthenticated({
  customerBusinessAccountId,
  onDisconnect,
}: MetaAuthenticatedProps) {
  const [isEditable, setIsEditable] = useState(true);

  const [businessAccountState, setBusinessAccountState] = useState(() => {
    return customerBusinessAccountId ?? '';
  });

  const { user } = useAuth();

  const { metaBusinessAccounts, isLoading: isMetaBusinessAccountsLoading } =
    useGetMetaBusinessAccounts();

  const {
    metaCatalog,
    isLoading: isMetaCatalogLoading,
    getMetaCatalog,
  } = useGetMetaCatalog(
    businessAccountState,
    !!user?.customer?.meta_business_id,
  );

  const { metaPages } = useGetMetaPages();

  const { metaAdAccounts } = useGetMetaAddAccounts();

  const { metaConfig } = useMetaConfig();

  const { metaDisconnect } = useMetaDisconnect();

  const businessAccountDefaultValue = metaBusinessAccounts.find(
    (businessAccount) => businessAccount.id === user?.customer.meta_business_id,
  )?.id;

  const catalogAccountDefaultValue = metaCatalog.find(
    (catalog) => catalog.id === user?.customer.meta_catalog_id,
  )?.id;

  const pageDefaultValue = metaPages.find(
    (page) => page.id === user?.customer.meta_page_id,
  )?.id;

  const adAccountDefaultValue = metaAdAccounts.find(
    (adAccount) => adAccount.id === user?.customer.meta_ad_account_id,
  )?.id;

  const {
    formState: { isSubmitting, isValid },
    control,
    watch,
    handleSubmit,
    getValues,
    reset,
  } = useForm<MetaFormData>({
    resolver: zodResolver(metaFormSchema),
    mode: 'onChange',
  });

  const businessAccountId = watch('businessAccountId');

  function handleEdit() {
    setIsEditable(true);
    reset({
      businessAccountId: '',
      catalogAccountId: '',
      page: '',
      adAccount: '',
    });
  }

  async function handleMetaDisconnect() {
    const { disconnected } = await metaDisconnect();

    if (disconnected) {
      onDisconnect();
    }
  }

  async function onSubmit(data: MetaFormData) {
    try {
      await metaConfig({
        businessId: data.businessAccountId,
        catalogId: data.catalogAccountId,
        facebookAdAccountId: data.adAccount,
        facebookPageId: data.page,
      });

      toast.success('Meta settings saved successfully.');
    } catch {
      toast.error(
        'An error occurred while saving meta settings. Please try again.',
      );
    }
  }

  useEffect(() => {
    if (
      user?.customer.meta_business_id &&
      user?.customer.meta_catalog_id &&
      user?.customer.meta_page_id &&
      user.customer.meta_ad_account_id
    ) {
      setIsEditable(false);
    }
  }, [user]);

  useEffect(() => {
    if (businessAccountId) {
      setBusinessAccountState(businessAccountId);
    }
  }, [businessAccountId, reset]);

  useEffect(() => {
    if (businessAccountState) {
      reset({
        adAccount: undefined,
        businessAccountId: getValues('businessAccountId'),
        catalogAccountId: undefined,
        page: undefined,
      });

      getMetaCatalog().then(({ data }) => {
        if (data?.length === 0) {
          toast.error('No catalog accounts were found.');
        }
      });
    }
  }, [businessAccountState, getMetaCatalog, getValues, reset]);

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Connected Account</CardTitle>
          <CardDescription>
            Manage your Meta integration settings
          </CardDescription>
        </CardHeader>

        <CardContent className="space-y-4">
          <div className="flex items-center space-x-4">
            <div className="rounded-full bg-[#1877F2] p-2">
              <Facebook className="h-6 w-6 text-white" />
            </div>
            <div>
              <p className="font-semibold">Meta Business Account</p>
              <p className="text-sm text-muted-foreground">
                Connected on {new Date().toLocaleDateString()}
              </p>
            </div>
          </div>

          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            {isMetaBusinessAccountsLoading && (
              <LoaderCircle className="animate-spin" />
            )}

            {metaBusinessAccounts.length > 0 &&
              !isMetaBusinessAccountsLoading && (
                <div className="space-y-2">
                  <h4 className="font-semibold">Select Business Account</h4>

                  <Controller
                    control={control}
                    name="businessAccountId"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        defaultValue={businessAccountDefaultValue}
                        disabled={!isEditable}
                        onValueChange={onChange}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select an Business Account" />
                        </SelectTrigger>

                        <SelectContent>
                          {metaBusinessAccounts.map((businessAccount) => (
                            <SelectItem
                              key={businessAccount.id}
                              value={String(businessAccount.id)}
                            >
                              {businessAccount.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              )}

            {isMetaCatalogLoading && !isMetaBusinessAccountsLoading && (
              <LoaderCircle className="animate-spin" />
            )}

            {metaCatalog.length > 0 && !isMetaCatalogLoading && (
              <>
                <div className="space-y-2">
                  <h4 className="font-semibold">Select Catalog Account</h4>

                  <Controller
                    control={control}
                    name="catalogAccountId"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        defaultValue={catalogAccountDefaultValue}
                        disabled={!isEditable}
                        onValueChange={onChange}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select an Catalog Account" />
                        </SelectTrigger>

                        <SelectContent>
                          {metaCatalog.map((metaCatalog) => (
                            <SelectItem
                              key={metaCatalog.id}
                              value={String(metaCatalog.id)}
                            >
                              {metaCatalog.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>

                <div className="space-y-2">
                  <h4 className="font-semibold">Select Facebook Page</h4>

                  <Controller
                    control={control}
                    name="page"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        defaultValue={pageDefaultValue}
                        disabled={!isEditable}
                        onValueChange={onChange}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select a Page" />
                        </SelectTrigger>

                        <SelectContent>
                          {metaPages.map((metaPage) => (
                            <SelectItem
                              key={metaPage.id}
                              value={String(metaPage.id)}
                            >
                              {metaPage.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>

                <div className="space-y-2">
                  <h4 className="font-semibold">Select Ad Account</h4>

                  <Controller
                    control={control}
                    name="adAccount"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        defaultValue={adAccountDefaultValue}
                        disabled={!isEditable}
                        onValueChange={onChange}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select an Ad Account" />
                        </SelectTrigger>

                        <SelectContent>
                          {metaAdAccounts.map((metaAdAccount) => (
                            <SelectItem
                              key={metaAdAccount.id}
                              value={String(metaAdAccount.id)}
                            >
                              {metaAdAccount.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>
              </>
            )}

            <div className="flex gap-4">
              {isEditable && (
                <Button type="submit" disabled={isSubmitting || !isValid}>
                  <Save className="mr-2 h-4 w-4" />
                  Save Settings
                </Button>
              )}

              {!isEditable && (
                <Button type="button" onClick={handleEdit}>
                  <Pencil className="mr-2 h-4 w-4" />
                  Edit
                </Button>
              )}

              <Button
                type="button"
                variant="destructive"
                onClick={handleMetaDisconnect}
              >
                <LogOut className="mr-2 h-4 w-4" />
                Disconnect
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      <div className="rounded-lg bg-gray-100 p-4">
        <h3 className="mb-2 text-lg font-semibold">Integration Benefits</h3>
        <ul className="list-inside list-disc space-y-2 text-sm text-muted-foreground">
          <li>Creation and management of product sets</li>
          <li>Creation and management of custom audiences </li>
          <li>Enable optimizations of campaign strategies and boost ROI</li>
        </ul>
      </div>
    </div>
  );
}
