import { SquareArrowOutUpRight, Trash, Undo } from 'lucide-react';

import { Button } from '@/components/ui/Button';

import { ROUTE_PATHS } from '@/config/constants';

interface ProductListCtasProps {
  productId: number;
  isActive: boolean;
  onUndo: (productId: number) => void;
  onRemove: (productId: number) => void;
}

export function ProductListCtas({
  productId,
  isActive,
  onRemove,
  onUndo,
}: ProductListCtasProps) {
  function handleOpenVisionProduct() {
    window.open(`${ROUTE_PATHS.VISION_ENRICHMENT}/${productId}`, '_blank');
  }

  return (
    <div className="flex gap-2">
      <Button type="button" size="icon" onClick={handleOpenVisionProduct}>
        <SquareArrowOutUpRight className="h-4 w-4" />
      </Button>

      <Button
        type="button"
        variant={isActive ? 'destructive' : 'secondary'}
        size="icon"
        onClick={() => (isActive ? onRemove(productId) : onUndo(productId))}
      >
        {isActive ? (
          <Trash className="h-4 w-4" />
        ) : (
          <Undo className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
}
