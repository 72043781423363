type Message = {
  success: boolean;
};

class AuthCallbackBroadcast {
  private readonly channel = new BroadcastChannel('auth-callback-channel');

  sendMessage(message: Message) {
    this.channel.postMessage(message);
  }

  onMessage(callback: (event: Message) => void) {
    this.channel.onmessage = (event: MessageEvent<Message>) => {
      callback(event.data);
    };
  }
}

export default new AuthCallbackBroadcast();
