import { useMutation } from '@tanstack/react-query';

import CustomerService, {
  UpdateHubspotTokenDto,
} from '@/services/CustomerService';

export function useUpdateHubspotToken() {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: (updateHubspotTokenDto: UpdateHubspotTokenDto) =>
      CustomerService.updateHubspotToken(updateHubspotTokenDto),
  });

  return {
    updateHubspotToken: mutateAsync,
    isLoading: isPending,
  };
}
