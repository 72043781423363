import { useQuery } from '@tanstack/react-query';

import SeoService from '@/services/SeoService';

export const GET_SEO_QUERY_KEY = ['seo'];

export function useGetSeo() {
  const { data, isFetching } = useQuery({
    queryKey: GET_SEO_QUERY_KEY,
    queryFn: () => SeoService.getSeoConfig(),
  });

  return {
    seo: data,
    isLoading: isFetching,
  };
}
