import { Loader2 } from 'lucide-react';
import { useEffect, useRef } from 'react';

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCaption,
} from '@/components/ui/Table';
import { useGetProducts } from '@/hooks/useGetProducts';

import { ProductRow } from './ProductRow';
import { DownloadButton } from './DownloadButton';

export function ProductsTable() {
  const endTableRef = useRef<HTMLDivElement>(null);

  const { products, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetProducts();

  useEffect(() => {
    if (!endTableRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, obs) => {
        const { isIntersecting } = entries[0];

        if (!hasNextPage) {
          obs.disconnect();
          return;
        }

        if (isIntersecting && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        rootMargin: '30px',
      },
    );

    observer.observe(endTableRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  return (
    <div className="space-y-4">
      <DownloadButton />

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Product ID</TableHead>
              <TableHead>Product Name</TableHead>
              <TableHead>Product Image</TableHead>
              <TableHead>Product Description</TableHead>
              <TableHead>Alt Description Product</TableHead>
              <TableHead>Product Tags</TableHead>

              {Array.from({ length: 10 }).map((_, index) => (
                <TableHead key={index}>
                  Alt Description Image {index + 1}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {products.map((product) => (
              <ProductRow
                key={product.id}
                productId={product.id}
                externalProductId={product.productId}
              />
            ))}
          </TableBody>

          <TableCaption ref={endTableRef} />
        </Table>
      </div>

      {isFetchingNextPage && (
        <div className="mt-4 flex items-center justify-center">
          <Loader2 className="animate-spin" />
        </div>
      )}
    </div>
  );
}
