import { CircleHelp, SlidersHorizontal } from 'lucide-react';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/Popover';
import { RangeSlider } from '@/components/ui/RangeSlider';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/Sheet';
import { Switch } from '@/components/ui/Switch';
import { priceFormatter } from '@/lib/utils';

import {
  AppliedFilters,
  appliedFiltersInitialState,
  CalculatedProducts,
} from '..';
import { useFilterCalculatedProducts } from '@/hooks/useFilterCalculatedProducts';

export enum DiscountFilter {
  ALL = 'ALL',
  WITH_DISCOUNT = 'WITH_DISCOUNT',
  WITHOUT_DISCOUNT = 'WITHOUT_DISCOUNT',
}

const stockLimitDefault = '10';

interface ProductListFiltersProps {
  unchangeCalculatedProducts: CalculatedProducts;
  appliedFilters: AppliedFilters;
  isFiltering: boolean;
  onFiltering: Dispatch<SetStateAction<boolean>>;
  onCalculatedProducts: (products: CalculatedProducts) => void;
  onAppliedFilters: Dispatch<SetStateAction<AppliedFilters>>;
  onResetAppliedFilters: VoidFunction;
}

export function ProductListFilters({
  unchangeCalculatedProducts,
  appliedFilters,
  isFiltering,
  onFiltering,
  onCalculatedProducts,
  onAppliedFilters,
  onResetAppliedFilters,
}: ProductListFiltersProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [minPriceRange, maxPriceRange] = useMemo(
    () =>
      unchangeCalculatedProducts.reduce(
        ([min, max], product) => [
          Math.min(min, Number(product.price)),
          Math.max(max, Number(product.price)),
        ],
        [Infinity, -Infinity],
      ),
    [unchangeCalculatedProducts],
  );

  const [isApplied, setIsApplied] = useState(false);
  const [brokenStock, setBrokenStock] = useState(false);
  const [discountFilter, setDiscountFilter] = useState<DiscountFilter>(
    DiscountFilter.ALL,
  );
  const [lowStock, setLowStock] = useState(false);
  const [stockLimit, setStockLimit] = useState(stockLimitDefault);
  const [percentageRange, setPercentageRange] = useState([0, 100]);
  const [priceRange, setPriceRange] = useState([minPriceRange, maxPriceRange]);

  const { filterCalculatedProducts } = useFilterCalculatedProducts();

  function handleBrokenStock(checked: boolean) {
    setBrokenStock(checked);
  }

  function handleDiscountFilter(value: DiscountFilter) {
    setDiscountFilter(value);
  }

  function handleLowStock(checked: boolean) {
    setLowStock(checked);
  }

  function handleChangeStockLimit(event: ChangeEvent<HTMLInputElement>) {
    setStockLimit(event.target.value);
  }

  function handleBlurStockLimit(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.value) {
      setStockLimit(stockLimitDefault);
    }
  }

  async function handleApply() {
    onFiltering(true);

    try {
      const filtersToApply = appliedFiltersInitialState;

      if (brokenStock) {
        filtersToApply.brokenStock.active = true;
      }

      if (discountFilter === DiscountFilter.WITHOUT_DISCOUNT) {
        filtersToApply.discount.type = DiscountFilter.WITHOUT_DISCOUNT;
        filtersToApply.discount.active = true;
      }

      if (percentageRange[0] > 0 || percentageRange[1] < 100) {
        filtersToApply.discount = {
          active: true,
          range: {
            from: percentageRange[0],
            to: percentageRange[1],
          },
          type: DiscountFilter.WITH_DISCOUNT,
        };
      }

      if (priceRange[0] > minPriceRange || priceRange[1] < maxPriceRange) {
        filtersToApply.price = {
          range: {
            from: priceRange[0],
            to: priceRange[1],
          },
          active: true,
        };
      }

      if (lowStock) {
        filtersToApply.lowStock = {
          active: true,
          stockLimit: Number(stockLimit),
        };
      }

      onAppliedFilters(filtersToApply);
      setIsApplied(true);
      setIsOpen(false);

      const { filteredProducts } = await filterCalculatedProducts({
        calculatedProductIds: unchangeCalculatedProducts.map(
          (product) => product.productId,
        ),
        filters: appliedFilters,
      });

      onCalculatedProducts(
        unchangeCalculatedProducts.map((product) => {
          const filteredProduct = filteredProducts.find(
            (filteredProduct) =>
              filteredProduct.productId === product.productId,
          );

          if (!filteredProduct) {
            return product;
          }

          return {
            ...product,
            active: filteredProduct.active,
          };
        }),
      );
    } catch {
      //
    } finally {
      onFiltering(false);
    }
  }

  function handleReset() {
    setBrokenStock(false);
    setLowStock(false);
    setStockLimit(stockLimitDefault);
    setDiscountFilter(DiscountFilter.ALL);
    onCalculatedProducts(unchangeCalculatedProducts);
    setPercentageRange([0, 100]);
    setPriceRange([minPriceRange, maxPriceRange]);
    setIsApplied(false);
    onResetAppliedFilters();
  }

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button type="button" variant="outline" className="gap-2">
          <SlidersHorizontal className="h-4 w-4" />
          Filters
        </Button>
      </SheetTrigger>

      <SheetContent>
        <SheetHeader>
          <SheetTitle>Advanced Filters</SheetTitle>
          <SheetDescription />
        </SheetHeader>

        <div className="grid gap-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Label htmlFor="broken-stock">Full Size Run</Label>

              <Popover>
                <PopoverTrigger asChild>
                  <CircleHelp className="h-4 w-4 cursor-pointer text-muted-foreground" />
                </PopoverTrigger>

                <PopoverContent className="w-80 p-2">
                  <p className="text-sm text-muted-foreground">
                    Only include products that have a full range of sizes in
                    stock.
                  </p>
                </PopoverContent>
              </Popover>
            </div>

            <Switch
              id="broken-stock"
              checked={brokenStock}
              onCheckedChange={handleBrokenStock}
              disabled={isApplied}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="discount-filter">Discount</Label>

            <Select
              value={discountFilter}
              onValueChange={handleDiscountFilter}
              disabled={isApplied}
            >
              <SelectTrigger id="discount-filter">
                <SelectValue placeholder="Select the discount type" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value={DiscountFilter.ALL}>All products</SelectItem>

                <SelectItem value={DiscountFilter.WITH_DISCOUNT}>
                  Products with discount
                </SelectItem>

                <SelectItem value={DiscountFilter.WITHOUT_DISCOUNT}>
                  Products without discount
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          {discountFilter === DiscountFilter.WITH_DISCOUNT && (
            <div className="space-y-4">
              <Label>Discount Range</Label>

              <RangeSlider
                min={0}
                max={100}
                value={percentageRange}
                onValueChange={setPercentageRange}
                className="w-full"
                disabled={isApplied}
              />
              <div className="flex justify-between text-sm text-muted-foreground">
                <span>{percentageRange[0]}%</span>
                <span>{percentageRange[1]}%</span>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <Label>Price Range</Label>

            <RangeSlider
              min={minPriceRange}
              max={maxPriceRange}
              value={priceRange}
              onValueChange={setPriceRange}
              className="w-full"
              disabled={isApplied}
            />
            <div className="flex justify-between text-sm text-muted-foreground">
              <span>{priceFormatter(priceRange[0])}</span>
              <span>{priceFormatter(priceRange[1])}</span>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Label htmlFor="low-stock">Low Stock</Label>

              <Switch
                id="low-stock"
                checked={lowStock}
                onCheckedChange={handleLowStock}
                disabled={isApplied}
              />
            </div>

            {lowStock && (
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Label htmlFor="low-stock-limit">Limit</Label>

                  <Popover>
                    <PopoverTrigger asChild>
                      <CircleHelp className="h-4 w-4 cursor-pointer text-muted-foreground" />
                    </PopoverTrigger>

                    <PopoverContent className="w-80 p-2">
                      <p className="text-sm text-muted-foreground">
                        Sets the limit to consider a product as &quot;low
                        stock.&quot; Products with a stock quantity less than or
                        equal to this value will be marked as low stock.
                      </p>
                    </PopoverContent>
                  </Popover>
                </div>

                <Input
                  id="low-stock-limit"
                  type="number"
                  value={stockLimit}
                  onChange={handleChangeStockLimit}
                  onBlur={handleBlurStockLimit}
                  className="w-full"
                  disabled={isApplied}
                />
              </div>
            )}
          </div>

          <div className="flex gap-2">
            {isApplied && (
              <Button
                type="button"
                variant="outline"
                className="w-full"
                disabled={isFiltering}
                onClick={handleReset}
              >
                Clear
              </Button>
            )}

            {!isApplied && (
              <Button
                type="button"
                className="w-full"
                disabled={isFiltering}
                onClick={handleApply}
              >
                Apply
              </Button>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
