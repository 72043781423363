import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/Table';

const permissions = [
  {
    product: 'Catalog',
    category: 'Content',
    resource: 'Product and SKU Management',
  },
  {
    product: 'Catalog',
    category: 'Content',
    resource: 'Categories Management',
  },
  {
    product: 'Catalog',
    category: 'Content',
    resource: 'Category',
  },
  {
    product: 'Catalog',
    category: 'Content',
    resource: 'SKus',
  },
  {
    product: 'OMS',
    category: 'OMS access',
    resource: 'List Orders',
  },
  {
    product: 'OMS',
    category: 'OMS access',
    resource: 'View order',
  },
  {
    product: 'Checkout',
    category: 'CheckoutResources',
    resource: 'Orders Full Access',
  },
];

export function PermissionsTable() {
  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <h3 className="text-lg font-bold">Permissions</h3>

        <p>
          To ensure proper authentication and authorization within the VTEX
          ecosystem, your token must have specific permissions granted. Below is
          a table outlining the required accesses and their respective scopes:
        </p>
      </div>

      <div className="max-w-[768px] overflow-hidden rounded-xl border border-gray-200 bg-gray-50">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="border-d border border-gray-200 font-semibold text-black">
                Product
              </TableHead>

              <TableHead className="border border-gray-200 font-semibold text-black">
                Category
              </TableHead>

              <TableHead className="border border-gray-200 font-semibold text-black">
                Resource
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {permissions.map((permission) => (
              <TableRow key={permission.resource}>
                <TableCell className="border border-gray-200">
                  {permission.product}
                </TableCell>

                <TableCell className="border border-gray-200">
                  {permission.category}
                </TableCell>

                <TableCell className="border border-gray-200">
                  {permission.resource}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
