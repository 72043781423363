import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/Carousel';
import { cn } from '@/lib/utils';

import { CalculatedProducts } from '..';

import { ProductListCtas } from './ProductListCtas';
import { Loader2 } from 'lucide-react';

interface ProductListCarouselProps {
  calculatedProducts: CalculatedProducts;
  isFiltering: boolean;
  onUndo: (productId: number) => void;
  onRemove: (productId: number) => void;
}

export function ProductListCarousel({
  calculatedProducts,
  isFiltering = true,
  onUndo,
  onRemove,
}: ProductListCarouselProps) {
  return (
    <div>
      {isFiltering && (
        <div>
          <Loader2 className="animate-spin" />
        </div>
      )}

      {!isFiltering && (
        <Carousel
          opts={{
            align: 'start',
            loop: false,
          }}
          className="w-full"
        >
          <CarouselContent>
            {calculatedProducts.map((product) => (
              <CarouselItem
                key={product.id}
                className="md:basis-1/2 lg:basis-1/4"
              >
                <div className="p-1">
                  <div className="group relative overflow-hidden rounded-lg border">
                    <div
                      className={cn(
                        'absolute left-0 top-0 h-full w-full bg-black opacity-0',
                        product.active
                          ? 'group-hover:opacity-10'
                          : 'opacity-40',
                      )}
                    />

                    <img
                      src={product.image1}
                      alt={product.name}
                      className="aspect-square w-full object-cover"
                    />

                    <div className="p-4">
                      <h3 className="truncate font-medium">{product.name}</h3>
                    </div>

                    <div className="z-5 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100">
                      <ProductListCtas
                        productId={product.id}
                        isActive={product.active}
                        onUndo={onUndo}
                        onRemove={onRemove}
                      />
                    </div>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>

          <CarouselPrevious type="button" className="-left-3" />
          <CarouselNext type="button" className="-right-3" />
        </Carousel>
      )}
    </div>
  );
}
