import { useFormContext } from 'react-hook-form';

import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';

import { AudienceFormData } from '..';

export function AudienceName() {
  const { register } = useFormContext<AudienceFormData>();

  return (
    <div className="space-y-2">
      <Label htmlFor="audience-name">Audience Name</Label>
      <Input
        id="audience-name"
        placeholder="Name of your audience"
        {...register('name')}
      />
    </div>
  );
}
