import { useMutation } from '@tanstack/react-query';

import AudiencesService, {
  AudienceCalculateDto,
} from '@/services/AudiencesService';

export function useAudienceCalculate() {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (audienceCalculateDto: AudienceCalculateDto) =>
      AudiencesService.audienceCalculate(audienceCalculateDto),
  });

  return {
    audienceCalculate: mutateAsync,
    isLoading: isPending,
  };
}
