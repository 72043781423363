import { useFormContext } from 'react-hook-form';

import { Label } from '@/components/ui/Label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/RadioGroup';

import { AudienceFormData } from '..';

interface InputTypeProps {
  onResetCalculate: () => void;
  onTransformedValue: (value: null | string) => void;
}

export function InputType({
  onResetCalculate,
  onTransformedValue,
}: InputTypeProps) {
  const { register, setValue } = useFormContext<AudienceFormData>();

  function handleChangeInputType(value: string) {
    setValue('inputType', value as any);
    setValue('inputValue', '');
    setValue('file', null);
    onResetCalculate();
    onTransformedValue(null);
  }

  return (
    <div className="space-y-2">
      <Label>Select Input Type</Label>

      <RadioGroup
        onValueChange={(value) => handleChangeInputType(value)}
        className="flex flex-col space-y-2"
        {...register('inputType')}
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="CUSTOM" id="CUSTOM" />
          <Label htmlFor="CUSTOM">Custom Preferences</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="PRODUCTS" id="PRODUCTS" />
          <Label htmlFor="PRODUCTS">Product List</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="USERS" id="USERS" />
          <Label htmlFor="USERS">User List</Label>
        </div>
      </RadioGroup>
    </div>
  );
}
