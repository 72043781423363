import { TaxonomyList } from './components/TaxonomyList';
import { TaxonomySelect } from './components/TaxonomySelect';
import { TaxonomyProvider } from './contexts/TaxonomyContext';

export function Taxonomy() {
  return (
    <TaxonomyProvider>
      <div className="py-8">
        <section className="mb-10">
          <h1 className="text-[42px] tracking-[8.25px]">my style ai</h1>
        </section>

        <TaxonomySelect />
        <TaxonomyList />
      </div>
    </TaxonomyProvider>
  );
}
