import { useStylistAiContext } from '../../contexts/StylistAiContext';

import { ProductList } from './ProductList';

export function RecommendedProducts() {
  const { queryText, hasProducts } = useStylistAiContext();

  if (!queryText) {
    return null;
  }

  return (
    <div>
      {hasProducts && (
        <h2 className="mb-4 text-lg font-semibold">Recommended Products</h2>
      )}

      <ProductList />
    </div>
  );
}
