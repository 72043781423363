import { useQuery } from '@tanstack/react-query';

import MetaService from '@/services/MetaService';

export const META_OAUTH_QUERY_KEY = ['meta-oauth'];

export function useMetaOAuth() {
  const { data, isFetching, refetch } = useQuery({
    queryKey: META_OAUTH_QUERY_KEY,
    staleTime: 30 * 60 * 1000, // 30 minutes
    enabled: false,
    queryFn: () => MetaService.metaOAuth(),
  });

  return {
    data,
    isLoading: isFetching,
    metaOAuth: refetch,
  };
}
