import { useMutation, useQueryClient } from '@tanstack/react-query';

import CustomerService, { UpdateStoreURLDto } from '@/services/CustomerService';
import { GET_ME_QUERY_KEY } from './useGetMe';
import { produce } from 'immer';
import { MeResponse } from '@/services/AuthService';

export function useUpdateStoreURL() {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (updateStoreURLDto: UpdateStoreURLDto) =>
      CustomerService.updateStoreURL(updateStoreURLDto),
    onMutate: (updateStoreURLDto) => {
      const previousData =
        queryClient.getQueryData<MeResponse>(GET_ME_QUERY_KEY);

      queryClient.setQueryData<MeResponse>(
        GET_ME_QUERY_KEY,
        produce((draft) => {
          if (draft?.customer) {
            draft.customer.url = updateStoreURLDto.storeURL;
          }
        }),
      );

      return { previousData };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueryData<MeResponse>(
        GET_ME_QUERY_KEY,
        context?.previousData,
      );
    },
  });

  return {
    updateStoreURL: mutateAsync,
    isLoading: isPending,
  };
}
