import { useQuery } from '@tanstack/react-query';

import MetaService from '@/services/MetaService';

export const GET_META_BUSINESS_ACCOUNTS_QUERY_QUERY = [
  'meta-business-accounts',
];

export function useGetMetaBusinessAccounts() {
  const { data, isFetching } = useQuery({
    queryKey: GET_META_BUSINESS_ACCOUNTS_QUERY_QUERY,
    queryFn: () => MetaService.metaBusinessAccounts(),
  });

  return {
    metaBusinessAccounts: data ?? [],
    isLoading: isFetching,
  };
}
