import { useQuery } from '@tanstack/react-query';

import TaxonomiesService from '@/services/TaxonomiesService';

export const GET_TAXONOMIES_BY_CATEGORY_QUERY_KEY = (category: string) => [
  'taxonomy',
  category,
];

export function useGetTaxonomiesByCategory(category: string) {
  const { data, isFetching } = useQuery({
    queryKey: GET_TAXONOMIES_BY_CATEGORY_QUERY_KEY(category),
    queryFn: () => TaxonomiesService.getTaxonomiesByCategory(category),
  });

  const formattedData = data ?? [];

  return {
    taxonomy: formattedData,
    isLoading: isFetching,
  };
}
