import { useInfiniteQuery } from '@tanstack/react-query';

import AudiencesService from '@/services/AudiencesService';

export const GET_AUDIENCES_QUERY_KEY = ['audiences'];

export function useGetAudiences() {
  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: GET_AUDIENCES_QUERY_KEY,
      initialPageParam: 1,
      queryFn: ({ pageParam }) => AudiencesService.getAudiences(pageParam, 10),
      getNextPageParam: (lastPage, _allPages, lastPageParam) => {
        const { currentPage, totalPages } = lastPage;

        if (currentPage === totalPages || totalPages === 0) {
          return null;
        }

        return lastPageParam + 1;
      },
    });

  const audiences = data?.pages.flatMap((page) => page?.items);

  return {
    audiences: audiences ?? [],
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
}
