import { useMutation } from '@tanstack/react-query';

import { queryClient } from '@/lib/queryClient';
import MetaService, { MetaConfigDto } from '@/services/MetaService';

import { GET_ME_QUERY_KEY } from './useGetMe';

export function useMetaConfig() {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (metaConfigDto: MetaConfigDto) =>
      MetaService.metaConfig(metaConfigDto),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: GET_ME_QUERY_KEY,
      });
    },
  });

  return {
    metaConfig: mutateAsync,
    isLoading: isPending,
  };
}
