import { useInfiniteQuery } from '@tanstack/react-query';

import ProductsService from '@/services/ProductsService';

export const GET_PRODUCTS_QUERY_KEY = ['products'];

export function useGetProducts(limit: number = 12) {
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: GET_PRODUCTS_QUERY_KEY,
      initialPageParam: 1,
      queryFn: ({ pageParam }) => ProductsService.getProducts(pageParam, limit),
      getNextPageParam: (lastPage, _allPages, lastPageParam) => {
        const data = lastPage;

        if (data.currentPage === data.totalPages || data.totalPages === 0) {
          return null;
        }

        return lastPageParam + 1;
      },
    });

  const products = data?.pages.flatMap((page) => page?.items);

  return {
    products: products ?? [],
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
}
