import { X } from 'lucide-react';
import { Fragment } from 'react';

import { Badge } from '@/components/ui/Badge';
import { Card, CardContent } from '@/components/ui/Card';
import { useProductsRecommendation } from '@/hooks/useProductsRecommendation';
import { capitalizeWords } from '@/lib/utils';

import {
  SelectedAttribute,
  useStylistAiContext,
} from '../../contexts/StylistAiContext';

export function FiltersCard() {
  const {
    queryText,
    selectedCategories,
    selectedAttributes,
    setSelectedCategoriesCallback,
    setSelectedAttributesCallback,
  } = useStylistAiContext();

  const { products } = useProductsRecommendation(queryText);

  function formatAttributesText(text: string) {
    return capitalizeWords(text.replaceAll('_', ' '));
  }

  function toggleCategory(category: string) {
    const newCategories = selectedCategories.includes(category)
      ? selectedCategories.filter(
          (selectedCategory) => selectedCategory !== category,
        )
      : [...selectedCategories, category];

    setSelectedCategoriesCallback(newCategories);
  }

  function toggleAttribute(attribute: SelectedAttribute) {
    const newAttributes = selectedAttributes.find(
      (selectedAttribute) =>
        selectedAttribute.displayName === attribute.displayName,
    )
      ? selectedAttributes.filter(
          (selectedAttribute) =>
            selectedAttribute.displayName !== attribute.displayName,
        )
      : [...selectedAttributes, attribute];

    setSelectedAttributesCallback(newAttributes);
  }

  return (
    <Card>
      <CardContent className="p-6">
        <h2 className="mb-4 text-lg font-semibold">Extractions</h2>
        <div className="space-y-6">
          {/* Categories */}
          <div>
            <h3 className="mb-2 font-medium">Categories</h3>
            <div className="flex flex-wrap gap-2">
              {products.categories.map((category) => (
                <Badge
                  key={category}
                  variant={
                    selectedCategories.includes(category)
                      ? 'default'
                      : 'outline'
                  }
                  className="cursor-pointer"
                  onClick={() => toggleCategory(category)}
                >
                  {category}
                  {selectedCategories.includes(category) && (
                    <X className="ml-1 h-3 w-3" />
                  )}
                </Badge>
              ))}
            </div>
          </div>

          {/* Attributes */}
          <div>
            {Object.entries(products.attributes ?? {}).map((attribute: any) => (
              <Fragment key={attribute[0]}>
                <h3 className="mb-2 font-medium">
                  {formatAttributesText(attribute[0])}
                </h3>

                <div className="flex flex-wrap gap-2">
                  {attribute[1].map((item: any) => {
                    const formattedAttribute = `${formatAttributesText(item.property)}: ${formatAttributesText(item.name)}`;

                    return (
                      <Badge
                        key={formattedAttribute}
                        variant={
                          selectedAttributes.some(
                            (selectedAttribute) =>
                              selectedAttribute.displayName ===
                              formattedAttribute,
                          )
                            ? 'default'
                            : 'outline'
                        }
                        className="cursor-pointer"
                        onClick={() =>
                          toggleAttribute({
                            displayName: formattedAttribute,
                            name: item.name,
                            property: item.property,
                          })
                        }
                      >
                        {formattedAttribute}
                        {selectedAttributes.some(
                          (selectedAttribute) =>
                            selectedAttribute.displayName ===
                            formattedAttribute,
                        ) && <X className="ml-1 h-3 w-3" />}
                      </Badge>
                    );
                  })}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
