import { useMutation } from '@tanstack/react-query';

import MetaService, { MetaDisconnectResponse } from '@/services/MetaService';

export function useMetaDisconnect() {
  const { mutateAsync, isPending } = useMutation<MetaDisconnectResponse>({
    mutationFn: () => MetaService.metaDisconnect(),
  });

  return {
    metaDisconnect: mutateAsync,
    isLoading: isPending,
  };
}
