import { v4 } from 'uuid';

import authCarousel1 from '@/assets/auth-carousel-1.jpg';
import authCarousel2 from '@/assets/auth-carousel-2.jpg';
import authCarousel3 from '@/assets/auth-carousel-3.jpg';

import { Carousel, CarouselSlide } from './Carousel';

const authCarouselData = [
  {
    id: v4(),
    url: authCarousel1,
    alt: 'Auth carousel image 1',
    description: 'A new way to interact with your catalog',
  },
  {
    id: v4(),
    url: authCarousel2,
    alt: 'Auth carousel image 2',
    description: 'Get to know more about how users interact with your products',
  },
  {
    id: v4(),
    url: authCarousel3,
    alt: 'Auth carousel image 3',
    description: 'Get ahead with business projections and predictions',
  },
];

export function AuthCarousel() {
  return (
    <Carousel
      className="h-full rounded-lg"
      autoplay
      pagination={{ enabled: true }}
    >
      {authCarouselData.map((image) => (
        <CarouselSlide key={image.id}>
          <div className="relative h-full">
            <img
              className="h-full w-full object-cover"
              src={image.url}
              alt={image.alt}
            />

            <div className="absolute bottom-[68px] left-1/2 w-full max-w-[800px] -translate-x-1/2 px-20">
              <h3 className="text-center text-2xl uppercase text-white">
                {image.description}
              </h3>
            </div>
          </div>
        </CarouselSlide>
      ))}
    </Carousel>
  );
}
