import { LogOut } from 'lucide-react';
import { Link, Outlet } from 'react-router-dom';

import { Button } from '@/components/ui/Button';
import { ROUTE_PATHS } from '@/config/constants';
import { useAuth } from '@/contexts/AuthContext';

export function AdminLayout() {
  const { logout } = useAuth();

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="container mx-auto flex items-center justify-between">
          <nav className="container px-6 py-3">
            <ul className="flex space-x-4">
              <li>
                <Link
                  to={ROUTE_PATHS.ADMIN_DASHBOARD}
                  className="text-gray-800 hover:text-gray-600"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTE_PATHS.ADMIN_CUSTOMERS_CREATE}
                  className="text-gray-800 hover:text-gray-600"
                >
                  Create Customer
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTE_PATHS.ADMIN_USERS_CREATE}
                  className="text-gray-800 hover:text-gray-600"
                >
                  Create User
                </Link>
              </li>
            </ul>
          </nav>
          <div>
            <Button
              variant="ghost"
              className="text-gray-800 hover:text-gray-600"
              onClick={logout}
            >
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </Button>
          </div>
        </div>
      </header>

      <main className="container mx-auto overflow-hidden px-4 py-6">
        <Outlet />
      </main>
    </div>
  );
}
