import {
  Table,
  TableBody,
  TableCellWithTooltip,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/Table';
import { useGetCategoriesSeo } from '@/hooks/useGetCategoriesSeo';
import { DownloadButton } from './DownloadButton';

export function CategoriesTable() {
  const { categoriesSeo } = useGetCategoriesSeo();

  return (
    <div className="space-y-4">
      <DownloadButton />

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Title</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Keywords</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {categoriesSeo.map((category) => (
              <TableRow key={category.category_id}>
                <TableCellWithTooltip>
                  {category.category_id}
                </TableCellWithTooltip>

                <TableCellWithTooltip>{category.name}</TableCellWithTooltip>

                <TableCellWithTooltip>{category.title}</TableCellWithTooltip>

                <TableCellWithTooltip>
                  {category.description}
                </TableCellWithTooltip>

                <TableCellWithTooltip>{category.keywords}</TableCellWithTooltip>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
