import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { LoaderCircle } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { FormGroup } from '@/components/FormGroup';
import { ERROR_MESSAGES } from '@/config/constants';
import { useAuth } from '@/contexts/AuthContext';

import { FormInput } from './FormInput';

const loginFormSchema = z.object({
  email: z.string().email({ message: 'Please enter a valid email address' }),
  password: z.string().min(1, { message: 'Password is required' }),
});

type LoginFormData = z.infer<typeof loginFormSchema>;

export function Form() {
  const { login } = useAuth();

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginFormSchema),
    mode: 'onSubmit',
  });

  async function submitForm({ email, password }: LoginFormData) {
    try {
      await login(email, password);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status === 401) {
          toast.error(ERROR_MESSAGES.INVALID_CREDENTIALS);
          return;
        }

        if (error.status === 422) {
          toast.error(ERROR_MESSAGES.INVALID_DATA);
          return;
        }

        toast.error(ERROR_MESSAGES.INTERNAL_SERVER_ERROR);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="mb-5 flex flex-col gap-4">
        <FormGroup
          error={{
            message: errors.email?.message,
            font: 'xs',
            align: 'center',
          }}
        >
          <FormInput
            placeholder="Your email"
            inputMode="email"
            {...register('email')}
          />
        </FormGroup>

        <FormGroup
          error={{
            message: errors.password?.message,
            font: 'xs',
            align: 'center',
          }}
        >
          <FormInput
            type="password"
            placeholder="Your password"
            {...register('password')}
          />
        </FormGroup>

        <div className="flex flex-wrap justify-between gap-2 px-[14px]">
          <a
            href="#"
            className="hidden cursor-not-allowed text-xs text-[#6F82B8] underline"
          >
            Forgot my password
          </a>
        </div>
      </div>

      <button
        type="submit"
        className="flex h-10 w-full items-center justify-center rounded-[45px] bg-black text-sm font-medium text-white hover:bg-opacity-80"
        disabled={isSubmitting}
      >
        {isSubmitting ? <LoaderCircle className="animate-spin" /> : 'Login'}
      </button>
    </form>
  );
}
