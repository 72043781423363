import { useQuery } from '@tanstack/react-query';

import ProductsService from '@/services/ProductsService';

export const GET_VISION_PRODUCT_QUERY_KEY = (productId: number) => [
  'vision-product',
  productId,
];

export function useGetVisionProduct(productId: number) {
  const { data, isFetching } = useQuery({
    queryKey: GET_VISION_PRODUCT_QUERY_KEY(productId),
    queryFn: () => ProductsService.getVisionProduct(productId),
  });

  return {
    product: data,
    isLoading: isFetching,
  };
}
