import { useInfiniteQuery } from '@tanstack/react-query';

import { useAuth } from '@/contexts/AuthContext';
import ProductsService from '@/services/ProductsService';

export function useProductsRecommendation(queryText: string) {
  const { user } = useAuth();

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['products-recommendation', user?.customer?.id, { queryText }],
      initialPageParam: 1,
      queryFn: ({ pageParam }) =>
        ProductsService.getProductsRecommendation(queryText, pageParam, 30),
      getNextPageParam: (lastPage, _allPages, lastPageParam) => {
        const data = lastPage;

        const totalPages = Math.ceil(data.total / data.page_size);

        if (data.page === totalPages) {
          return null;
        }

        return lastPageParam + 1;
      },
    });

  const formattedProducts =
    data?.pages.flatMap((page) => page.data?.result?.items) ?? [];

  const formattedCategories = [
    ...new Set(
      data?.pages.flatMap((page) => page.data?.result?.categories) ?? [],
    ),
  ];

  const uniqueAttributes = new Map();

  for (const page of data?.pages ?? []) {
    if (formattedProducts.length === 0) {
      uniqueAttributes.clear();
      break;
    }

    Object.entries(page.data.result.attributes ?? {}).forEach(
      ([key, value]: any[]) => {
        if (!uniqueAttributes.has(key)) {
          const mappedValues = value.flatMap((valueItem: any) => {
            if (typeof valueItem.name === 'object') {
              return valueItem.name.map((name: any) => ({
                ...valueItem,
                name,
              }));
            }

            return valueItem;
          });

          uniqueAttributes.set(key, mappedValues);
        }
      },
    );
  }

  const formattedAttributes = Object.fromEntries(uniqueAttributes);

  const products = {
    items: formattedProducts,
    categories: formattedCategories,
    attributes: formattedAttributes,
  };

  return {
    products,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
}
