import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/Tabs';
import { withUser } from '@/hocs/withUser';
import { Platform } from '@/services/CustomerService';

import { General } from './components/General';
import { HubSpot } from './components/HubSpot';
import { Meta } from './components/Meta';
import { User } from './components/User';
import { Vtex } from './components/Vtex';
import { Security } from './components/Security';

export const Settings = withUser(({ user }) => {
  const tabs = [
    {
      value: 'general',
      label: 'General Settings',
      active: true,
      Component: General,
    },
    {
      value: 'security',
      label: 'Security Settings',
      active: true,
      Component: Security,
    },
    {
      value: 'vtex',
      label: 'VTEX Settings',
      active: user?.customer.platform === Platform.VTEX,
      Component: Vtex,
    },
    {
      value: 'meta',
      label: 'Meta Settings',
      active: true,
      Component: Meta,
    },
    {
      value: 'hubspot',
      label: 'HubSpot Settings',
      active: true,
      Component: HubSpot,
    },
  ];

  const activeTabs = tabs.filter((tab) => tab.active);

  return (
    <div className="py-8">
      <section className="mb-10">
        <h1 className="text-[42px] tracking-[8.25px]">settings</h1>
      </section>

      <section>
        <User />
      </section>

      <Tabs defaultValue="general" className="w-full">
        <TabsList className="mb-6 grid h-[40px] w-full grid-cols-5">
          {activeTabs.map(({ label, value }) => (
            <TabsTrigger key={value} value={value}>
              {label}
            </TabsTrigger>
          ))}
        </TabsList>

        {tabs.map(({ Component, value }) => (
          <TabsContent key={value} value={value}>
            <Component />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
});
