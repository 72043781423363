import { httpClient } from '@/lib/httpClient';

export interface TaxonomyResponse {
  id: number;
  type: string;
  groupName: string;
  categoryName: string;
  property: string;
  description: string;
  examples: string;
  order: number;
}

export interface UpdateTaxonomyDto {
  examples: string;
}

class TaxonomiesService {
  constructor() {}

  async getTaxonomies() {
    const { data } = await httpClient.get<string[]>('/v1/taxonomies');

    return data;
  }

  async getTaxonomiesByCategory(category: string) {
    const { data } = await httpClient.get<TaxonomyResponse[]>(
      `/v1/taxonomies/category/${category}`,
    );

    return data;
  }

  async updateTaxonomy(id: number, updateTaxonomyDto: UpdateTaxonomyDto) {
    const { data } = await httpClient.patch(
      `/v1/taxonomies/${id}`,
      updateTaxonomyDto,
    );

    return data;
  }
}

export default new TaxonomiesService();
