import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { FormGroup } from '@/components/FormGroup';
import { RequiredLabel } from '@/components/RequiredLabel';
import { Button } from '@/components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';
import { Input } from '@/components/ui/Input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select';
import { CustomerSelect } from '@/components/CustomerSelect';
import { useCreateUser } from '@/hooks/useCreateUser';
import { Role, Status } from '@/services/UserService';

const userSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
  role: z.nativeEnum(Role),
  status: z.nativeEnum(Status),
  customer: z.string().min(1, 'Customer is required'),
});

type UserFormData = z.infer<typeof userSchema>;

interface FormProps {
  onSuccess: (value: boolean) => void;
}

export default function Form({ onSuccess }: FormProps) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      role: Role.User,
      status: Status.Active,
    },
  });

  const { createUser, isLoading } = useCreateUser();

  const onSubmit = handleSubmit(async (data: UserFormData) => {
    try {
      await createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        role: { id: data.role },
        status: { id: data.status },
        customer: { id: parseInt(data.customer, 10) },
      });
      onSuccess(true);
      reset();
    } catch (error) {
      toast.error('Failed to create user. Please try again.');
    }
  });

  return (
    <Card className="mx-auto w-full max-w-2xl">
      <CardHeader>
        <CardTitle>Create New User</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={onSubmit} className="space-y-4">
          <FormGroup error={{ message: errors.firstName?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="firstName">First Name</RequiredLabel>
            <Input id="firstName" {...register('firstName')} />
          </FormGroup>

          <FormGroup error={{ message: errors.lastName?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="lastName">Last Name</RequiredLabel>
            <Input id="lastName" {...register('lastName')} />
          </FormGroup>

          <FormGroup error={{ message: errors.email?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="email">Email</RequiredLabel>
            <Input id="email" type="email" {...register('email')} />
          </FormGroup>

          <FormGroup error={{ message: errors.password?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="password">Password</RequiredLabel>
            <Input id="password" type="password" {...register('password')} />
          </FormGroup>

          <FormGroup error={{ message: errors.role?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="role">Role</RequiredLabel>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Select
                  onValueChange={(value) => field.onChange(Number(value))}
                  defaultValue={field.value.toString()}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select role" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={Role.User.toString()}>User</SelectItem>
                    <SelectItem value={Role.Admin.toString()}>Admin</SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
          </FormGroup>

          <FormGroup error={{ message: errors.status?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="status">Status</RequiredLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  onValueChange={(value) => field.onChange(Number(value))}
                  defaultValue={field.value.toString()}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={Status.Active.toString()}>
                      Active
                    </SelectItem>
                    <SelectItem value={Status.Inactive.toString()}>
                      Inactive
                    </SelectItem>
                  </SelectContent>
                </Select>
              )}
            />
          </FormGroup>

          <FormGroup error={{ message: errors.customer?.message, font: 'sm' }}>
            <RequiredLabel htmlFor="customer">Customer</RequiredLabel>
            <Controller
              name="customer"
              control={control}
              render={({ field }) => (
                <CustomerSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                />
              )}
            />
          </FormGroup>

          <p className="mt-4 text-sm text-gray-500">
            <span className="text-red-500">*</span> Required field
          </p>
          <Button
            type="submit"
            className="w-full"
            disabled={isSubmitting || isLoading}
          >
            {(isSubmitting || isLoading) && (
              <Loader2 className="animate-spin" />
            )}

            {!isSubmitting && !isLoading && 'Create User'}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
