import { httpClient } from '@/lib/httpClient';

import { Platform } from './CustomerService';

export interface LoginDto {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
}

export interface MeResponse {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: {
    id: number;
    name: string;
  };
  customer: {
    id: number;
    name: string;
    url: string;
    platform: Platform;
    vtexUrl: string;
    freemium: boolean;
    hubspotToken: string | null;
    vtexAppKey: string | null;
    vtexAppToken: string | null;
    vtexAffiliateUrl: string | null;
    vtexAffiliateId: string | null;
    vtexSalesChannel: number | null;
    meta_user_token: string | null;
    meta_business_id: string;
    meta_catalog_id: string;
    meta_page_id: string | null;
    meta_ad_account_id: string | null;
    visionLastSubmission: Date | null;
    visionAutoRun: boolean;
    needUpdateCategoryName: boolean;
  };
}

export interface ChangePasswordDto {
  currentPassword: string;
  newPassword: string;
}

export interface ChangePasswordResponse {
  message: string;
}

class AuthService {
  constructor() {}

  async login(loginDto: LoginDto) {
    const { data } = await httpClient.post<LoginResponse>(
      '/v1/auth/email/login',
      loginDto,
    );

    return { token: data.token };
  }

  async me() {
    const { data } = await httpClient.get<MeResponse>('/v1/auth/me');

    return data;
  }

  async changePassword(changePasswordDto: ChangePasswordDto) {
    const { data } = await httpClient.post<ChangePasswordResponse>(
      '/v1/auth/change/password',
      changePasswordDto,
    );

    return data;
  }
}

export default new AuthService();
