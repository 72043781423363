import { ComponentProps, forwardRef } from 'react';

import { cn } from '@/lib/utils';

interface FormInputProps extends ComponentProps<'input'> {
  className?: string;
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  ({ className, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={cn(
          'w-full rounded-[42px] border border-solid border-[#DBDBDB] px-6 py-[10px] text-center outline-none placeholder:text-[#727272]',
          className,
        )}
        {...props}
      />
    );
  },
);

FormInput.displayName = 'FormInput';
