import { Download } from 'lucide-react';

import { CSVDownloadButton } from '@/components/CSVDownloadButton';
import { useGetAudienceProducts } from '@/hooks/useGetAudienceProducts';
import { Audience } from '@/services/AudiencesService';

interface ProductsInSetProps {
  audience: Pick<Audience, 'id' | 'name' | 'products_in_set_active'>;
}

export function ProductsInSet({ audience }: ProductsInSetProps) {
  const { audienceProducts, getAudienceProducts } = useGetAudienceProducts(
    audience.id,
  );

  const data =
    audienceProducts?.map((audienceProduct) => ({
      product_id: audienceProduct.product.productId,
    })) ?? [];

  const headers = [{ label: 'product_id', key: 'product_id' }];

  async function handleDownload() {
    await getAudienceProducts();
  }

  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="text-2xl font-bold">
          {Number(audience.products_in_set_active)}
        </div>

        <CSVDownloadButton
          data={data}
          headers={headers}
          onDownload={handleDownload}
        >
          <Download className="h-4 w-4" />
        </CSVDownloadButton>
      </div>

      <p className="text-xs text-muted-foreground">products in set</p>
    </div>
  );
}
