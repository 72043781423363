import { useQuery } from '@tanstack/react-query';

import ProductsService, {
  VisionAttributresParams,
} from '@/services/ProductsService';

export const GET_VISION_ATTRIBUTES_QUERY_KEY = (attributeId: string) => [
  'vision-attributes',
  attributeId,
];

export function useGetVisionAttributes(
  attributeId: string,
  params: VisionAttributresParams,
) {
  const { data, isFetching } = useQuery({
    queryKey: GET_VISION_ATTRIBUTES_QUERY_KEY(attributeId),
    queryFn: () => ProductsService.getVisionAttributes(params),
  });

  return {
    visionAttributes: data,
    isLoading: isFetching,
  };
}
