import { VisionProducts } from './VisionProducts';
import { StoreForm } from './StoreForm';
import { Tab } from '../Tab';

export function General() {
  return (
    <Tab
      title="General Settings"
      description="Manage general settings and actions for your account"
    >
      <StoreForm />
      <VisionProducts />
    </Tab>
  );
}
