import { Download, Info, Plus } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/Tooltip';
import { Switch } from '@/components/ui/Switch';
import insiderLogo from '@/assets/insider-logo.png';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '@/config/constants';

export function CrmBehaviors() {
  const suggestedBehaviors = [
    {
      title: 'Workplace Style',
      audience: 252,
      similarity: 78,
      arpu: 65,
      explanation:
        'Peças sofisticadas e elegantes para ambientes de trabalho, combinando conforto, credibilidade e estilo profissional para uso em reuniões, escritórios formais, eventos corporativos e encontros de negócios. Peças para uma profissional moderna que valoriza qualidade, sofisticação e um estilo que transmita credibilidade no trabalho. O visual buscado é para uma pessoa que se imagina em um ambiente corporativo refinado, com um look alinhado, sofisticado e que transmite autoridade.',
    },
    {
      title: 'Trendsetters',
      audience: 320,
      similarity: 85,
      arpu: 92,
      explanation:
        'Peças inovadoras e ousadas que seguem as últimas tendências da moda, ideais para quem busca um look fashionista e autêntico. Perfeito para eventos de moda, festas descoladas, encontros em locais sofisticados e situações onde um look impactante faz diferença. Para consumidores antenados com as novidades do mundo da moda, que buscam peças diferenciadas para expressar sua individualidade. O visual imaginado é de alguém desfilando pelas ruas com um look moderno e autêntico, chamando atenção pelo estilo inovador e sofisticado.',
    },
    {
      title: 'Avant-Garde Fashionistas',
      audience: 189,
      similarity: 72,
      arpu: 88,
      explanation:
        'Moda conceitual e artística, com cortes inusitados, tecidos diferenciados e um visual que desafia padrões tradicionais. Ideal para exposições de arte, eventos culturais, festivais e locais onde a moda se torna uma forma de expressão. Para pessoas que veem a moda como uma extensão da identidade artística e buscam peças com design disruptivo e vanguardista. O visual imaginado é de alguém em um evento sofisticado e criativo, onde seu look funciona como uma peça de arte em movimento.',
    },
    {
      title: 'Chic Urban',
      audience: 275,
      similarity: 81,
      arpu: 76,
      explanation:
        'Estilo urbano sofisticado, equilibrando modernidade e praticidade com um toque refinado para o dia a dia na cidade. Ótimo para passeios pela cidade, jantares em lugares sofisticados e eventos sociais onde o estilo é essencial. Para pessoas que se inspiram no street style das grandes metrópoles, valorizando peças que unem conforto e elegância. O visual imaginado é de alguém caminhando pelas ruas da cidade, visitando cafés e galerias de arte, sempre com um visual refinado e atual.',
    },
    {
      title: 'Streetwear',
      audience: 310,
      similarity: 79,
      arpu: 70,
      explanation:
        'Moda casual inspirada na cultura urbana, com influência do hip-hop, skate e esportes, focada em conforto e atitude. Ideal para encontros casuais, eventos urbanos, festivais de música e momentos de lazer onde o estilo é um diferencial. Para pessoas que se identificam com a cultura de rua e buscam roupas que expressem identidade e atitude no dia a dia. O visual imaginado é de alguém explorando a cidade com um look despojado, combinando peças oversized, sneakers estilosos e acessórios que refletem sua essência.',
    },
    {
      title: 'Active Lifestyle',
      audience: 290,
      similarity: 83,
      arpu: 68,
      explanation:
        'Peças versáteis e funcionais para quem tem uma rotina dinâmica e precisa de roupas que acompanhem seu ritmo. Perfeito para treinos, caminhadas, passeios ao ar livre, viagens e atividades que exigem conforto e mobilidade. Para pessoas que valorizam praticidade e bem-estar, escolhendo roupas que proporcionam liberdade de movimento e combinam com um estilo de vida saudável. O visual imaginado é de alguém correndo no parque, praticando esportes ou cumprindo uma rotina agitada com um look esportivo e moderno.',
    },
    {
      title: 'Performance Sportswear Enthusiasts',
      audience: 215,
      similarity: 75,
      arpu: 82,
      explanation:
        'Roupas técnicas e de alta performance para esportistas que buscam funcionalidade, resistência e inovação em cada detalhe. Ideal para treinos intensos, competições, esportes de alto rendimento e qualquer atividade física que demande tecnologia no vestuário. Para atletas e entusiastas do fitness que priorizam tecidos inteligentes, cortes ergonômicos e inovações que melhoram o rendimento esportivo. O visual imaginado é de alguém treinando na academia, competindo em corridas ou praticando esportes de alta intensidade, vestindo peças que potencializam seu desempenho.',
    },
    {
      title: 'Beachwear & Resort',
      audience: 180,
      similarity: 70,
      arpu: 79,
      explanation:
        'Peças leves, sofisticadas e confortáveis para momentos de relaxamento à beira-mar ou em resorts de luxo. Perfeito para dias de praia, cruzeiros, férias tropicais e momentos de lazer sob o sol. Para pessoas que amam destinos tropicais e buscam roupas leves, estilosas e sofisticadas para compor um visual perfeito para o calor. O visual imaginado é de alguém caminhando na areia ao pôr do sol, relaxando à beira da piscina ou curtindo um resort exclusivo com um look leve e refinado.',
    },
    {
      title: 'Surfer Vibes',
      audience: 165,
      similarity: 68,
      arpu: 72,
      explanation:
        'Estilo despojado e descontraído, inspirado no lifestyle do surfe, com peças confortáveis e estampas tropicais. Ideal para surf trips, festivais de praia, encontros entre amigos e qualquer ocasião que remeta ao espírito livre do surfe. Para pessoas que amam o mar, a natureza e o estilo de vida praiano, optando por roupas leves e descomplicadas com uma pegada tropical. O visual imaginado é de alguém pegando ondas, caminhando descalço na areia ou relaxando depois de um dia de sol, sempre com um look autêntico e alinhado à vibe praiana.',
    },
    {
      title: 'Comfort Seekers',
      audience: 340,
      similarity: 88,
      arpu: 64,
      explanation:
        'Moda focada no bem-estar, com peças macias, modelagens soltas e tecidos que garantem conforto absoluto. Perfeito para momentos de descanso, home office, viagens longas e qualquer situação onde o conforto seja prioridade. Para pessoas que valorizam roupas funcionais e aconchegantes, escolhendo peças que tragam bem-estar e praticidade para o dia a dia. O visual imaginado é de alguém relaxando em casa, curtindo um café aconchegante ou viajando com um look que equilibra estilo e conforto.',
    },
    {
      title: 'Loungewear Addicts',
      audience: 225,
      similarity: 76,
      arpu: 67,
      explanation:
        'Roupas confortáveis e estilosas para momentos de relaxamento, combinando praticidade e sofisticação. Perfeito para ficar em casa com estilo, viagens longas, home office ou momentos de descanso com conforto absoluto. Para pessoas que valorizam peças aconchegantes e bem confeccionadas, buscando roupas que tragam leveza e sofisticação para o dia a dia. O visual imaginado é de alguém em casa tomando um café, assistindo a um filme ou curtindo uma manhã tranquila com um look relaxante e sofisticado.',
    },
    {
      title: 'Minimalist Chic',
      audience: 295,
      similarity: 82,
      arpu: 85,
      explanation:
        'Peças atemporais, de cortes simples e tons neutros, criando um estilo sofisticado sem exageros. Ideal para ambientes de trabalho modernos, encontros casuais, viagens e qualquer ocasião que exija um visual refinado e discreto. Para pessoas que preferem um guarda-roupa reduzido, mas bem estruturado, optando por peças versáteis e duradouras. O visual imaginado é de alguém caminhando pelas ruas da cidade com um look impecável e neutro, onde cada peça foi escolhida com precisão para criar um visual elegante e descomplicado.',
    },
    {
      title: 'Casual Cool',
      audience: 305,
      similarity: 80,
      arpu: 73,
      explanation:
        'Moda descomplicada e moderna, com peças confortáveis que trazem um toque de estilo e atitude. Ótimo para encontros casuais, passeios no shopping, almoços com amigos ou qualquer ocasião onde conforto e estilo se encontram. Para pessoas que valorizam conforto, mas não abrem mão do estilo, apostando em looks práticos, porém cheios de identidade. O visual imaginado é de alguém explorando a cidade, passeando com amigos ou curtindo um evento descontraído, sempre com um look descolado e moderno.',
    },
    {
      title: 'Classic Timeless',
      audience: 280,
      similarity: 84,
      arpu: 90,
      explanation:
        'Moda clássica e elegante, com peças refinadas e atemporais que nunca saem de moda. Ideal para eventos sociais, ambientes profissionais, encontros formais e momentos que exigem sofisticação discreta. Para pessoas sofisticadas, que prezam por qualidade e tradição, buscando um estilo refinado e duradouro. O visual imaginado é de alguém em um evento elegante, vestindo peças impecáveis que valorizam cortes bem estruturados e materiais nobres.',
    },
    {
      title: 'Boho Spirit',
      audience: 210,
      similarity: 74,
      arpu: 77,
      explanation:
        'Estilo boêmio, inspirado na moda hippie e folk, com tecidos leves, estampas étnicas e modelagens fluidas. Perfeito para festivais, encontros ao ar livre, viagens para lugares exóticos e momentos que pedem um look leve e descontraído. Para pessoas criativas e livres, que buscam roupas com alma e que transmitam um estilo de vida leve e autêntico. O visual imaginado é de alguém em um festival de música, em uma viagem para um destino paradisíaco ou caminhando em um campo florido, sempre com um look descontraído e cheio de personalidade.',
    },
    {
      title: 'Edgy & Rocker',
      audience: 195,
      similarity: 71,
      arpu: 80,
      explanation:
        'Estilo marcante e rebelde, inspirado no rock e no punk, com peças em couro, preto, tachas e cortes ousados. Ideal para shows, festivais de música, baladas alternativas e encontros onde a atitude faz parte do look. Para pessoas que amam música, têm um espírito rebelde e gostam de roupas que traduzam sua atitude e autenticidade. O visual imaginado é de alguém em um show ao vivo ou em um bar underground, com um look cheio de atitude, combinando peças escuras e detalhes metálicos.',
    },
    {
      title: 'Techwear Enthusiasts',
      audience: 230,
      similarity: 77,
      arpu: 95,
      explanation:
        'Moda futurista e funcional, com tecidos tecnológicos, cortes utilitários e design inspirado na estética cyberpunk. Perfeito para o dia a dia na cidade, eventos de tecnologia, ambientes urbanos modernos e momentos que exigem praticidade com estilo. Para pessoas futuristas e conectadas, que buscam peças versáteis, resistentes e preparadas para diferentes condições climáticas. O visual imaginado é de alguém caminhando pela cidade à noite, com um look monocromático, repleto de bolsos estratégicos, tecidos impermeáveis e um visual inspirado no futuro.',
    },
    {
      title: 'Nightlife Glam',
      audience: 265,
      similarity: 73,
      arpu: 98,
      explanation:
        'Moda sofisticada e chamativa, com brilho, cortes sensuais e tecidos luxuosos para destacar-se em eventos noturnos. Ideal para festas, baladas, eventos exclusivos e momentos em que glamour e elegância são essenciais. Para pessoas que adoram sair à noite e buscam peças que expressem confiança e sofisticação em cada detalhe. O visual imaginado é de alguém em uma festa exclusiva, sob luzes brilhantes, vestindo um look impecável que reflete sua personalidade vibrante e glamourosa.',
    },
    {
      title: 'Statement Dressers',
      audience: 175,
      similarity: 69,
      arpu: 86,
      explanation:
        'Peças impactantes e cheias de personalidade, que funcionam como protagonistas no look e não passam despercebidas. Perfeito para eventos sociais, festas, desfiles e momentos onde a moda é uma forma de expressão. Para pessoas criativas e confiantes, que usam a moda para se expressar e não têm medo de chamar atenção. O visual imaginado é de alguém entrando em um evento com um look arrebatador, onde cada detalhe foi pensado para impressionar e refletir sua identidade.',
    },
    {
      title: 'Ethnic & Cultural Fashion',
      audience: 205,
      similarity: 72,
      arpu: 83,
      explanation:
        'Moda inspirada em tradições culturais, com tecidos autênticos, estampas étnicas e modelagens que celebram a diversidade. Ideal para festivais culturais, encontros temáticos, viagens internacionais e ocasiões que valorizam heranças culturais. Para pessoas que apreciam a riqueza cultural da moda e buscam peças que contem histórias e carreguem significados simbólicos. O visual imaginado é de alguém em um evento cultural, vestindo uma peça com cores vibrantes e padrões tradicionais, destacando-se pelo respeito e valorização das heranças culturais.',
    },
  ];

  const navigate = useNavigate();

  const handleDownloadCSV = (title: string, audience: number) => {
    console.log(`Downloading CSV for ${title} with audience ${audience}`);
  };

  const handleCreateNewBehavior = () => {
    navigate(ROUTE_PATHS.AUDIENCES_CREATE);
  };

  return (
    <div className="py-8">
      <section className="mb-10">
        <h1 className="text-[42px] tracking-[8.25px]">behaviors</h1>
      </section>

      <section className="mb-10">
        <h2 className="mb-4 text-xl font-semibold">Suggested Behavior</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {suggestedBehaviors.map((behavior, index) => (
            <Card key={index} className="overflow-hidden">
              <CardHeader className="pb-2">
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-1.5">
                    <CardTitle className="text-lg font-bold">
                      {behavior.title}
                    </CardTitle>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <button className="text-gray-400 hover:text-gray-600">
                            <Info size={16} />
                          </button>
                        </TooltipTrigger>
                        <TooltipContent className="max-w-xs border border-gray-200 bg-background text-foreground shadow-md">
                          <p>{behavior.explanation}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <button className="text-gray-400 hover:text-gray-600">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="2" fill="currentColor" />
                      <circle cx="12" cy="5" r="2" fill="currentColor" />
                      <circle cx="12" cy="19" r="2" fill="currentColor" />
                    </svg>
                  </button>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">Audience Size</span>
                    <button
                      onClick={() =>
                        handleDownloadCSV(behavior.title, behavior.audience)
                      }
                      className="flex items-center text-blue-600 hover:underline"
                    >
                      <span className="mr-1 w-10 text-right font-semibold">
                        {behavior.audience}
                      </span>
                      <Download size={14} />
                    </button>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">
                      Similarity Score
                    </span>
                    <span className="w-10 text-right font-semibold">
                      {behavior.similarity}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-600">ARPU</span>
                    <span className="w-10 text-right font-semibold">
                      ${behavior.arpu}
                    </span>
                  </div>

                  <div className="my-2 border-t border-gray-200"></div>

                  <div className="flex items-center justify-between pt-1">
                    <div className="relative h-6 w-20">
                      <img
                        src={insiderLogo}
                        alt="Insider Logo"
                        width={80}
                        height={24}
                        className="object-contain"
                      />
                    </div>
                    <Switch />
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      <section>
        <h2 className="mb-4 text-xl font-semibold">Custom Behavior</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          <Card className="overflow-hidden">
            <CardHeader className="pb-2">
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-1.5">
                  <CardTitle className="text-lg font-bold">
                    Comfort Seekers - Feminino Adulto
                  </CardTitle>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button className="text-gray-400 hover:text-gray-600">
                          <Info size={16} />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-xs border border-gray-200 bg-background text-foreground shadow-md">
                        <p>
                          Considere apenas as compras do feminino adulto.
                          <br />
                          <br />
                          Moda focada no bem-estar, com peças macias, modelagens
                          soltas e tecidos que garantem conforto absoluto.
                          <br />
                          <br />
                          Perfeito para momentos de descanso, home office,
                          viagens longas e qualquer situação onde o conforto
                          seja prioridade.
                          <br />
                          <br />
                          Para pessoas que valorizam roupas funcionais e
                          aconchegantes, escolhendo peças que tragam bem-estar e
                          praticidade para o dia a dia.
                          <br />
                          <br />O visual imaginado é de alguém relaxando em
                          casa, curtindo um café aconchegante ou viajando com um
                          look que equilibra estilo e conforto.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <button className="text-gray-400 hover:text-gray-600">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="2" fill="currentColor" />
                    <circle cx="12" cy="5" r="2" fill="currentColor" />
                    <circle cx="12" cy="19" r="2" fill="currentColor" />
                  </svg>
                </button>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">Audience Size</span>
                  <button className="flex items-center text-blue-600 hover:underline">
                    <span className="mr-1 w-10 text-right font-semibold">
                      178
                    </span>
                    <Download size={14} />
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">
                    Similarity Score
                  </span>
                  <span className="w-10 text-right font-semibold">92</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">ARPU</span>
                  <span className="w-10 text-right font-semibold">$73</span>
                </div>

                <div className="my-2 border-t border-gray-200"></div>

                <div className="flex items-center justify-between pt-1">
                  <div className="relative h-6 w-20">
                    <img
                      src={insiderLogo}
                      alt="Insider Logo"
                      width={80}
                      height={24}
                      className="object-contain"
                    />
                  </div>
                  <Switch defaultChecked />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="flex h-[180px] items-center justify-center border-2 border-dashed">
            <Button
              variant="ghost"
              className="flex flex-col items-center p-6"
              onClick={handleCreateNewBehavior}
            >
              <Plus size={24} className="mb-2" />
              <span>Create new Behavior</span>
            </Button>
          </Card>
        </div>
      </section>
    </div>
  );
}
