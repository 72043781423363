import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { FormGroup } from '@/components/FormGroup';
import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { useAuth } from '@/contexts/AuthContext';
import { useUpdateVtexCredentials } from '@/hooks/useUpdateVtexCredentials';

const formSchema = z.object({
  url: z.string().min(1, { message: 'URL is required' }).url('Invalid URL'),
  key: z.string().min(1, { message: 'API Key is required' }),
  token: z.string().min(1, { message: 'API Token is required' }),
  salesChannel: z.string().min(1, { message: 'Sales Channel is required' }),
  affiliateId: z.string().min(1, { message: 'Affiliate ID is required' }),
});

type FormData = z.infer<typeof formSchema>;

export function Form() {
  const { user } = useAuth();

  const [isEditable, setIsEditable] = useState<boolean>(() => {
    return !(
      user?.customer.vtexUrl &&
      user?.customer.vtexAppKey &&
      user?.customer.vtexAppToken &&
      user?.customer.vtexSalesChannel &&
      user?.customer.vtexAffiliateId
    );
  });

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    setValue,
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: user?.customer.vtexUrl ?? '',
      key: user?.customer.vtexAppKey ?? '',
      token: user?.customer.vtexAppToken ?? '',
      salesChannel: String(user?.customer.vtexSalesChannel ?? 1),
      affiliateId: user?.customer.vtexAffiliateId ?? 'FFF',
    },
  });

  const { updateVtexCredentials } = useUpdateVtexCredentials();

  function handleEdit() {
    setValue('key', '');
    setValue('token', '');
    setIsEditable(true);
  }

  async function onSubmit({
    url,
    key,
    token,
    salesChannel,
    affiliateId,
  }: FormData) {
    try {
      await updateVtexCredentials({
        vtexUrl: url,
        vtexAppKey: key,
        vtexAppToken: token,
        vtexSalesChannel: Number(salesChannel),
        vtexAffiliateId: affiliateId,
      });

      toast.success('Successfully updated VTEX Credentials.');
      setIsEditable(false);
    } catch {
      toast.error('Error updating VTEX Credentials.');
    }
  }

  return (
    <form
      className="flex h-full flex-col space-y-6"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormGroup
        className="space-y-2"
        error={{ message: errors.url?.message, font: 'sm' }}
      >
        <Label htmlFor="url">URL</Label>

        <Input
          id="url"
          placeholder="Enter your VTEX URL"
          className="w-full"
          disabled={!isEditable}
          {...register('url')}
        />

        {!errors.url?.message && (
          <p
            className="text-sm text-muted-foreground"
            dangerouslySetInnerHTML={{
              __html: `The URL of your VTEX store, used to make API requests and access the storefront. It follows the format https://{{accountName}}.vtexcommercestable.com.br`,
            }}
          />
        )}
      </FormGroup>

      <FormGroup
        className="space-y-2"
        error={{ message: errors.key?.message, font: 'sm' }}
      >
        <Label htmlFor="vtexApiKey">API Key</Label>

        <Input
          id="vtexApiKey"
          type="password"
          placeholder="Enter your VTEX API key"
          className="w-full"
          disabled={!isEditable}
          {...register('key')}
        />

        {!errors.key?.message && (
          <p className="text-sm text-muted-foreground">
            You can find your API key in the VTEX admin panel
          </p>
        )}
      </FormGroup>

      <FormGroup
        className="space-y-2"
        error={{ message: errors.token?.message, font: 'sm' }}
      >
        <Label htmlFor="vtexApiToken">API Token</Label>

        <Input
          id="vtexApiToken"
          type="password"
          placeholder="Enter your VTEX API Token"
          className="w-full"
          disabled={!isEditable}
          {...register('token')}
        />

        {!errors.token?.message && (
          <p className="text-sm text-muted-foreground">
            The API token is used to authenticate your requests
          </p>
        )}
      </FormGroup>

      <FormGroup
        className="space-y-2"
        error={{ message: errors.salesChannel?.message, font: 'sm' }}
      >
        <Label htmlFor="vtexSalesChannel">
          Trade Policy (The value must be greater than or equal to 1)
        </Label>

        <Input
          id="vtexSalesChannel"
          type="number"
          placeholder="Enter Sales Channel"
          className="w-full"
          disabled={!isEditable}
          {...register('salesChannel')}
        />

        {!errors.salesChannel?.message && (
          <p className="text-sm text-muted-foreground">
            The identifier for the sales channel where transactions will be
            processed.
          </p>
        )}
      </FormGroup>

      <FormGroup
        className="space-y-2"
        error={{ message: errors.affiliateId?.message, font: 'sm' }}
      >
        <Label htmlFor="vtexaffiliateId">Affiliate Id</Label>

        <Input
          id="vtexaffiliateId"
          placeholder="Enter Affiliate Id"
          className="w-full"
          disabled={!isEditable}
          {...register('affiliateId')}
        />

        {!errors.affiliateId?.message && (
          <p className="text-sm text-muted-foreground">
            The unique identifier assigned to the affiliate.
          </p>
        )}
      </FormGroup>

      {isEditable && (
        <Button
          type="submit"
          className="mt-auto w-full bg-black text-white transition-colors hover:bg-gray-800"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save VTEX Settings'}
        </Button>
      )}

      {!isEditable && (
        <Button
          type="button"
          className="mt-auto w-full bg-black text-white transition-colors hover:bg-gray-800"
          onClick={handleEdit}
        >
          Edit
        </Button>
      )}
    </form>
  );
}
