import { httpClient } from '@/lib/httpClient';

export interface CreateFeedbackDto {
  attributeId: string;
  comments: string;
  upVote: boolean;
  downVote: boolean;
  productId: number;
}

export interface FeedbackResponse {
  id: number;
  attributeId: string;
  comments: string;
  downVoteDate: string | null;
  upVoteDate: string | null;
}

class FeedbacksService {
  constructor() {}

  async createFeedback(createFeedbackDto: CreateFeedbackDto) {
    const { data } = await httpClient.post<FeedbackResponse>(
      '/v1/feedbacks',
      createFeedbackDto,
    );

    return data;
  }

  async getFeedbackByProductId(productId: number) {
    const { data } = await httpClient.get<FeedbackResponse[]>(
      `/v1/feedbacks/product/${productId}`,
    );

    return data;
  }
}

export default new FeedbacksService();
