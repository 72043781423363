import { ComponentType, lazy } from 'react';

import { ROUTE_PATHS } from '@/config/constants';
import { Role } from '@/contexts/AuthContext';
import { AdminLayout } from '@/layouts/AdminLayout';
import { AuthLayout } from '@/layouts/AuthLayout';
import { DefaultLayout } from '@/layouts/DefaultLayout';
import { AdminCustomersCreate } from '@/pages/admin/CustomersCreate';
import { AdminUsersCreate } from '@/pages/admin/UsersCreate';
import { AdminDashboard } from '@/pages/admin/Dashboard';
import { Analytics } from '@/pages/Analytics';
import { Audiences } from '@/pages/Audiences';
import { AudiencesCreate } from '@/pages/AudiencesCreate';
import { AuthCallback } from '@/pages/AuthCallback';
import { Login } from '@/pages/Login';
import { NotFound } from '@/pages/NotFound';
import { SeoContent } from '@/pages/SeoContent';
import { Settings } from '@/pages/Settings';
import { StylistAi } from '@/pages/StylistAi';
import { Taxonomy } from '@/pages/Taxonomy';
import { VisionEnrichment } from '@/pages/VisionEnrichment';
import { VisionProduct } from '@/pages/VisionProduct';
import { CrmBehaviors } from '@/pages/CrmBehaviors';
import { Home } from '@/pages/Home';

const Copilot = lazy(() => import('@/pages/Copilot'));

export type RouteItem = {
  path: string;
  Component: ComponentType;
  Layout?: ComponentType;
  enabled: boolean;
  isPrivate?: boolean;
  roles?: Role[];
};

export const routes: RouteItem[] = [
  // Start user routes
  {
    path: ROUTE_PATHS.HOME,
    Component: Home,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.COPILOT,
    Component: Copilot,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: false,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.SETTINGS,
    Component: Settings,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.VISION_ENRICHMENT,
    Component: VisionEnrichment,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: `${ROUTE_PATHS.VISION_ENRICHMENT}/:productId`,
    Component: VisionProduct,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.TAXONOMY,
    Component: Taxonomy,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.AUDIENCES,
    Component: Audiences,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.AUDIENCES_CREATE,
    Component: AudiencesCreate,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.SEO_CONTENT,
    Component: SeoContent,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.CRM_BEHAVIORS,
    Component: CrmBehaviors,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.ANALYTICS,
    Component: Analytics,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: false,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.STYLIST_AI,
    Component: StylistAi,
    Layout: DefaultLayout,
    isPrivate: true,
    enabled: false,
    roles: [Role.Admin, Role.User],
  },
  {
    path: ROUTE_PATHS.AUTH_CALLBACK,
    Component: AuthCallback,
    isPrivate: true,
    enabled: true,
  },
  // End user routes

  // Start admin routes
  {
    path: ROUTE_PATHS.ADMIN_DASHBOARD,
    Component: AdminDashboard,
    Layout: AdminLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Root],
  },
  {
    path: ROUTE_PATHS.ADMIN_CUSTOMERS_CREATE,
    Component: AdminCustomersCreate,
    Layout: AdminLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Root],
  },
  {
    path: ROUTE_PATHS.ADMIN_USERS_CREATE,
    Component: AdminUsersCreate,
    Layout: AdminLayout,
    isPrivate: true,
    enabled: true,
    roles: [Role.Root],
  },
  // End admin routes

  // Start public routes
  {
    path: ROUTE_PATHS.LOGIN,
    Component: Login,
    Layout: AuthLayout,
    enabled: true,
    isPrivate: false,
  },
  // End public routes

  // Start found route
  {
    path: ROUTE_PATHS.NOT_FOUND,
    Component: NotFound,
    enabled: true,
  },
  // End not found route
];
