import { Loader2 } from 'lucide-react';

import { CSVDownloadButton } from '@/components/CSVDownloadButton';
import { Button } from '@/components/ui/Button';
import { useGetVisionProducts } from '@/hooks/useGetVisionProducts';

import { extractedAttributes } from '../../utils/extractedAttributes';

export function DownloadButton() {
  const { visionProducts, isLoading, getVisionProducts } =
    useGetVisionProducts();

  const data =
    visionProducts?.map((visionProduct) => {
      const {
        altDescriptionProduct,
        imagesAltText,
        productName,
        productDescription,
        description,
        productTags,
      } = extractedAttributes(visionProduct.productAttributes);

      return {
        product_id: visionProduct.productId,
        product_name: productName ?? visionProduct.name,
        product_description: productDescription ?? description,
        alt_description_product: altDescriptionProduct,
        product_tags: productTags,
        alt_description_image_1: imagesAltText[0],
        alt_description_image_2: imagesAltText[1],
        alt_description_image_3: imagesAltText[2],
        alt_description_image_4: imagesAltText[3],
        alt_description_image_5: imagesAltText[4],
        alt_description_image_6: imagesAltText[5],
        alt_description_image_7: imagesAltText[6],
        alt_description_image_8: imagesAltText[7],
        alt_description_image_9: imagesAltText[8],
        alt_description_image_10: imagesAltText[9],
      };
    }) ?? [];

  const headers = [
    { label: 'product_id', key: 'product_id' },
    { label: 'product_name', key: 'product_name' },
    { label: 'product_description', key: 'product_description' },
    { label: 'alt_description_product', key: 'alt_description_product' },
    { label: 'product_tags', key: 'product_tags' },
    { label: 'alt_description_image_1', key: 'alt_description_image_1' },
    { label: 'alt_description_image_2', key: 'alt_description_image_2' },
    { label: 'alt_description_image_3', key: 'alt_description_image_3' },
    { label: 'alt_description_image_4', key: 'alt_description_image_4' },
    { label: 'alt_description_image_5', key: 'alt_description_image_5' },
    { label: 'alt_description_image_6', key: 'alt_description_image_6' },
    { label: 'alt_description_image_7', key: 'alt_description_image_7' },
    { label: 'alt_description_image_8', key: 'alt_description_image_8' },
    { label: 'alt_description_image_9', key: 'alt_description_image_9' },
    { label: 'alt_description_image_10', key: 'alt_description_image_10' },
  ];

  async function handleDownload() {
    await getVisionProducts();
  }

  return (
    <div className="flex items-center justify-end">
      <CSVDownloadButton
        data={data}
        headers={headers}
        onDownload={handleDownload}
        asChild
      >
        <Button disabled={isLoading}>
          {isLoading ? (
            <>
              <Loader2 className="animate-spin" /> Generating Product Data
            </>
          ) : (
            'Download Product Data'
          )}
        </Button>
      </CSVDownloadButton>
    </div>
  );
}
