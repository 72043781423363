import {
  BookImage,
  ChartLine,
  Fingerprint,
  LogOut,
  Search,
  Settings,
  Target,
  User,
  Wand2,
} from 'lucide-react';
import { MouseEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ROUTE_PATHS } from '@/config/constants';
import { useAuth } from '@/contexts/AuthContext';

import { AssistantIcon } from '../AssistantIcon';

import { FooterLink } from './components/FooterLink';
import { NavigationLink } from './components/NavigationLink';
import { NewConversationButton } from './components/NewConversationButton';
import { FashionaiLogo } from '../FashionaiLogo';

export function NavigationBar() {
  const { pathname } = useLocation();

  const { logout } = useAuth();

  function handleLogout(
    event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  ) {
    event.preventDefault();

    logout();
  }

  return (
    <aside className="hidden w-[282px] lg:block">
      <div className="sticky left-0 top-0 flex h-dvh w-[282px] flex-col overflow-scroll px-6 py-10 scrollbar-hide">
        <header className="mb-9 flex items-center justify-between">
          <Link to={ROUTE_PATHS.HOME}>
            <FashionaiLogo />
          </Link>

          {pathname === ROUTE_PATHS.COPILOT && <NewConversationButton />}
        </header>

        <nav className="mb-[10px] flex-1">
          <ul className="flex flex-col gap-4">
            <NavigationLink to={ROUTE_PATHS.VISION_ENRICHMENT}>
              <BookImage /> Vision Enrichment
            </NavigationLink>

            <NavigationLink to={ROUTE_PATHS.TAXONOMY}>
              <Fingerprint /> My Style AI
            </NavigationLink>

            <NavigationLink to={ROUTE_PATHS.AUDIENCES}>
              <Target /> Performance Hub
            </NavigationLink>

            <NavigationLink to={ROUTE_PATHS.CRM_BEHAVIORS} isInBeta>
              <User /> Behaviors
            </NavigationLink>

            <NavigationLink to={ROUTE_PATHS.SEO_CONTENT}>
              <Search /> Seo Content
            </NavigationLink>

            <NavigationLink to={ROUTE_PATHS.ANALYTICS}>
              <ChartLine /> Analytics
            </NavigationLink>

            <NavigationLink to={ROUTE_PATHS.STYLIST_AI}>
              <Wand2 /> Stylist AI
            </NavigationLink>

            <NavigationLink to={ROUTE_PATHS.COPILOT} isAssistant>
              <AssistantIcon /> Copilot
            </NavigationLink>
          </ul>
        </nav>

        <footer>
          <nav>
            <ul>
              <FooterLink to={ROUTE_PATHS.SETTINGS}>
                Settings <Settings />
              </FooterLink>

              <FooterLink to="#" onClick={handleLogout}>
                Logout <LogOut />
              </FooterLink>
            </ul>
          </nav>
        </footer>
      </div>
    </aside>
  );
}
