import './Carousel.css';

import { ElementRef, useEffect, useRef } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperProps } from 'swiper/react';

import { NavigationButton } from './NavigationButton';

import { useForceUpdate } from '@/hooks/useForceUpdate';
import { cn } from '@/lib/utils';

interface CarouselProps extends SwiperProps {
  className?: string;
  navigation?: {
    enabled: boolean;
  };
  pagination?: {
    enabled: boolean;
  };
}

export function Carousel({
  children,
  className,
  navigation = { enabled: false },
  pagination = { enabled: false },
  ...props
}: CarouselProps) {
  const prevElementRef = useRef<ElementRef<'button'>>(null);
  const nextElementRef = useRef<ElementRef<'button'>>(null);

  const { forceUpdate } = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, [forceUpdate]);

  return (
    <Swiper
      className={cn('carousel', className)}
      modules={[Autoplay, Navigation, Pagination]}
      navigation={{
        enabled: navigation.enabled,
        disabledClass: 'carousel__navigation--button-disabled',
        prevEl: prevElementRef.current,
        nextEl: nextElementRef.current,
      }}
      pagination={{
        enabled: pagination.enabled,
        clickable: true,
        clickableClass: 'carousel__pagination--bullets',
        bulletClass: 'carousel__pagination--bullet',
        bulletActiveClass: 'carousel__pagination--bullet-active',
      }}
      {...props}
    >
      {children}

      {navigation.enabled && (
        <>
          <NavigationButton ref={prevElementRef} direction="prev" />
          <NavigationButton ref={nextElementRef} direction="next" />
        </>
      )}
    </Swiper>
  );
}
