import './NavigationButton.css';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import { forwardRef } from 'react';

import { cn } from '@/lib/utils';

interface NavigationButtonProps {
  direction: 'prev' | 'next';
}

export const NavigationButton = forwardRef<
  HTMLButtonElement,
  NavigationButtonProps
>(({ direction }, ref) => {
  const isNext = direction === 'next';

  return (
    <button
      ref={ref}
      className={cn(
        'carousel__navigation--button',
        isNext
          ? 'carousel__navigation--button-next'
          : 'carousel__navigation--button-prev',
      )}
    >
      {isNext ? <ChevronRight /> : <ChevronLeft />}
    </button>
  );
});

NavigationButton.displayName = 'NavigationButton';
