import { useMutation } from '@tanstack/react-query';

import UserService, { CreateUserDto } from '@/services/UserService';

export function useCreateUser() {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (createUserDto: CreateUserDto) =>
      UserService.create(createUserDto),
  });

  return {
    createUser: mutateAsync,
    isLoading: isPending,
  };
}
