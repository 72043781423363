import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface TaxonomyContextValue {
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
}

export const TaxonomyContext = createContext({} as TaxonomyContextValue);

export function TaxonomyProvider({ children }: { children: ReactNode }) {
  const [selectedCategory, setSelectedCategory] = useState('');

  return (
    <TaxonomyContext.Provider value={{ selectedCategory, setSelectedCategory }}>
      {children}
    </TaxonomyContext.Provider>
  );
}

export function useTaxonomyContext() {
  return useContext(TaxonomyContext);
}
