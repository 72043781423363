import { Navigate, Outlet } from 'react-router-dom';

import { ROUTE_PATHS } from '@/config/constants';
import { Role } from '@/contexts/AuthContext';
import { withUser } from '@/hocs/withUser';

interface RoleGuardProps {
  allowedRoles: Role[];
}

export const RoleGuard = withUser<RoleGuardProps>(({ allowedRoles, user }) => {
  const role = user?.role.id;

  if (!role) {
    return <Outlet />;
  }

  if (role === Role.Root && !allowedRoles.includes(role)) {
    return <Navigate to={ROUTE_PATHS.ADMIN_DASHBOARD} replace />;
  }

  if (role !== Role.Root && !allowedRoles.includes(role)) {
    return <Navigate to={ROUTE_PATHS.HOME} replace />;
  }

  return <Outlet />;
});
