import { Outlet } from 'react-router-dom';

import { useAuth } from '@/contexts/AuthContext';

import { NavigationBar } from '../components/NavigationBar';

export function DefaultLayout() {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <div className="flex">
      <NavigationBar />

      <main className="w-full overflow-hidden px-6 lg:px-[60px]">
        <Outlet />
      </main>
    </div>
  );
}
