import { useMutation } from '@tanstack/react-query';

import AuthService, { ChangePasswordDto } from '@/services/AuthService';

export function useChangePassword() {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (changePasswordDto: ChangePasswordDto) =>
      AuthService.changePassword(changePasswordDto),
  });

  return {
    changePassword: mutateAsync,
    isLoading: isPending,
  };
}
