import { LoaderCircle } from 'lucide-react';
import { useEffect, useRef } from 'react';

import { ProductCard } from '@/components/ProductCard';
import { useGetProducts } from '@/hooks/useGetProducts';

export function VisionEnrichment() {
  const endProductsRef = useRef<HTMLDivElement>(null);

  const { products, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetProducts();

  useEffect(() => {
    if (!endProductsRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, obs) => {
        const { isIntersecting } = entries[0];

        if (!hasNextPage) {
          obs.disconnect();
          return;
        }

        if (isIntersecting && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        rootMargin: '30px',
      },
    );

    observer.observe(endProductsRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  return (
    <div className="py-8">
      <section className="mb-10">
        <h1 className="text-[42px] tracking-[8.25px]">vision enrichment</h1>
      </section>

      {products.length > 0 && (
        <section>
          <ul className="grid w-full grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-4">
            {products.map((product) => (
              <li key={product.id}>
                <ProductCard
                  id={product.id}
                  image1={product.image1}
                  image2={product.image2}
                  name={product.name}
                  price={product.price}
                  salePrice={product.salePrice}
                />
              </li>
            ))}
          </ul>

          <div ref={endProductsRef}>
            {isFetchingNextPage && (
              <LoaderCircle className="mx-auto mt-10 animate-spin" />
            )}
          </div>
        </section>
      )}
    </div>
  );
}
