import fashionaiLogo from '@/assets/fashionai-logo.png';

export function FashionaiLogo() {
  return (
    <img
      src={fashionaiLogo}
      alt="FashionAI logo"
      className="h-auto w-[146px]"
    />
  );
}
