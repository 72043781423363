import { useState } from 'react';

import { Label } from '@/components/ui/Label';
import { Slider } from '@/components/ui/Slider';

interface AudienceSliderControlProps {
  productsInSet: {
    from: number;
    to: number;
    suggestion: number;
    value: number;
  };
  audienceSize: {
    from: number;
    to: number;
    suggestion: number;
    value: number;
  };
  onChangeAudienceSize: (newValue: number) => void;
  onChangeProductsInSet: (newValue: number) => void;
}

export function AudienceSliderControl({
  audienceSize,
  productsInSet,
  onChangeAudienceSize,
  onChangeProductsInSet,
}: AudienceSliderControlProps) {
  const [audienceSizeSliderValue, setAudienceSizeSliderValue] = useState(
    audienceSize.value,
  );
  const [productsInSetSliderValue, setProductsInSetSliderValue] = useState(
    productsInSet.value,
  );

  const audienceSizeStep = audienceSize.to < 1 ? 0.001 : 1;

  function handleChangeAudienceSizeSliderValue(newValue: number[]) {
    setAudienceSizeSliderValue(newValue[0]);
  }

  function handleCommitAudienceSizeValue(newValue: number[]) {
    onChangeAudienceSize(newValue[0]);
  }

  function handleChangeProductsInSetSliderValue(newValue: number[]) {
    setProductsInSetSliderValue(newValue[0]);
  }

  function handleCommitProductsInSetValue(newValue: number[]) {
    onChangeProductsInSet(newValue[0]);
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label>Audience Size</Label>

        <Slider
          value={[audienceSizeSliderValue]}
          defaultValue={[audienceSize.suggestion]}
          min={audienceSize.from}
          max={audienceSize.to}
          step={audienceSizeStep}
          onValueChange={handleChangeAudienceSizeSliderValue}
          onValueCommit={handleCommitAudienceSizeValue}
        />
        <div className="flex justify-between text-sm text-muted-foreground">
          <span>{audienceSize.from}</span>
          <span>{audienceSize.to}</span>
        </div>
      </div>

      <div className="space-y-2">
        <Label>Product Set Size</Label>

        <Slider
          value={[productsInSetSliderValue]}
          defaultValue={[productsInSet.suggestion]}
          min={productsInSet.from}
          max={productsInSet.to}
          onValueChange={handleChangeProductsInSetSliderValue}
          onValueCommit={handleCommitProductsInSetValue}
        />

        <div className="flex justify-between text-sm text-muted-foreground">
          <span>{productsInSet.from}</span>
          <span>{productsInSet.to}</span>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="text-2xl font-bold">{audienceSizeSliderValue}</div>
          <p className="text-sm text-muted-foreground">audience size</p>
        </div>

        <div>
          <div className="text-2xl font-bold">{productsInSetSliderValue}</div>
          <p className="text-sm text-muted-foreground">products in set</p>
        </div>
      </div>
    </div>
  );
}
