import { httpClient } from '@/lib/httpClient';

export interface MetaOAuthResponse {
  url: string;
}

export interface MetaDisconnectResponse {
  disconnected: boolean;
}

export interface MetaBusinessAccountResponse {
  id: string;
  name: string;
}

export interface MetaCatalogResponse {
  id: string;
  name: string;
}

export interface MetaPagesResponse {
  id: string;
  name: string;
}

export interface MetaAdAccountsResponse {
  id: string;
  name: string;
}

export interface MetaConfigDto {
  businessId: string;
  catalogId: string;
  facebookPageId: string;
  facebookAdAccountId: string;
}

class MetaService {
  constructor() {}

  async metaOAuth() {
    const { data } = await httpClient.get<MetaOAuthResponse>(
      '/v1/meta/auth/oauthurl',
    );

    return data;
  }

  async metaDisconnect() {
    const { data } = await httpClient.patch<MetaDisconnectResponse>(
      '/v1/meta/disconnect',
      null,
    );

    return data;
  }

  async metaPages() {
    const { data } =
      await httpClient.get<MetaPagesResponse[]>('/v1/meta/pages');

    return data;
  }

  async metaAdAccounts() {
    const { data } = await httpClient.get<MetaAdAccountsResponse[]>(
      '/v1/meta/ad-account',
    );

    return data;
  }

  async metaBusinessAccounts() {
    const { data } = await httpClient.get<MetaBusinessAccountResponse[]>(
      '/v1/meta/business-account',
    );

    return data;
  }

  async metaCatalog(businessAccount: string) {
    const { data } = await httpClient.get<MetaCatalogResponse[]>(
      `/v1/meta/catalog/${businessAccount}`,
    );

    return data;
  }

  async metaConfig(metaConfigDto: MetaConfigDto) {
    const { data } = await httpClient.post(`/v1/meta/config`, metaConfigDto);

    return data;
  }
}

export default new MetaService();
