import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import fashionLogo from '@/assets/fashion-logo.svg';
import AuthCallbackBroadcast from '@/broadcasts/AuthCallbackBroadcast';
import { Button } from '@/components/ui/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/Card';
import { ROUTE_PATHS } from '@/config/constants';

export function AuthCallback() {
  useEffect(() => {
    AuthCallbackBroadcast.sendMessage({ success: true });
  }, []);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <Card className="w-full max-w-md">
        <CardHeader>
          <div className="mb-4 flex h-12 w-full items-center justify-center rounded-full">
            <img src={fashionLogo} alt="Fashion.ai Logo" />
          </div>
          <CardTitle className="text-center">Connection Successful</CardTitle>
          <CardDescription className="text-center">
            Your account has been successfully connected.
          </CardDescription>
        </CardHeader>

        <CardContent>
          <p className="text-center text-sm text-muted-foreground">
            You can now use features in your Fashion.ai account. Your
            integration settings have been updated automatically.
          </p>
        </CardContent>

        <CardFooter className="flex justify-center">
          <Link to={ROUTE_PATHS.SETTINGS}>
            <Button>Return to Settings</Button>
          </Link>
        </CardFooter>
      </Card>
    </div>
  );
}
