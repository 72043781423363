import { useQuery } from '@tanstack/react-query';

import AudiencesService from '@/services/AudiencesService';

export const GET_AUDIENCE_USERS_QUERY_KEY = (audienceId: number) => [
  'audience-users',
  audienceId,
];

export function useGetAudienceUsers(audienceId: number) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: GET_AUDIENCE_USERS_QUERY_KEY(audienceId),
    enabled: false,
    queryFn: () => AudiencesService.getAudienceUsers(audienceId),
  });

  return {
    audienceUsers: data,
    isLoading: isFetching,
    getAudienceUsers: refetch,
  };
}
