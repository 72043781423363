import { capitalize } from '@/lib/utils';
import { VisionProductAttributes } from '@/services/ProductsService';

import { AttributeItem } from './AttributeItem';

const hiddenAttributes = [
  'seo_content',
  'seo_content_default',
  'itens_details',
  'items_details',
];

interface VisionDescriptionProps {
  productId: number;
  externalProductId: string;
  visionCategory: string;
  attributes: VisionProductAttributes;
}

export function Attributes({
  productId,
  externalProductId,
  visionCategory,
  attributes,
}: VisionDescriptionProps) {
  if (!attributes) {
    return null;
  }

  return (
    <div className="space-y-8">
      {Object.entries(attributes).map(([key, value]) => {
        if (hiddenAttributes.includes(key)) {
          return null;
        }

        return (
          <section key={key}>
            <header className="flex h-[54px] items-center bg-[#F4F4F4] px-5">
              <h2 className="text-sm font-bold">
                {capitalize(key?.replaceAll?.('_', ' '))}
              </h2>
            </header>

            <dl>
              {value.map((item) => (
                <AttributeItem
                  key={item.id}
                  productId={productId}
                  externalProductId={externalProductId}
                  attributeId={item.id}
                  attributeProperty={item?.property}
                  attributeName={item.name}
                  propertyKey={key}
                  visionCategory={visionCategory}
                />
              ))}
            </dl>
          </section>
        );
      })}
    </div>
  );
}
