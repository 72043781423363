import { useAuth } from '@/contexts/AuthContext';

export function User() {
  const { user } = useAuth();

  const userName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const customerName = user?.customer.name;
  const roleName = user?.role.name;
  const status = user?.customer.freemium ? 'Freemium' : 'Full';

  return (
    <div className="mb-6 rounded-lg bg-gray-100 p-4 capitalize">
      <h2 className="mb-2 text-2xl font-semibold">{userName}</h2>

      <div className="text-muted-foreground">
        <p>Customer: {customerName}</p>
        <p>Role: {roleName}</p>
        <p>Status: {status}</p>
      </div>
    </div>
  );
}
