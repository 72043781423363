import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface SelectedAttribute {
  property: string;
  name: string;
  displayName: string;
}

interface StylistAiContextValue {
  queryText: string;
  isLoading: boolean;
  hasProducts: boolean;
  selectedCategories: string[];
  selectedAttributes: SelectedAttribute[];
  setQueryTextCallback: (value: string) => void;
  setIsLoadingCallback: (value: boolean) => void;
  setHasProductsCallback: (value: boolean) => void;
  setSelectedCategoriesCallback: (values: string[]) => void;
  setSelectedAttributesCallback: (values: SelectedAttribute[]) => void;
}

export const StylistAiContext = createContext({} as StylistAiContextValue);

export function StylistAiProvider({ children }: { children: ReactNode }) {
  const [queryText, setQueryText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasProducts, setHasProducts] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<
    SelectedAttribute[]
  >([]);

  const setQueryTextCallback = useCallback((value: string) => {
    setQueryText(value);
  }, []);

  const setIsLoadingCallback = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const setHasProductsCallback = useCallback((value: boolean) => {
    setHasProducts(value);
  }, []);

  const setSelectedCategoriesCallback = useCallback((values: string[]) => {
    setSelectedCategories(values);
  }, []);

  const setSelectedAttributesCallback = useCallback(
    (values: SelectedAttribute[]) => {
      setSelectedAttributes(values);
    },
    [],
  );

  const value: StylistAiContextValue = {
    queryText,
    isLoading,
    hasProducts,
    selectedCategories,
    selectedAttributes,
    setQueryTextCallback,
    setIsLoadingCallback,
    setHasProductsCallback,
    setSelectedCategoriesCallback,
    setSelectedAttributesCallback,
  };

  return (
    <StylistAiContext.Provider value={value}>
      {children}
    </StylistAiContext.Provider>
  );
}

export function useStylistAiContext() {
  return useContext(StylistAiContext);
}
