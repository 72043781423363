import { Button } from '@/components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/Dialog';

interface ConfirmModalProps {
  isOpen: boolean;
  isSyncEnabled: boolean;
  onToggle: () => void;
  onConfirm: () => void;
}

export function ConfirmModal({
  isOpen,
  isSyncEnabled,
  onToggle,
  onConfirm,
}: ConfirmModalProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onToggle}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>SEO Integration</DialogTitle>

          <DialogDescription>
            Are you sure you want to{' '}
            <strong className="text-red-400">
              {isSyncEnabled ? 'disable' : 'enable'}
            </strong>{' '}
            SEO integration? This action will{' '}
            <strong className="text-red-400">
              {isSyncEnabled ? 'unsync' : 'sync'}
            </strong>{' '}
            your product data for SEO optimization.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant="outline" onClick={onToggle}>
            Cancel
          </Button>

          <Button onClick={onConfirm}>
            {isSyncEnabled ? 'Unsync' : 'Sync'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
