import { useQuery } from '@tanstack/react-query';

import MetaService from '@/services/MetaService';

export const GET_META_PAGES_QUERY_KEY = ['meta-pages'];

export function useGetMetaPages() {
  const { data, isFetching } = useQuery({
    queryKey: GET_META_PAGES_QUERY_KEY,
    queryFn: () => MetaService.metaPages(),
  });

  return {
    metaPages: data ?? [],
    isLoading: isFetching,
  };
}
