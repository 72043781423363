import { useQuery } from '@tanstack/react-query';

import MetaService from '@/services/MetaService';

export const GET_META_CATALOG_QUERY_KEY = ['meta-catalog'];

export function useGetMetaCatalog(businessAccount: string, enabled: boolean) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: GET_META_CATALOG_QUERY_KEY,
    enabled,
    queryFn: () => MetaService.metaCatalog(businessAccount),
  });

  return {
    metaCatalog: data ?? [],
    isLoading: isFetching,
    getMetaCatalog: refetch,
  };
}
