import { Outlet } from 'react-router-dom';

import { AuthCarousel } from '@/components/AuthCarousel';

export function AuthLayout() {
  return (
    <main className="flex h-dvh p-[40px] lg:p-5">
      <section className="w-full lg:w-1/2 lg:py-20">
        <Outlet />
      </section>

      <section className="relative hidden w-1/2 lg:block">
        <AuthCarousel />
      </section>
    </main>
  );
}
