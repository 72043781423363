if ('serviceWorker' in navigator) {
  const controller = new AbortController();

  navigator.serviceWorker.addEventListener(
    'controllerchange',
    () => {
      window.location.reload();
      controller.abort();
    },
    { signal: controller.signal },
  );
}
