import { Target } from 'lucide-react';
import { useState } from 'react';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/Popover';
import { Audience } from '@/services/AudiencesService';

interface AudiencePopoverProps {
  audience: Pick<Audience, 'input_value'>;
}

export function AudiencePopover({ audience }: AudiencePopoverProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  function handleOpenPopover() {
    setIsPopoverOpen(true);
  }

  function handleClosePopover() {
    setIsPopoverOpen(false);
  }

  return (
    <Popover open={isPopoverOpen}>
      <PopoverTrigger
        onClick={handleOpenPopover}
        onMouseEnter={handleOpenPopover}
        onMouseLeave={handleClosePopover}
      >
        <Target className="h-4 w-4 text-muted-foreground" />
      </PopoverTrigger>

      <PopoverContent sideOffset={10} className="w-fit">
        {audience.input_value}
      </PopoverContent>
    </Popover>
  );
}
