import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { routes } from './config/routes';
import { AuthGuard } from './guards/AuthGuard';
import { RoleGuard } from './guards/RoleGuard';

export function Router() {
  return (
    <Suspense>
      <BrowserRouter
        future={{ v7_relativeSplatPath: true, v7_startTransition: true }}
      >
        <Routes>
          {routes.map(
            ({ path, Component, Layout, isPrivate, enabled, roles }) => {
              if (!enabled) {
                return null;
              }

              return (
                <Route
                  key={path}
                  element={
                    typeof isPrivate === 'boolean' && (
                      <AuthGuard isPrivate={isPrivate} />
                    )
                  }
                >
                  <Route element={roles && <RoleGuard allowedRoles={roles} />}>
                    <Route element={Layout && <Layout />}>
                      <Route path={path} element={<Component />} />
                    </Route>
                  </Route>
                </Route>
              );
            },
          )}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
