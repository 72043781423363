import { useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';

import AuthCallbackBroadcast from '@/broadcasts/AuthCallbackBroadcast';
import { ERROR_MESSAGES } from '@/config/constants';
import { storageKeys } from '@/config/storageKeys';
import { useGetMe } from '@/hooks/useGetMe';
import { useLogin } from '@/hooks/useLogin';
import { httpClient } from '@/lib/httpClient';
import { MeResponse } from '@/services/AuthService';
import { env } from '@/config/env';

export enum Role {
  Root = 1,
  User = 2,
  Admin = 3,
}

export interface User extends MeResponse {}

interface AuthContextValue {
  isLoggedIn: boolean;
  user: User | undefined;
  isUserLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext({} as AuthContextValue);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return !!localStorage.getItem(storageKeys.accessToken);
  });

  const queryClient = useQueryClient();

  const { user, isUserLoading, getUser, refetchUser } = useGetMe(isLoggedIn);

  const { loginFn } = useLogin();

  const login = useCallback(
    async (email: string, password: string) => {
      const { token } = await loginFn({ email, password });

      localStorage.setItem(storageKeys.accessToken, token);

      await getUser();
      setIsLoggedIn(true);
    },
    [getUser, loginFn],
  );

  const logout = useCallback(() => {
    localStorage.removeItem(storageKeys.accessToken);
    queryClient.clear();
    setIsLoggedIn(false);
  }, [queryClient]);

  useLayoutEffect(() => {
    const interceptorId = httpClient.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem(storageKeys.accessToken);

      if (accessToken) {
        config.headers.set('Authorization', `Bearer ${accessToken}`);
      }

      return config;
    });

    return () => {
      httpClient.interceptors.request.eject(interceptorId);
    };
  }, []);

  useLayoutEffect(() => {
    const interceptorId = httpClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.code && error.code === 'ERR_NETWORK') {
          toast.error(ERROR_MESSAGES.INTERNAL_SERVER_ERROR);
        }

        if (error.response && error.response.status === 401) {
          logout();
          toast.error(ERROR_MESSAGES.SESSION_EXPIRED);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      httpClient.interceptors.response.eject(interceptorId);
    };
  }, [logout]);

  useEffect(() => {
    AuthCallbackBroadcast.onMessage(async (event) => {
      if (event.success) {
        await refetchUser();
      }
    });
  }, [refetchUser]);

  useEffect(() => {
    if (env.PROD && user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
      });
    }

    if (env.PROD && !user) {
      Sentry.setUser(null);
    }
  }, [user]);

  const value: AuthContextValue = {
    isLoggedIn,
    user,
    isUserLoading,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
