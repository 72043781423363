import { LoaderCircle } from 'lucide-react';
import { ElementRef, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';
import { ROUTE_PATHS } from '@/config/constants';
import { useGetAudiences } from '@/hooks/useGetAudiences';

import { AudiencePopover } from './components/AudiencePopover';
import { AudienceSize } from './components/AudienceSize';
import { MetaSwitch } from './components/MetaSwitch';
import { ProductsInSet } from './components/ProductsInSet';

export function Audiences() {
  const endAudiencesRef = useRef<ElementRef<'div'>>(null);

  const navigate = useNavigate();

  const {
    audiences,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetAudiences();

  function handleCreateAudienceNavigate() {
    navigate(ROUTE_PATHS.AUDIENCES_CREATE);
  }

  useEffect(() => {
    if (!endAudiencesRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, obs) => {
        const { isIntersecting } = entries[0];

        if (!hasNextPage) {
          obs.disconnect();
          return;
        }

        if (isIntersecting && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        rootMargin: '30px',
      },
    );

    observer.observe(endAudiencesRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  return (
    <div className="container py-8">
      <section className="mb-10 flex items-center justify-between">
        <h1 className="text-[42px] tracking-[8.25px]">performance hub</h1>
        <Button onClick={handleCreateAudienceNavigate}>
          Create New Strategy
        </Button>
      </section>

      <div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {audiences.map((audience) => (
            <Card key={audience.id}>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  {audience.name}
                </CardTitle>

                <AudiencePopover audience={audience} />
              </CardHeader>

              <CardContent>
                <div className="grid grid-cols-2 gap-4">
                  <AudienceSize audience={audience} />

                  <ProductsInSet audience={audience} />
                </div>

                <div className="mt-4 space-y-3">
                  <MetaSwitch audience={audience} />
                </div>
              </CardContent>
            </Card>
          ))}
        </div>

        <div ref={endAudiencesRef}>
          {(isFetching || isFetchingNextPage) && (
            <LoaderCircle className="mx-auto mt-10 animate-spin" />
          )}
        </div>
      </div>
    </div>
  );
}
