import { FormGroup } from '@/components/FormGroup';
import { Alert, AlertDescription } from '@/components/ui/Alert';
import { Button } from '@/components/ui/Button';
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { useChangePassword } from '@/hooks/useChangePassword';
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { AlertCircle } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';
import { ChangePasswordResponse } from '@/services/AuthService';

const passwordSchema = z
  .string()
  .min(6, { message: 'The password must be at least 6 characters long.' })
  .refine((password) => /[!@#$%^&*(),.?":{}|<>]/.test(password), {
    message: 'The password must contain at least one special character.',
  })
  .refine((password) => /\d/.test(password), {
    message: 'The password must contain at least one number.',
  })
  .refine((password) => /[a-z]/.test(password), {
    message: 'The password must contain at least one lowercase letter.',
  })
  .refine((password) => /[A-Z]/.test(password), {
    message: 'The password must contain at least one uppercase letter.',
  });

const schema = z
  .object({
    currentPassword: z.string().min(1, 'Current password is required.'),
    newPassword: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match.',
    path: ['confirmPassword'],
  });

type FormData = z.infer<typeof schema>;

export function ChangePassword() {
  const [feedback, setFeedback] = useState<null | {
    type: 'error' | 'success';
    message: string;
  }>(null);

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const { changePassword } = useChangePassword();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { message } = await changePassword({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      });

      setFeedback({ type: 'success', message });
      reset();
    } catch (error) {
      const errorMessage = 'Failed to update password. Please try again.';

      if (isAxiosError<ChangePasswordResponse>(error) && error.status === 400) {
        setFeedback({
          type: 'error',
          message: error.response?.data.message ?? errorMessage,
        });
      }

      toast.error(errorMessage);
    }
  });

  return (
    <div className="max-w-md">
      <h3 className="mb-4 text-base font-medium">Change Password</h3>

      <form onSubmit={onSubmit} className="space-y-4">
        {feedback?.type === 'error' && (
          <Alert variant="destructive" className="py-2">
            <AlertDescription className="flex items-center gap-2">
              <AlertCircle className="h-4 w-4" />
              {feedback.message}
            </AlertDescription>
          </Alert>
        )}

        {feedback?.type === 'success' && (
          <Alert className="border-green-200 bg-green-50 py-2 text-green-800">
            <AlertDescription>{feedback.message}</AlertDescription>
          </Alert>
        )}

        <FormGroup
          error={{ message: errors.currentPassword?.message, font: 'sm' }}
        >
          <Label htmlFor="current-password">Current Password</Label>
          <Input
            id="current-password"
            type="password"
            placeholder="Enter your current password"
            {...register('currentPassword')}
          />
        </FormGroup>

        <FormGroup error={{ message: errors.newPassword?.message, font: 'sm' }}>
          <Label htmlFor="new-password">New Password</Label>
          <Input
            id="new-password"
            type="password"
            placeholder="Enter your new password"
            {...register('newPassword')}
          />
        </FormGroup>

        <FormGroup
          error={{ message: errors.confirmPassword?.message, font: 'sm' }}
        >
          <Label htmlFor="confirm-password">Confirm Password</Label>
          <Input
            id="confirm-password"
            type="password"
            placeholder="Confirm your new password"
            {...register('confirmPassword')}
          />
        </FormGroup>

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
      </form>
    </div>
  );
}
