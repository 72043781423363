import axios from 'axios';
import { LoaderCircle } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/Button';
import { useAudienceCalculate } from '@/hooks/useAudienceCalculate';
import AudiencesService, {
  AudienceCalculateResponse,
} from '@/services/AudiencesService';

import { AudienceFormData, InputTypes } from '..';

interface CalculateButtonProps {
  isCalculated: boolean;
  isCalculating: boolean;
  isFiltering: boolean;
  onCalculate: (audienceCalculated: AudienceCalculateResponse) => void;
  onIsCalculating: (value: boolean) => void;
  onTransformedValue: (value: string) => void;
}

export function CalculateButton({
  isCalculated,
  isCalculating,
  isFiltering,
  onCalculate,
  onIsCalculating,
  onTransformedValue,
}: CalculateButtonProps) {
  const { watch, getValues } = useFormContext<AudienceFormData>();

  const { audienceCalculate } = useAudienceCalculate();

  const name = watch('name');
  const inputValue = watch('inputValue');

  const isDisabled = !name || !inputValue || isFiltering;

  async function sendFile() {
    try {
      const data = await AudiencesService.audiencesUploadUrl();
      const file = getValues('file');

      if (!data || !file) {
        return;
      }

      await axios.put(data.url, file, {
        headers: {
          'Content-Type': 'text/csv',
        },
      });

      return data;
    } catch {
      //
    }
  }

  async function handleCalculate() {
    if (isCalculating) {
      return;
    }

    onIsCalculating(true);

    const { name, inputType, inputValue } = getValues();

    const input_type = InputTypes[inputType];

    try {
      let transformed_value = '';

      if (inputType !== 'CUSTOM') {
        const data = await sendFile();
        transformed_value = data?.filename ?? '';
      }

      if (transformed_value) {
        onTransformedValue(transformed_value);
      }

      const audienceCalculated = await audienceCalculate({
        name,
        input_type,
        input_value: transformed_value || inputValue,
        config_audience_score: false,
        config_productset_score: false,
        meta_integration: false,
        products_in_set: 0,
        audience_size: 0,
      });

      onCalculate(audienceCalculated);
    } catch {
      //
    } finally {
      onIsCalculating(false);
    }
  }

  return (
    <Button type="button" disabled={isDisabled} onClick={handleCalculate}>
      {isCalculating && <LoaderCircle className="animate-spin" />}

      {!isCalculating && !isCalculated && 'Calculate'}
      {!isCalculating && isCalculated && 'Recalculate'}
    </Button>
  );
}
