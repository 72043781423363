import { Carousel, CarouselSlide } from '@/components/Carousel';

import { cn, priceFormatter } from '@/lib/utils';

interface ProductCardProps {
  name: string | undefined;
  images: string[] | undefined;
  link: string | undefined;
  price: string | undefined;
  salePrice: string | undefined;
}

export function ProductCard({
  name,
  images,
  link,
  price = '0',
  salePrice = '0',
}: ProductCardProps) {
  const formattedPrice = parseFloat(price);
  const formattedSalePrice = parseFloat(salePrice);

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="fixed flex h-fit w-[320px] flex-col rounded-lg border border-solid border-[#E0E0E0]"
    >
      <div>
        <Carousel navigation={{ enabled: true }}>
          {images?.map((image) => (
            <CarouselSlide key={image}>
              <div>
                <img className="rounded-t-lg" src={image} alt={name} />
              </div>
            </CarouselSlide>
          ))}
        </Carousel>
      </div>

      <div className="h-20 flex-col p-5 font-light">
        <span className="block truncate text-xs">{name}</span>

        {formattedPrice > 0 && formattedSalePrice > 0 && (
          <div className="mt-1 flex gap-2 text-sm">
            {formattedSalePrice < formattedPrice && (
              <span className="line-through opacity-50">
                {priceFormatter(formattedPrice)}
              </span>
            )}

            <span
              className={cn(
                'opacity-90',
                formattedSalePrice < formattedPrice &&
                  'font-semibold text-red-400',
              )}
            >
              {priceFormatter(formattedSalePrice)}
            </span>
          </div>
        )}
      </div>
    </a>
  );
}
