import { Loader2 } from 'lucide-react';

import { CSVDownloadButton } from '@/components/CSVDownloadButton';
import { Button } from '@/components/ui/Button';

import { useGetCategoriesSeo } from '@/hooks/useGetCategoriesSeo';

export function DownloadButton() {
  const { categoriesSeo, isLoading } = useGetCategoriesSeo();

  const data =
    categoriesSeo?.map((category) => ({
      id: category.category_id,
      title: category.title,
      description: category.description,
      keywords: category.keywords,
    })) ?? [];

  const headers = [
    { label: 'id', key: 'id' },
    { label: 'title', key: 'title' },
    { label: 'description', key: 'description' },
    { label: 'keywords', key: 'keywords' },
  ];

  return (
    <div className="flex items-center justify-end">
      <CSVDownloadButton data={data} headers={headers} asChild>
        <Button disabled={isLoading}>
          {isLoading ? (
            <>
              <Loader2 className="animate-spin" /> Generating Category Data
            </>
          ) : (
            'Download Category Data'
          )}
        </Button>
      </CSVDownloadButton>
    </div>
  );
}
