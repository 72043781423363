import { Control, Controller } from 'react-hook-form';

import { Checkbox } from '@/components/ui/Checkbox';
import { Label } from '@/components/ui/Label';

import { IntegrationFormData } from '.';

interface FormCheckboxProps {
  name: keyof IntegrationFormData;
  label: string;
  disabled: boolean;
  control: Control<IntegrationFormData>;
}

export function FormCheckbox({
  name,
  label,
  disabled,
  control,
}: FormCheckboxProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <div className="flex items-center space-x-2">
          <Checkbox
            id={name}
            value={String(value)}
            defaultChecked={value}
            onCheckedChange={onChange}
            disabled={disabled}
          />
          <Label htmlFor={name}>{label}</Label>
        </div>
      )}
    />
  );
}
