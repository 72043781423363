import { useMutation, useQueryClient } from '@tanstack/react-query';

import AudiencesService, {
  AudienceSetupDto,
} from '@/services/AudiencesService';

import { GET_AUDIENCES_QUERY_KEY } from './useGetAudiences';

export function useAudienceSetup() {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (audienceSetupDto: AudienceSetupDto) =>
      AudiencesService.audienceSetup(audienceSetupDto),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: GET_AUDIENCES_QUERY_KEY,
      });
    },
  });

  return {
    audienceSetup: mutateAsync,
    isLoading: isPending,
  };
}
