import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalize(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function capitalizeWords(str: string) {
  return str
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

export function waitForElement<TElement extends Element = Element>(
  selector: string,
  callback: (element: NodeListOf<TElement>) => void,
) {
  let tries = 0;

  const intervalId = setInterval(() => {
    const element = document.querySelectorAll(selector) as NodeListOf<TElement>;

    if (element.length > 0) {
      clearInterval(intervalId);
      callback(element);
    } else {
      tries++;
      if (tries > 50) {
        clearInterval(intervalId);
        console.log(
          `waitForElement :: element "${selector}" not found [this is not an error]`,
        );
      }
    }
  }, 500);
}

export function priceFormatter(price: number) {
  const formattedNumber = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price);

  return formattedNumber;
}

export function formatSubmissionDate(submissionDate: Date | null | undefined) {
  if (!submissionDate) {
    return 'N/A';
  }

  const dateObject = new Date(submissionDate.toString().replace(' ', 'T'));
  const formattedDate = dateObject.toLocaleDateString('pt-BR');
  const formattedTime = dateObject.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return `${formattedDate} ${formattedTime}`;
}

export function convertToFileList(files: File[]): FileList {
  const dataTransfer = new DataTransfer();
  files.forEach((file) => dataTransfer.items.add(file));
  return dataTransfer.files;
}

export async function convertFilesToBase64(files: FileList): Promise<string[]> {
  return Promise.all(
    Array.from(files).map((file) => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
      });
    }),
  );
}
