import { Slot } from '@radix-ui/react-slot';
import { ReactNode, useEffect, useState } from 'react';
import { CSVDownload } from 'react-csv';

interface CSVDownloadButtonProps {
  data: object[];
  headers: {
    label: string;
    key: string;
  }[];
  filename?: string;
  className?: string;
  asChild?: boolean;
  children: ReactNode;
  onDownload?: () => Promise<void>;
}

export function CSVDownloadButton({
  data,
  headers,
  filename,
  className,
  asChild,
  children,
  onDownload = async () => {},
}: CSVDownloadButtonProps) {
  const [download, setDownload] = useState(false);

  async function handleDownload() {
    try {
      await onDownload();
    } catch (error) {
      console.error(`CSV Download Error: ${error}`);
    } finally {
      setDownload(true);
    }
  }

  useEffect(() => {
    setDownload(false);
  }, [download]);

  const Button = asChild ? Slot : 'button';

  return (
    <>
      <Button className={className} onClick={handleDownload}>
        {children}
      </Button>

      {download && (
        <CSVDownload
          data={data}
          headers={headers}
          filename={filename}
          target="_self"
        />
      )}
    </>
  );
}
