import { Star } from 'lucide-react';
import { Link } from 'react-router-dom';

import { cn, priceFormatter } from '@/lib/utils';

import { ROUTE_PATHS } from '@/config/constants';

interface ProductCardProps {
  id: number;
  image1: string;
  image2?: string;
  name: string;
  score?: number;
  price?: string;
  salePrice?: string;
}

export function ProductCard({
  id,
  image1,
  image2,
  name,
  score,
  price = '0',
  salePrice = '0',
}: ProductCardProps) {
  const formattedPrice = parseFloat(price);
  const formattedSalePrice = parseFloat(salePrice);

  return (
    <Link
      to={`${ROUTE_PATHS.VISION_ENRICHMENT}/${id}`}
      className="flex flex-col rounded-lg border border-solid border-[#E0E0E0]"
    >
      <div className="relative">
        <img src={image1} alt={name} className="rounded-t-lg" />

        {image2 && (
          <img
            src={image2}
            alt={name}
            className="absolute left-0 top-0 h-full w-full rounded-t-lg opacity-0 transition-opacity duration-500 hover:opacity-100"
          />
        )}

        {score && (
          <div className="absolute right-2 top-2 flex items-center gap-1 rounded-md bg-background/80 px-2 py-1 text-foreground backdrop-blur-sm">
            <Star className="h-4 w-4 fill-primary" />
            {(score * 100).toFixed(0)}%
          </div>
        )}
      </div>

      <div className="h-20 flex-col p-5 text-xs 2xl:text-sm">
        <h3 className="block truncate font-light">{name}</h3>

        {formattedPrice > 0 && formattedSalePrice > 0 && (
          <div className="flex gap-2 font-light">
            {formattedSalePrice < formattedPrice && (
              <span className="line-through opacity-50">
                {priceFormatter(formattedPrice)}
              </span>
            )}

            <span
              className={cn(
                'opacity-90',
                formattedSalePrice < formattedPrice &&
                  'font-semibold text-red-400',
              )}
            >
              {priceFormatter(formattedSalePrice)}
            </span>
          </div>
        )}
      </div>
    </Link>
  );
}
