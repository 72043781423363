import { useQuery } from '@tanstack/react-query';

import TaxonomiesService from '@/services/TaxonomiesService';

export const GET_TAXONOMIES_QUERY_KEY = ['taxonomies'];

export function useGetTaxonomies() {
  const { data, isFetching } = useQuery({
    queryKey: GET_TAXONOMIES_QUERY_KEY,
    queryFn: () => TaxonomiesService.getTaxonomies(),
  });

  const formattedData = data ?? [];

  return {
    taxonomies: formattedData,
    isLoading: isFetching,
  };
}
