import { useQuery } from '@tanstack/react-query';

import MetaService from '@/services/MetaService';

export const GET_META_AD_ACCOUNTS_QUERY_QUERY = ['meta-ad-accounts'];

export function useGetMetaAddAccounts() {
  const { data, isFetching } = useQuery({
    queryKey: GET_META_AD_ACCOUNTS_QUERY_QUERY,
    queryFn: () => MetaService.metaAdAccounts(),
  });

  return {
    metaAdAccounts: data ?? [],
    isLoading: isFetching,
  };
}
