import { useQuery } from '@tanstack/react-query';

import CustomerService from '@/services/CustomerService';

export const GET_CUSTOMERS_INFO_KEY = ['customers-info'];

export function useGetCustomersInfo() {
  const { data, isFetching } = useQuery({
    queryKey: GET_CUSTOMERS_INFO_KEY,
    queryFn: () => CustomerService.getCustomersInfo(),
  });

  return {
    customersInfo: data ?? [],
    isLoading: isFetching,
  };
}
