import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/Card';
import { useGetSeo } from '@/hooks/useGetSeo';

import { IntegrationForm } from './components/IntegrationForm';
import { ProductsTable } from './components/ProductsTable';
import { CategoriesTable } from './components/CategoriesTable';

export function SeoContent() {
  const { seo } = useGetSeo();

  return (
    <div className="py-8">
      <section className="mb-10">
        <h1 className="text-[42px] tracking-[8.25px]">seo content</h1>
      </section>

      {seo && (
        <div className="space-y-12">
          <Card>
            <CardHeader>
              <CardTitle>E-commerce Integration</CardTitle>
              <CardDescription>
                Enable integration with your e-commerce platform and select
                which data to sync
              </CardDescription>
            </CardHeader>

            <CardContent>
              <IntegrationForm />
            </CardContent>
          </Card>

          <div className="space-y-8">
            <CategoriesTable />
            <ProductsTable />
          </div>
        </div>
      )}
    </div>
  );
}
