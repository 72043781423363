import { CheckCircle } from 'lucide-react';

import { Button } from '@/components/ui/Button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/Card';

interface SuccessProps {
  onSuccess: (value: boolean) => void;
}

export function Success({ onSuccess }: SuccessProps) {
  return (
    <div className="container mx-auto py-10">
      <Card className="mx-auto w-full max-w-md">
        <CardHeader>
          <div className="mb-4 flex items-center justify-center">
            <CheckCircle className="h-16 w-16 text-green-500" />
          </div>

          <CardTitle className="text-center">
            Customer Created Successfully!
          </CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col items-center">
          <p className="mb-6 text-center">
            The new customer has been added to your system.
          </p>

          <Button onClick={() => onSuccess(false)}>
            Create Another Customer
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
