import { useMutation, useQueryClient } from '@tanstack/react-query';

import FeedbacksService, {
  CreateFeedbackDto,
  FeedbackResponse,
} from '@/services/FeedbacksService';

import { GET_FEEDBACK_BY_PRODUCT_ID_QUERY_KEY } from './useGetFeedbackByProductId';

export function useCreateFeedback(
  productId: number,
  currentAttributeId: string,
) {
  const queryClient = useQueryClient();

  const getFeedbackByProductQueryKey =
    GET_FEEDBACK_BY_PRODUCT_ID_QUERY_KEY(productId);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (createFeedbackDto: CreateFeedbackDto) =>
      FeedbacksService.createFeedback(createFeedbackDto),
    onMutate: (variables) => {
      const feedbackData = queryClient.getQueryData<FeedbackResponse[]>(
        getFeedbackByProductQueryKey,
      );

      const previousFeedbackData = feedbackData?.find(
        (item) => item.attributeId === currentAttributeId,
      );

      const feedbackId = previousFeedbackData
        ? previousFeedbackData.id
        : Math.random();

      const { attributeId, comments, downVote, upVote } = variables;

      queryClient.setQueryData<FeedbackResponse[]>(
        getFeedbackByProductQueryKey,
        (state) => {
          const currentDate = new Date().toISOString();

          const newFeedback = {
            id: feedbackId,
            attributeId,
            comments,
            downVoteDate: downVote ? currentDate : null,
            upVoteDate: upVote ? currentDate : null,
          };

          if (previousFeedbackData) {
            return state?.map((item) => {
              if (item.id === previousFeedbackData.id) {
                return newFeedback;
              }

              return item;
            });
          }

          return state?.concat(newFeedback);
        },
      );

      return { feedbackId, previousFeedbackData };
    },
    onSuccess: async (data, _variables, context) => {
      await queryClient.cancelQueries({
        queryKey: getFeedbackByProductQueryKey,
      });

      queryClient.setQueryData<FeedbackResponse[]>(
        getFeedbackByProductQueryKey,
        (state) =>
          state?.map((item) => {
            return item.id === context.feedbackId ? data : item;
          }),
      );
    },
    onError: async (_error, _variables, context) => {
      await queryClient.cancelQueries({
        queryKey: getFeedbackByProductQueryKey,
      });

      queryClient.setQueryData<FeedbackResponse[]>(
        getFeedbackByProductQueryKey,
        (state) => {
          if (context?.previousFeedbackData) {
            return state?.map((item) => {
              if (item.id === context.previousFeedbackData?.id) {
                return context.previousFeedbackData;
              }

              return item;
            });
          }

          return state?.filter((item) => item.id !== context?.feedbackId);
        },
      );
    },
  });

  return {
    createFeedback: mutateAsync,
    isLoading: isPending,
  };
}
