import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import ProductsService, {
  UpdateVisionAttributeDto,
  VisionProductResponse,
} from '@/services/ProductsService';

import { GET_VISION_PRODUCT_QUERY_KEY } from './useGetVisionProduct';

export function useUpdateVisionAttribute(
  productId: number,
  externalProductId: string,
) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (updateVisionAttributeDto: UpdateVisionAttributeDto) =>
      ProductsService.updateVisionAttribute(
        externalProductId,
        updateVisionAttributeDto,
      ),
    onSuccess: (data) => {
      queryClient.setQueryData<VisionProductResponse>(
        GET_VISION_PRODUCT_QUERY_KEY(productId),
        produce((draft) => {
          const attributes = draft?.productAttributes;

          for (const key in attributes) {
            const found = attributes[key].find(
              (attribute) => attribute.id === data.id,
            );

            if (found) {
              found.name = data.name;
            }
          }
        }),
      );
    },
  });

  return {
    updateVisionAttribute: mutateAsync,
    isLoading: isPending,
  };
}
