import { useAuth, User } from '@/contexts/AuthContext';

interface WithUserLoadingProps {
  user: User | undefined;
}

export function withUser<T>(
  Component: React.ComponentType<T & WithUserLoadingProps>,
) {
  return function ComponentWithUser(props: T) {
    const { user, isUserLoading } = useAuth();

    if (isUserLoading) {
      return null;
    }

    return (
      <Component
        {...({ ...props } as JSX.IntrinsicAttributes & T)}
        user={user}
      />
    );
  };
}
