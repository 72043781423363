import { Facebook, LoaderCircle } from 'lucide-react';

import { Button } from '@/components/ui/Button';
import { useMetaOAuth } from '@/hooks/useMetaOAuth';

export function MetaOAuth() {
  const { isLoading, metaOAuth } = useMetaOAuth();

  async function handleMetaOAuth() {
    const { data } = await metaOAuth();

    if (data?.url) {
      window.open(data.url, '_blank');
    }
  }

  return (
    <div className="space-y-4">
      <div className="rounded-lg bg-gray-100 p-4">
        <h3 className="mb-2 text-lg font-semibold">Why integrate with Meta?</h3>
        <ul className="list-inside list-disc space-y-2">
          <li>Seamlessly manage your Facebook and Instagram ad campaigns</li>
          <li>Access powerful audience targeting tools</li>
          <li>Track performance metrics across Meta platforms</li>
          <li>Automate posting and engagement on your social media accounts</li>
          <li>
            Utilize Meta&apos;s AI-powered insights for better marketing
            decisions
          </li>
        </ul>
      </div>

      <div className="space-y-4">
        <p className="text-sm text-muted-foreground">
          Click the button below to securely connect your account to Meta.
          You&apos;ll be redirected to Meta to complete the authentication
          process.
        </p>

        <Button
          onClick={handleMetaOAuth}
          className="w-full bg-[#1877F2] text-white transition-colors hover:bg-[#0C63D4]"
        >
          {isLoading && <LoaderCircle className="animate-spin" />}

          {!isLoading && (
            <>
              <Facebook className="mr-2 h-4 w-4" />
              Connect with Meta
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
