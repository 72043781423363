import { httpClient } from '@/lib/httpClient';

export enum EAudiencePlatform {
  META = 'META',
}

export interface Audience {
  id: number;
  audience_size: string;
  config_audience_score: boolean;
  config_productset_score: boolean;
  input_type: number;
  input_value: string;
  name: string;
  products_in_set: string;
  products_in_set_active: string;
  audiencePlatform: {
    id: number;
    isSynchronized: boolean;
    platform: EAudiencePlatform;
    platformAudienceId: string;
  }[];
}

export interface AudienceResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: Audience[];
}

export interface AudienceCalculateDto {
  name: string;
  input_type: number;
  input_value: string;
  meta_integration: boolean;
  config_audience_score: boolean;
  config_productset_score: boolean;
  audience_size: number;
  products_in_set: number;
}

export interface AudienceCalculateResponse {
  audienceId: number;
  engine: {
    audience_size: { from: number; to: number; suggestion: number };
    product_size: { from: number; to: number; suggestion: number };
  };
  products: {
    id: number;
    productId: string;
    name: string;
    image1: string;
    price: string;
    salePrice: string;
    score: number;
    active: boolean;
    filters: {
      calculatedStockBalance: number;
      isBrokenStock: boolean;
      discountValue: number;
      discountText: string;
      hasDiscount: boolean;
    };
  }[];
}

export interface FilterCalculatedProductsDto {
  calculatedProductIds: string[];
  filters: Record<string, any>;
}

export interface FilterCalculatedProductsResponse {
  filteredProducts: {
    productId: string;
    active: boolean;
  }[];
}

export interface UpdateAudienceDto {
  platform: EAudiencePlatform;
}

export interface UpdateAudienceResponse {
  id: number;
  isSynchronized: boolean;
  platform: EAudiencePlatform;
  platformAudienceId: string;
  createdAt: string;
  synchronizedAt: string;
  updatedAt: string;
}

export interface AudienceSetupDto {
  id: number;
  name: string;
  input_type: number;
  input_value: string;
  config_audience_score: boolean;
  config_productset_score: boolean;
  audience_size: number;
  products_in_set: number;
  audience_products: {
    productId: string;
    active: boolean;
  }[];
  filters: Record<string, any>;
}

export interface AudiencesUploadUrlResponse {
  filename: string;
  url: string;
}

export interface AudienceUsersResponse {
  audienceId: number;
  crmId: number;
  crm: {
    userProfileId: string;
    phone: string;
  };
}

export interface AudienceProductsResponse {
  audienceId: number;
  productId: number;
  product: {
    productId: string;
  };
}

class AudiencesService {
  constructor() {}

  async getAudiences(page: number, limit: number) {
    const { data } = await httpClient.get<AudienceResponse>('/v1/audiences', {
      params: {
        page,
        limit,
      },
    });

    return data;
  }

  async audienceCalculate(audienceCalculateDto: AudienceCalculateDto) {
    const { data } = await httpClient.post<AudienceCalculateResponse>(
      '/v1/audiences/calculate',
      audienceCalculateDto,
    );

    return data;
  }

  async filterCalculatedProducts(
    filterCalculatedProductsDto: FilterCalculatedProductsDto,
  ) {
    const { data } = await httpClient.post<FilterCalculatedProductsResponse>(
      '/v1/audiences/filter-calculated-products',
      filterCalculatedProductsDto,
    );

    return data;
  }

  async updateAudience(id: number, updateAudienceDto: UpdateAudienceDto) {
    const { data } = await httpClient.patch<UpdateAudienceResponse>(
      `/v1/audiences/${id}`,
      updateAudienceDto,
    );

    return data;
  }

  async audienceSetup(audienceSetupDto: AudienceSetupDto) {
    const { data } = await httpClient.put(
      '/v1/audiences/setup',
      audienceSetupDto,
    );

    return data;
  }

  async audiencesUploadUrl() {
    const { data } = await httpClient.get<AudiencesUploadUrlResponse>(
      '/v1/audiences/upload-url',
    );

    return data;
  }

  async getAudienceUsers(id: number) {
    const { data } = await httpClient.get<AudienceUsersResponse[]>(
      `/v1/audiences/users/${id}`,
    );

    return data;
  }

  async getAudienceProducts(id: number) {
    const { data } = await httpClient.get<AudienceProductsResponse[]>(
      `/v1/audiences/products/${id}`,
    );

    return data;
  }
}

export default new AudiencesService();
