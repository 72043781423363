import { LoaderCircle } from 'lucide-react';
import { useEffect, useRef } from 'react';

import { ProductCard } from '@/components/ProductCard';
import { useProductsRecommendation } from '@/hooks/useProductsRecommendation';

import { useStylistAiContext } from '../../contexts/StylistAiContext';

export function ProductList() {
  const endProductsRef = useRef<HTMLDivElement>(null);

  const {
    queryText,
    selectedCategories,
    selectedAttributes,
    setHasProductsCallback,
    setIsLoadingCallback,
  } = useStylistAiContext();

  const {
    products,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useProductsRecommendation(queryText);

  const isDisabledInfiniteScroll =
    products.items.length === 0 ||
    selectedAttributes.length > 0 ||
    selectedCategories.length > 0;

  const filteredProductsByCategory =
    selectedCategories.length > 0
      ? products.items.filter((product) =>
          selectedCategories.includes(product.category),
        )
      : products.items;

  const filteredProductsByAttributes =
    selectedAttributes.length > 0
      ? filteredProductsByCategory.filter((product) =>
          Object.values(product?.product_attributes ?? {}).some(
            (attributeArray: any) =>
              attributeArray.some((attribute: any) =>
                selectedAttributes.some((selectedAttribute) => {
                  return (
                    selectedAttribute.name === attribute.name &&
                    selectedAttribute.property === attribute.property
                  );
                }),
              ),
          ),
        )
      : filteredProductsByCategory;

  const filteredProducts = filteredProductsByAttributes;

  useEffect(() => {
    setIsLoadingCallback(isFetching);
  }, [isFetching, setIsLoadingCallback]);

  useEffect(() => {
    setHasProductsCallback(products.items.length > 0);
  }, [products, setHasProductsCallback]);

  useEffect(() => {
    if (isDisabledInfiniteScroll || !endProductsRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, obs) => {
        const { isIntersecting } = entries[0];

        if (!hasNextPage) {
          obs.disconnect();
          return;
        }

        if (isIntersecting && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        rootMargin: '30px',
      },
    );

    observer.observe(endProductsRef.current);

    return () => {
      observer.disconnect();
    };
  }, [
    isDisabledInfiniteScroll,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  ]);

  return (
    <div>
      <ul className="flex w-full flex-wrap justify-start gap-3">
        {filteredProducts.map((product) => (
          <li
            key={product?.product_id}
            className="w-full sm:w-[calc(50%-12px)] 2xl:w-[calc(25%-12px)]"
          >
            <ProductCard
              id={product?.product_id}
              image1={product?.images[0]}
              image2={product?.images[1]}
              name={product?.product_name}
              score={1}
            />
          </li>
        ))}
      </ul>

      <div ref={endProductsRef}>
        {isFetchingNextPage && (
          <LoaderCircle className="mx-auto mt-10 animate-spin" />
        )}
      </div>
    </div>
  );
}
