export const ROUTE_PATHS = {
  HOME: '/',
  COPILOT: '/copilot',
  SETTINGS: '/settings',
  VISION_ENRICHMENT: '/vision-enrichment',
  TAXONOMY: '/my-style-ai',
  AUDIENCES: '/performance-hub',
  AUDIENCES_CREATE: '/performance-hub/create-new-strategy',
  SEO_CONTENT: '/seo-content',
  CRM_BEHAVIORS: '/crm-behaviors',
  ANALYTICS: '/analytics',
  STYLIST_AI: '/stylist-ai',
  AUTH_CALLBACK: '/auth/callback',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_CUSTOMERS_CREATE: '/admin/customers/create',
  ADMIN_USERS_CREATE: '/admin/users/create',
  LOGIN: '/login',
  NOT_FOUND: '*',
} as const;

export const ERROR_MESSAGES = {
  ACCESS_DENIED: 'You do not have permission to access this resource.',
  INVALID_CREDENTIALS:
    'The email or password you entered is incorrect. Please try again.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again to continue.',
  INTERNAL_SERVER_ERROR:
    'A server error occurred. Please try again later or contact support.',
  INVALID_DATA:
    'The request could not be processed. Please check your input and try again.',
} as const;
