import { useGetTaxonomiesByCategory } from '@/hooks/useGetTaxonomiesByCategory';

import { useTaxonomyContext } from '../../contexts/TaxonomyContext';

import { DataItem } from './DataItem';

export function DataList() {
  const { selectedCategory } = useTaxonomyContext();

  const { taxonomy } = useGetTaxonomiesByCategory(selectedCategory);

  const sortedTaxonomy = [...taxonomy].sort((a, b) =>
    a.property.localeCompare(b.property, navigator.language || 'en-US', {
      sensitivity: 'base',
    }),
  );

  return (
    <dl>
      {sortedTaxonomy.map((taxonomyItem) => {
        if (taxonomyItem.type === 'sales_support') {
          return null;
        }

        return <DataItem key={taxonomyItem.id} taxonomyItem={taxonomyItem} />;
      })}
    </dl>
  );
}
