import { Download } from 'lucide-react';

import { CSVDownloadButton } from '@/components/CSVDownloadButton';
import { useGetAudienceUsers } from '@/hooks/useGetAudienceUsers';
import { Audience } from '@/services/AudiencesService';

interface AudienceSizeProps {
  audience: Pick<Audience, 'id' | 'audience_size'>;
}

export function AudienceSize({ audience }: AudienceSizeProps) {
  const { audienceUsers, getAudienceUsers } = useGetAudienceUsers(audience.id);

  const data =
    audienceUsers?.map((audienceUser) => ({
      user_id: audienceUser.crm.userProfileId,
      phone: audienceUser.crm.phone,
    })) ?? [];

  const headers = [
    { label: 'user_id', key: 'user_id' },
    { label: 'phone', key: 'phone' },
  ];

  async function handleDownload() {
    await getAudienceUsers();
  }

  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="text-2xl font-bold">
          {Number(audience.audience_size)}
        </div>

        <CSVDownloadButton
          data={data}
          headers={headers}
          onDownload={handleDownload}
        >
          <Download className="h-4 w-4" />
        </CSVDownloadButton>
      </div>

      <p className="text-xs text-muted-foreground">audience size</p>
    </div>
  );
}
