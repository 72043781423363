import { ChevronLeft } from 'lucide-react';
import { Link, Navigate, useParams } from 'react-router-dom';

import { ROUTE_PATHS } from '@/config/constants';
import { useGetVisionProduct } from '@/hooks/useGetVisionProduct';

import { Attributes } from './components/Attributes';
import { ProductCard } from './components/ProductCard';

export function VisionProduct() {
  const { productId } = useParams();

  const formattedProductId = productId ? +productId : 0;

  const { product, isLoading } = useGetVisionProduct(formattedProductId);

  if (isLoading) {
    return null;
  }

  if (!product && !isLoading) {
    return <Navigate to={ROUTE_PATHS.VISION_ENRICHMENT} replace />;
  }

  return (
    <div className="py-8">
      <Link to={ROUTE_PATHS.VISION_ENRICHMENT} className="mb-2 flex gap-1">
        <ChevronLeft />
        <span className="underline">Voltar</span>
      </Link>

      <article className="flex gap-[58px]">
        <div className="w-[320px]">
          <ProductCard
            name={product?.name}
            images={product?.images}
            link={product?.link}
            price={product?.price}
            salePrice={product?.salePrice}
          />
        </div>

        <section className="w-[730px]">
          <h1 className="text-3xl">{product?.name}</h1>
          <span className="text-sm">{product?.visionCategory}</span>

          {product?.productAttributes && (
            <div className="mt-10">
              <Attributes
                productId={formattedProductId}
                externalProductId={product.productId}
                visionCategory={product?.visionCategory}
                attributes={product?.productAttributes}
              />
            </div>
          )}
        </section>
      </article>
    </div>
  );
}
