import { useMutation, useQueryClient } from '@tanstack/react-query';

import CustomerService, {
  UpdateVtexCredentialsDto,
} from '@/services/CustomerService';
import { GET_ME_QUERY_KEY } from './useGetMe';
import { MeResponse } from '@/services/AuthService';
import { produce } from 'immer';

export function useUpdateVtexCredentials() {
  const queryClient = useQueryClient();

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (updateVtexCredentialsDto: UpdateVtexCredentialsDto) =>
      CustomerService.updateVtexCredentials(updateVtexCredentialsDto),
    onMutate: (updateVtexCredentialsDto) => {
      const previousData =
        queryClient.getQueryData<MeResponse>(GET_ME_QUERY_KEY);

      queryClient.setQueryData<MeResponse>(
        GET_ME_QUERY_KEY,
        produce((draft) => {
          if (draft?.customer) {
            draft.customer.vtexAppKey = updateVtexCredentialsDto.vtexAppKey;
            draft.customer.vtexAppToken = updateVtexCredentialsDto.vtexAppToken;
            draft.customer.vtexUrl = updateVtexCredentialsDto.vtexUrl;
            draft.customer.vtexSalesChannel =
              updateVtexCredentialsDto.vtexSalesChannel;
            draft.customer.vtexAffiliateId =
              updateVtexCredentialsDto.vtexAffiliateId;
          }
        }),
      );

      return { previousData };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueryData<MeResponse>(
        GET_ME_QUERY_KEY,
        context?.previousData,
      );
    },
  });

  return {
    updateVtexCredentials: mutateAsync,
    isLoading: isPending,
  };
}
