import { useEffect, useMemo } from 'react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select';
import { useGetTaxonomies } from '@/hooks/useGetTaxonomies';

import { useTaxonomyContext } from '../contexts/TaxonomyContext';

export function TaxonomySelect() {
  const { taxonomies } = useGetTaxonomies();

  const { setSelectedCategory } = useTaxonomyContext();

  const sortedTaxonomies = useMemo(() => {
    return [...taxonomies].sort((a, b) =>
      a.localeCompare(b, navigator.language || 'en-US', {
        sensitivity: 'base',
      }),
    );
  }, [taxonomies]);

  useEffect(() => {
    setSelectedCategory(sortedTaxonomies[0]);
  }, [setSelectedCategory, sortedTaxonomies]);

  return (
    <section className="mb-8">
      <Select onValueChange={setSelectedCategory}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder={sortedTaxonomies[0]} />
        </SelectTrigger>
        <SelectContent>
          {sortedTaxonomies.map((taxonomy) => (
            <SelectItem key={taxonomy} value={taxonomy}>
              {taxonomy}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </section>
  );
}
