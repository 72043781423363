import { zodResolver } from '@hookform/resolvers/zod';
import { ComponentRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormGroup } from '@/components/FormGroup';
import { Button } from '@/components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/Dialog';
import { Textarea } from '@/components/ui/Textarea';

const feedbackFormSchema = z.object({
  message: z.string().min(1, 'Feedback is required'),
});

type FeedbackFormData = z.infer<typeof feedbackFormSchema>;

interface FeedbackModalProps {
  isOpen: boolean;
  attributeProperty: string;
  isLoading: boolean;
  onOpenChange: () => void;
  onClose: () => void;
  onFeedback: (vote: 'up' | 'down', comments: string | undefined) => void;
}

export function FeedbackModal({
  isOpen,
  attributeProperty,
  isLoading,
  onOpenChange,
  onClose,
  onFeedback,
}: FeedbackModalProps) {
  const submitButtonRef = useRef<ComponentRef<'button'>>(null);

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<FeedbackFormData>({
    resolver: zodResolver(feedbackFormSchema),
    mode: 'onSubmit',
  });

  const onSubmit = handleSubmit((data: FeedbackFormData) => {
    onClose();
    reset();
    onFeedback('down', data.message);
  });

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{attributeProperty}</DialogTitle>

          <DialogDescription className="sr-only" />
        </DialogHeader>

        <div className="space-y-2">
          <p className="text-sm">
            Por favor, deixe seu feedback para nos ajudar a melhorar!
          </p>

          <form onSubmit={onSubmit}>
            <FormGroup
              error={{
                message: errors.message?.message,
                font: 'sm',
                align: 'center',
              }}
            >
              <Textarea className="h-[120px]" {...register('message')} />
            </FormGroup>
            <button
              ref={submitButtonRef}
              type="submit"
              className="hidden"
              disabled={isLoading}
            >
              Submit
            </button>
          </form>
        </div>

        <DialogFooter>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button onClick={() => submitButtonRef.current?.click()}>
            Salvar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
