import { useAuth } from '@/contexts/AuthContext';

import { AffiliateURL } from './AffiliateURL';

export function Affiliate() {
  const { user } = useAuth();

  return (
    <div className="space-y-4 rounded-lg bg-gray-100 p-4">
      <h3 className="mb-2 text-lg font-semibold">
        How to set up the affiliate system for catalog consumption
        <span className="ml-1 inline-block text-sm font-light italic text-muted-foreground">
          (not necessary for Freemium accounts)
        </span>
      </h3>

      <div className="space-y-2">
        <h4 className="mb-2 font-semibold">Create an affiliate</h4>

        <ol className="list-inside list-decimal space-y-2 pl-4">
          <li>
            On the admin side menu, click <strong>Order Management.</strong>
          </li>
          <li>
            Click <strong>Settings.</strong>
          </li>
          <li>
            Click the <strong>Affiliates</strong> tab.
          </li>
          <li>
            Click the <strong>New Affiliate</strong> button.
          </li>
          <li>
            Click <strong>Save.</strong>
          </li>
        </ol>
      </div>

      <div className="space-y-2">
        <h4 className="mb-2 font-semibold">
          Fill in the New Affiliate panel fields
        </h4>

        <ol className="list-inside list-decimal space-y-2 pl-4">
          <li>
            <strong>Name:</strong> FashionAI
          </li>

          <li>
            <strong>ID:</strong> {user?.customer.vtexAffiliateId}
          </li>

          <li>
            <strong>Trade Policy:</strong> {user?.customer.vtexSalesChannel}
          </li>

          <li>
            <strong>Follow Up E-mail:</strong> vtex@generativecrm.com
          </li>

          <li>
            <AffiliateURL />
          </li>

          <li>
            <strong>Search Endpoint Version:</strong> 1.x.x
          </li>

          <li>
            <strong>Use my payment method:</strong> Do not check the flag.
          </li>
        </ol>
      </div>

      <p className="mt-4 text-sm text-muted-foreground">
        Note: If any information from the affiliate panel fields is different,
        please fill in the form below.
      </p>
    </div>
  );
}
