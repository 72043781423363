import { httpClient } from '@/lib/httpClient';

export interface Seo {
  id: number;
  sync: boolean;
  productName: boolean;
  productDescription: boolean;
  altDescriptionProduct: boolean;
  productTags: boolean;
  altDescriptionImage1: boolean;
  altDescriptionImage2: boolean;
  altDescriptionImage3: boolean;
  altDescriptionImage4: boolean;
  altDescriptionImage5: boolean;
  altDescriptionImage6: boolean;
  altDescriptionImage7: boolean;
  altDescriptionImage8: boolean;
  altDescriptionImage9: boolean;
  altDescriptionImage10: boolean;
}

export interface SeoResponse extends Seo {}

export interface UpdateSeoDto extends Omit<Seo, 'id'> {}

class SeoService {
  constructor() {}

  async getSeoConfig() {
    const { data } = await httpClient.get<SeoResponse>('/v1/seo');

    return data;
  }

  async updateSeoConfig(updateSeoConfigDto: UpdateSeoDto) {
    const { data } = await httpClient.put<SeoResponse>(
      '/v1/seo',
      updateSeoConfigDto,
    );

    return data;
  }
}

export default new SeoService();
