import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { FormGroup } from '@/components/FormGroup';
import { Button } from '@/components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/Dialog';
import { Textarea } from '@/components/ui/Textarea';
import { Toast } from '@/components/ui/Toast';
import { useUpdateTaxonomy } from '@/hooks/useUpdateTaxonomy';
import { capitalize } from '@/lib/utils';
import { TaxonomyResponse } from '@/services/TaxonomiesService';

interface DataItemProps {
  taxonomyItem: TaxonomyResponse;
}

const taxonomyFormSchema = z.object({
  examples: z.string().min(1, 'Examples is required'),
});

type TaxonomyFormData = z.infer<typeof taxonomyFormSchema>;

export function DataItem({ taxonomyItem }: DataItemProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { updateTaxonomy } = useUpdateTaxonomy(
    taxonomyItem.id,
    taxonomyItem.categoryName,
  );

  const taxonomyProperty = capitalize(
    taxonomyItem.property.replaceAll('_', ' '),
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<TaxonomyFormData>({
    resolver: zodResolver(taxonomyFormSchema),
    defaultValues: {
      examples: taxonomyItem.examples,
    },
    mode: 'onSubmit',
  });

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  async function onSubmit({ examples }: TaxonomyFormData) {
    setIsModalOpen(false);

    try {
      await updateTaxonomy({ examples });
    } catch {
      toast.custom((id) => (
        <Toast
          id={id}
          title="Ocorreu um erro!"
          description="Não foi possível salvar a taxonomia."
          variant="error"
        />
      ));
    }
  }

  return (
    <div className="flex items-start gap-2 border-b border-solid border-[#C3C3C3] px-5 py-3">
      <dt className="w-[200px] text-sm font-bold">{taxonomyProperty}</dt>
      <dd className="flex-1 text-sm">{taxonomyItem.examples}</dd>

      <Button
        variant="ghost"
        className="p-0 hover:bg-transparent"
        onClick={handleOpenModal}
      >
        <Pencil />
      </Button>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent onInteractOutside={handleCloseModal}>
          <DialogHeader>
            <DialogTitle>{taxonomyProperty}</DialogTitle>

            <DialogDescription className="sr-only" />
          </DialogHeader>

          <div className="space-y-2">
            <p className="text-sm">
              Por favor, preencha o campo para atualizar a taxonomia.
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup
                error={{
                  message: errors.examples?.message,
                  font: 'sm',
                  align: 'center',
                }}
              >
                <Textarea className="h-[120px]" {...register('examples')} />
                <button id="submit-taxonomy-values" className="hidden">
                  Submit
                </button>
              </FormGroup>
            </form>
          </div>

          <DialogFooter>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button asChild>
              <label htmlFor="submit-taxonomy-values">Salvar</label>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
