import { Navigate, Outlet } from 'react-router-dom';

import { ROUTE_PATHS } from '@/config/constants';
import { useAuth } from '@/contexts/AuthContext';

interface AuthGuardProps {
  isPrivate: boolean;
}

export function AuthGuard({ isPrivate }: AuthGuardProps) {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn && !isPrivate) {
    return <Navigate to={ROUTE_PATHS.HOME} replace />;
  }

  if (!isLoggedIn && isPrivate) {
    return <Navigate to={ROUTE_PATHS.LOGIN} replace />;
  }

  return <Outlet />;
}
