import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useAuth } from '@/contexts/AuthContext';
import { META_OAUTH_QUERY_KEY } from '@/hooks/useMetaOAuth';

import { MetaAuthenticated } from './MetaAuthenticated';
import { MetaOAuth } from './MetaOAuth';
import { Tab } from '../Tab';

export function Meta() {
  const [isConnected, setIsConnected] = useState(false);

  const queryClient = useQueryClient();

  const { user } = useAuth();

  function handleDisconnect() {
    setIsConnected(false);

    queryClient.removeQueries({
      queryKey: META_OAUTH_QUERY_KEY,
    });
  }

  useEffect(() => {
    if (user?.customer.meta_user_token) {
      setIsConnected(true);
    }
  }, [user]);

  return (
    <Tab
      title="Meta Settings"
      description={
        isConnected
          ? 'Your account is connected to Meta'
          : 'Connect your account to Meta for social media integration'
      }
    >
      {isConnected ? (
        <MetaAuthenticated
          customerBusinessAccountId={user?.customer.meta_business_id}
          onDisconnect={handleDisconnect}
        />
      ) : (
        <MetaOAuth />
      )}
    </Tab>
  );
}
