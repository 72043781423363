import { Label } from '@/components/ui/Label';
import { Switch } from '@/components/ui/Switch';

interface IntegrationSwitchProps {
  isActive: boolean;
  onToggleConfirmModal: () => void;
}

export function IntegrationSwitch({
  isActive,
  onToggleConfirmModal,
}: IntegrationSwitchProps) {
  return (
    <div className="flex items-center space-x-2">
      <Switch checked={isActive} onCheckedChange={onToggleConfirmModal} />
      <Label>Enable SEO Integration</Label>
    </div>
  );
}
