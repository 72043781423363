import { useTaxonomyContext } from '../../contexts/TaxonomyContext';

import { DataList } from './DataList';

export function TaxonomyList() {
  const { selectedCategory } = useTaxonomyContext();

  if (!selectedCategory) {
    return null;
  }

  return (
    <section className="w-full max-w-[1280px]">
      <header className="flex h-[54px] items-center bg-[#F4F4F4]">
        <div className="flex items-start gap-2 px-5 py-3">
          <strong className="w-[200px] text-sm font-bold">Property</strong>
          <strong className="text-sm font-bold">Examples</strong>
        </div>
      </header>

      <DataList />
    </section>
  );
}
