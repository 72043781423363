import { useMutation } from '@tanstack/react-query';

import AudiencesService, {
  FilterCalculatedProductsDto,
} from '@/services/AudiencesService';

export function useFilterCalculatedProducts() {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (filterCalculatedProductsDto: FilterCalculatedProductsDto) =>
      AudiencesService.filterCalculatedProducts(filterCalculatedProductsDto),
  });

  return {
    filterCalculatedProducts: mutateAsync,
    isLoading: isPending,
  };
}
