import { Loader2, Search } from 'lucide-react';
import { useDeferredValue, useMemo, useState } from 'react';

import { Button } from '@/components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/Dialog';
import { Input } from '@/components/ui/Input';

import { CalculatedProducts } from '..';

import { ProductListCtas } from './ProductListCtas';
import { DialogDescription } from '@radix-ui/react-dialog';

interface ProductListModalProps {
  calculatedProducts: CalculatedProducts;
  isFiltering: boolean;
  onUndo: (productId: number) => void;
  onRemove: (productId: number) => void;
}

export function ProductListModal({
  calculatedProducts,
  isFiltering,
  onUndo,
  onRemove,
}: ProductListModalProps) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const deferredSearchTerm = useDeferredValue(searchTerm);

  const filteredProducts = useMemo(
    () =>
      calculatedProducts.filter(
        (product) =>
          product.name
            .toLowerCase()
            .includes(deferredSearchTerm.toLowerCase()) ||
          product.productId.includes(deferredSearchTerm),
      ),
    [deferredSearchTerm, calculatedProducts],
  );

  return (
    <div className="flex gap-2">
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button type="button" variant="default">
            View All
          </Button>
        </DialogTrigger>

        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>All Products</DialogTitle>
            <DialogDescription />
          </DialogHeader>

          <div className="relative mb-4">
            <Search className="absolute left-2 top-1/2 -translate-y-1/2 transform text-gray-400" />
            <Input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8"
            />
          </div>

          <div className="flex h-[600px] flex-col gap-4 overflow-scroll py-4 scrollbar-hide">
            {isFiltering && (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <Loader2 className="animate-spin" />
              </div>
            )}

            {!isFiltering &&
              filteredProducts.map((product) => (
                <div
                  key={product.id}
                  className="relative flex items-center gap-4 rounded-lg border p-4"
                >
                  {!product.active && (
                    <div className="absolute left-0 top-0 h-full w-full rounded-lg bg-black opacity-40" />
                  )}

                  <img
                    src={product.image1}
                    alt={product.name}
                    className="h-16 w-16 rounded-md object-cover"
                  />

                  <div className="max-w-[320px] flex-1">
                    <h3 className="truncate font-medium">{product.name}</h3>
                  </div>

                  <div className="absolute right-4 top-1/2 z-10 -translate-y-1/2">
                    <ProductListCtas
                      productId={product.id}
                      isActive={product.active}
                      onUndo={onUndo}
                      onRemove={onRemove}
                    />
                  </div>
                </div>
              ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
