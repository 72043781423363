import { useQuery } from '@tanstack/react-query';

import ProductsService from '@/services/ProductsService';

export const GET_VISION_PRODUCTS_QUERY_KEY = ['get-vision-products'];

export function useGetVisionProducts() {
  const { data, isFetching, refetch } = useQuery({
    queryKey: GET_VISION_PRODUCTS_QUERY_KEY,
    enabled: false,
    queryFn: () => ProductsService.getVisionProducts(),
  });

  return {
    visionProducts: data,
    isLoading: isFetching,
    getVisionProducts: refetch,
  };
}
