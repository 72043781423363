import * as Sentry from '@sentry/react';

import { env } from '@/config/env';

Sentry.init({
  enabled: env.PROD,
  dsn: 'https://a52da55af95434f363d6a9c01c5f9e5d@o4508841324052480.ingest.us.sentry.io/4508841325363200',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/catalog\.api\.fashionaiale\.com\/api/],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
