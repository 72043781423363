import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/Card';
import { ROUTE_PATHS } from '@/config/constants';

export function AdminDashboard() {
  const navigate = useNavigate();

  function handleCreateCustomerClick() {
    navigate(ROUTE_PATHS.ADMIN_CUSTOMERS_CREATE);
  }

  function handleCreateUserClick() {
    navigate(ROUTE_PATHS.ADMIN_USERS_CREATE);
  }

  return (
    <div>
      <h1 className="mb-8 text-4xl font-bold">Admin Dashboard</h1>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle>Customer Management</CardTitle>

            <CardDescription>
              Create and manage customer accounts
            </CardDescription>
          </CardHeader>

          <CardContent>
            <Button className="w-full" onClick={handleCreateCustomerClick}>
              Create New Customer
            </Button>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>User Management</CardTitle>

            <CardDescription>Create and manage user accounts</CardDescription>
          </CardHeader>

          <CardContent>
            <Button className="w-full" onClick={handleCreateUserClick}>
              Create New User
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
