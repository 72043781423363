import metaLogo from '@/assets/meta-logo.svg';
import { Switch } from '@/components/ui/Switch';
import { useUpdateAudience } from '@/hooks/useUpdateAudience';
import { Audience, EAudiencePlatform } from '@/services/AudiencesService';

interface MetaSwitchProps {
  audience: Pick<Audience, 'id' | 'audiencePlatform'>;
}

export function MetaSwitch({ audience }: MetaSwitchProps) {
  const { updateAudience, isLoading } = useUpdateAudience(audience.id);

  const metaAudience = audience.audiencePlatform.find(
    (audiencePlatform) => audiencePlatform.platform === EAudiencePlatform.META,
  );

  async function handleCheckedChange() {
    if (metaAudience?.isSynchronized) {
      return;
    }

    await updateAudience({ platform: EAudiencePlatform.META });
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex cursor-default items-center gap-2">
        <img
          src={metaLogo}
          alt="Meta Logo"
          className="h-20 w-20 object-contain"
        />
      </div>

      <Switch
        checked={metaAudience?.isSynchronized}
        disabled={isLoading}
        onCheckedChange={handleCheckedChange}
      />
    </div>
  );
}
