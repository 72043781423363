import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import { MeResponse } from '@/services/AuthService';
import CustomerService, { VisionTriggerDto } from '@/services/CustomerService';

import { GET_ME_QUERY_KEY } from './useGetMe';

export function useVisionTrigger() {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data: VisionTriggerDto) => CustomerService.visionTrigger(data),
    onMutate: (data) => {
      const previousData =
        queryClient.getQueryData<MeResponse>(GET_ME_QUERY_KEY);

      queryClient.setQueryData<MeResponse>(
        GET_ME_QUERY_KEY,
        produce((draft) => {
          if (draft?.customer) {
            draft.customer.needUpdateCategoryName = data.needUpdateCategoryName;
            draft.customer.visionAutoRun = data.visionAutoRun;
          }
        }),
      );

      return {
        needUpdateCategoryName: !!previousData?.customer.needUpdateCategoryName,
        visionAutoRun: !!previousData?.customer.visionAutoRun,
      };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueryData<MeResponse>(
        GET_ME_QUERY_KEY,
        produce((draft) => {
          if (draft?.customer) {
            draft.customer.needUpdateCategoryName =
              !!context?.needUpdateCategoryName;
            draft.customer.visionAutoRun = !!context?.visionAutoRun;
          }
        }),
      );
    },
  });

  return {
    updateNeedVisionRun: mutateAsync,
    isLoading: isPending,
  };
}
