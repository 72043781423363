import { httpClient } from '@/lib/httpClient';

export interface ProductsResponse {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: {
    id: number;
    productId: string;
    image1: string;
    image2: string;
    name: string;
    price: string;
    salePrice: string;
  }[];
}

export interface ImageAltTextName {
  description: string;
  url: string;
}

export interface VisionProductAttributes {
  [key: string]: {
    id: string;
    property: string;
    name: string;
  }[];
}

export interface VisionProductsResponse {
  name: string;
  productId: string;
  productAttributes: VisionProductAttributes;
}

export interface VisionProductResponse {
  id: number;
  name: string;
  productId: string;
  images: string[];
  visionCategory: string;
  link: string;
  price: string;
  salePrice: string;
  productAttributes: VisionProductAttributes;
}

export interface VisionAttributresParams {
  visionCategory: string;
  property: string;
  name: string;
}

export type VisionAttributresResponse = string[];

export interface UpdateVisionAttributeDto {
  propertyKey: string;
  propertyName: string;
  propertyValue: string;
}

export interface UpdateVisionAttributeResponse {
  id: string;
  property: string;
  name: string;
}

class ProductsService {
  constructor() {}

  async getProducts(page: number, limit: number) {
    const { data } = await httpClient.get<ProductsResponse>('/v1/products', {
      params: {
        page,
        limit,
      },
    });

    return data;
  }

  async getVisionProducts() {
    const { data } = await httpClient.get<VisionProductsResponse[]>(
      `/v1/products/get-vision-products`,
    );

    return data;
  }

  async getVisionProduct(productId: number) {
    const { data } = await httpClient.get<VisionProductResponse>(
      `/v1/products/get-vision-product/${productId}`,
    );

    return data;
  }

  async getVisionAttributes(params: VisionAttributresParams) {
    const { data } = await httpClient.get<VisionAttributresResponse>(
      '/v1/products/get-vision-attributes',
      {
        params,
      },
    );

    return data;
  }

  async updateVisionAttribute(
    externalProductId: string,
    updateVisionAttributeDto: UpdateVisionAttributeDto,
  ) {
    const { data } = await httpClient.patch<UpdateVisionAttributeResponse>(
      `/v1/products/update-vision-attribute/${externalProductId}`,
      updateVisionAttributeDto,
    );

    return data;
  }

  async getProductsRecommendation(
    queryText: string,
    page: number,
    limit: number,
  ) {
    const { data } = await httpClient.get('/v1/products/get-recommendation', {
      params: {
        queryText,
        page,
        limit,
      },
    });

    return data;
  }
}

export default new ProductsService();
