import { zodResolver } from '@hookform/resolvers/zod';
import { ElementRef, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useGetSeo } from '@/hooks/useGetSeo';
import { useUpdateSeo } from '@/hooks/useUpdateSeo';

import { ConfirmModal } from './ConfirmModal';
import { FormCheckbox } from './FormCheckbox';
import { IntegrationSwitch } from './IntegrationSwitch';

const integrationFormSchema = z.object({
  productName: z.boolean(),
  productDescription: z.boolean(),
  altDescriptionProduct: z.boolean(),
  productTags: z.boolean(),
  altDescriptionImage1: z.boolean(),
  altDescriptionImage2: z.boolean(),
  altDescriptionImage3: z.boolean(),
  altDescriptionImage4: z.boolean(),
  altDescriptionImage5: z.boolean(),
  altDescriptionImage6: z.boolean(),
  altDescriptionImage7: z.boolean(),
  altDescriptionImage8: z.boolean(),
  altDescriptionImage9: z.boolean(),
  altDescriptionImage10: z.boolean(),
});

export type IntegrationFormData = z.infer<typeof integrationFormSchema>;

export function IntegrationForm() {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const submitButtonRef = useRef<ElementRef<'button'>>(null);

  const { seo } = useGetSeo();

  const { updateSeo } = useUpdateSeo();

  const { control, handleSubmit } = useForm<IntegrationFormData>({
    resolver: zodResolver(integrationFormSchema),
    defaultValues: {
      productName: !!seo?.productName,
      productDescription: !!seo?.productDescription,
      altDescriptionProduct: !!seo?.altDescriptionProduct,
      productTags: !!seo?.productTags,
      altDescriptionImage1: !!seo?.altDescriptionImage1,
      altDescriptionImage2: !!seo?.altDescriptionImage2,
      altDescriptionImage3: !!seo?.altDescriptionImage3,
      altDescriptionImage4: !!seo?.altDescriptionImage4,
      altDescriptionImage5: !!seo?.altDescriptionImage5,
      altDescriptionImage6: !!seo?.altDescriptionImage6,
      altDescriptionImage7: !!seo?.altDescriptionImage7,
      altDescriptionImage8: !!seo?.altDescriptionImage8,
      altDescriptionImage9: !!seo?.altDescriptionImage9,
      altDescriptionImage10: !!seo?.altDescriptionImage10,
    },
  });

  const handleToggleConfirmModal = useCallback(() => {
    setIsConfirmModalOpen((state) => !state);
  }, []);

  const handleClickSubmitButton = useCallback(() => {
    submitButtonRef.current?.click();
    setIsConfirmModalOpen(!isConfirmModalOpen);
  }, [isConfirmModalOpen]);

  const handleIntegration = handleSubmit(async (data) => {
    await updateSeo({
      ...data,
      sync: !seo?.sync,
    });
  });

  if (!seo) {
    return null;
  }

  return (
    <>
      <form className="space-y-4" onSubmit={handleIntegration} noValidate>
        <IntegrationSwitch
          isActive={!!seo?.sync}
          onToggleConfirmModal={handleToggleConfirmModal}
        />

        <div className="mt-4 grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">
          <FormCheckbox
            name="productName"
            label="Product Name"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="productDescription"
            label="Product Description"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionProduct"
            label="Alt Description Product"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="productTags"
            label="Product Tags"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage1"
            label="Alt Description Image 1"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage2"
            label="Alt Description Image 2"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage3"
            label="Alt Description Image 3"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage4"
            label="Alt Description Image 4"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage5"
            label="Alt Description Image 5"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage6"
            label="Alt Description Image 6"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage7"
            label="Alt Description Image 7"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage8"
            label="Alt Description Image 8"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage9"
            label="Alt Description Image 9"
            disabled={seo.sync}
            control={control}
          />

          <FormCheckbox
            name="altDescriptionImage10"
            disabled={seo.sync}
            label="Alt Description Image 10"
            control={control}
          />
        </div>

        <button ref={submitButtonRef} type="submit" className="hidden" />
      </form>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        isSyncEnabled={seo.sync}
        onToggle={handleToggleConfirmModal}
        onConfirm={handleClickSubmitButton}
      />
    </>
  );
}
