import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { queryClient } from '@/lib/queryClient';
import AuthService from '@/services/AuthService';

export const GET_ME_QUERY_KEY = ['me'];

export function useGetMe(enabled: boolean) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: GET_ME_QUERY_KEY,
    enabled,
    queryFn: () => AuthService.me(),
  });

  const refetchUser = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: GET_ME_QUERY_KEY,
    });

    await refetch();
  }, [refetch]);

  return {
    user: data,
    isUserLoading: isFetching,
    getUser: refetch,
    refetchUser,
  };
}
