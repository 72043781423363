import { Filters } from './components/Filters';
import { RecommendedProducts } from './components/RecommendedProducts';
import { SearchForm } from './components/SearchForm';
import { StylistAiProvider } from './contexts/StylistAiContext';

export function StylistAi() {
  return (
    <StylistAiProvider>
      <div className="flex-1 overflow-auto px-8 pb-8 pt-[130px]">
        <div className="space-y-8">
          <div className="max-w-3xl space-y-8">
            <SearchForm />
            <Filters />
          </div>

          <RecommendedProducts />
        </div>
      </div>
    </StylistAiProvider>
  );
}
