import { Pencil, ThumbsDown, ThumbsUp } from 'lucide-react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import { Button } from '@/components/ui/Button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/Select';
import { Textarea } from '@/components/ui/Textarea';
import { useCreateFeedback } from '@/hooks/useCreateFeedback';
import { useGetFeedbackByProductId } from '@/hooks/useGetFeedbackByProductId';
import { useGetVisionAttributes } from '@/hooks/useGetVisionAttributes';
import { useUpdateVisionAttribute } from '@/hooks/useUpdateVisionAttribute';
import { capitalize, cn } from '@/lib/utils';

import { FeedbackModal } from './FeedbackModal';

const editableProperties = ['sales_support', 'suporte_a_vendas', 'pairs_with'];

interface AttributeItemProps {
  productId: number;
  externalProductId: string;
  attributeId: string;
  attributeProperty: string;
  attributeName: string;
  propertyKey: string;
  visionCategory: string;
}

export function AttributeItem({
  productId,
  externalProductId,
  attributeId,
  attributeName,
  attributeProperty,
  propertyKey,
  visionCategory,
}: AttributeItemProps) {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState(attributeName);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSelectable = !editableProperties.includes(propertyKey);

  const { visionAttributes } = useGetVisionAttributes(attributeId, {
    visionCategory,
    name: attributeProperty,
    property: propertyKey,
  });

  const formattedVisionAttributes = visionAttributes?.find(
    (attr) => attr === attributeName,
  )
    ? [
        attributeName,
        ...visionAttributes.filter((attr) => attr !== attributeName),
      ]
    : visionAttributes;

  const { updateVisionAttribute, isLoading: isUpdatingVisionAttribute } =
    useUpdateVisionAttribute(productId, externalProductId);

  const { createFeedback, isLoading } = useCreateFeedback(
    productId,
    attributeId,
  );

  const { feedback } = useGetFeedbackByProductId(productId);

  const currentFeedback = feedback.find(
    (item) => item.attributeId === attributeId,
  );

  const formattedAttributeProperty = capitalize(
    attributeProperty.replaceAll?.('_', ' ')?.toLowerCase(),
  );

  const isVotedUp = Boolean(
    currentFeedback?.upVoteDate && !currentFeedback.downVoteDate,
  );

  const isVotedDown = Boolean(
    !currentFeedback?.upVoteDate && currentFeedback?.downVoteDate,
  );

  function handleChangeText(event: ChangeEvent<HTMLTextAreaElement>) {
    setText(event.target.value);
  }

  async function handleAttributeChange(value: string) {
    try {
      await updateVisionAttribute({
        propertyKey: propertyKey,
        propertyName: attributeProperty,
        propertyValue: value,
      });

      toast.success('Attribute updated successfully!');
      setEditMode(false);
    } catch {
      toast.error('Failed to update attribute!');
    }
  }

  function handleToggleEditMode() {
    setEditMode(!editMode);
  }

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen((state) => !state);
  }, []);

  async function saveFeedback(
    vote: 'up' | 'down',
    comments: string | undefined = '',
  ) {
    const isUp = vote === 'up';

    await createFeedback({
      productId,
      downVote: !isUp,
      upVote: isUp,
      attributeId: attributeId,
      comments,
    });
  }

  function handleVoteUp() {
    saveFeedback('up');
  }

  useEffect(() => {
    if (!editMode) {
      setText(attributeName);
    }
  }, [attributeName, editMode]);

  return (
    <>
      <div className="flex items-center gap-2 border-b border-solid border-[#C3C3C3] px-5 py-3">
        <dt className="w-[200px] text-sm font-bold">
          {formattedAttributeProperty}
        </dt>
        <dd className="flex-1 text-sm">
          {!editMode && attributeName}

          {editMode && isSelectable && (
            <Select
              value={attributeName}
              onValueChange={handleAttributeChange}
              disabled={isUpdatingVisionAttribute}
            >
              <SelectTrigger className="max-w-[400px]">
                <SelectValue placeholder="Select attribute" />
              </SelectTrigger>
              <SelectContent>
                {formattedVisionAttributes?.map((visionAttribute) => (
                  <SelectItem key={visionAttribute} value={visionAttribute}>
                    {visionAttribute}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}

          {editMode && !isSelectable && (
            <div className="space-y-2">
              <Textarea value={text} onChange={handleChangeText} />

              <div className="flex gap-1">
                <Button size="sm" onClick={() => handleAttributeChange(text)}>
                  Save
                </Button>

                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleToggleEditMode}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </dd>

        <div className="flex items-center gap-2">
          {(!isSelectable ||
            (visionAttributes && visionAttributes.length > 0)) && (
            <button onClick={handleToggleEditMode}>
              <Pencil size={16} />
            </button>
          )}

          <button disabled={isLoading || isVotedUp}>
            <ThumbsUp
              size={16}
              className={cn(isVotedUp && 'fill-blue-500')}
              onClick={handleVoteUp}
            />
          </button>

          <button disabled={isLoading || isVotedDown}>
            <ThumbsDown
              className={cn(isVotedDown && 'fill-red-500')}
              size={16}
              onClick={handleOpenModal}
            />
          </button>
        </div>
      </div>

      <FeedbackModal
        isOpen={isModalOpen}
        attributeProperty={formattedAttributeProperty}
        isLoading={isLoading}
        onOpenChange={handleToggleModal}
        onClose={handleCloseModal}
        onFeedback={saveFeedback}
      />
    </>
  );
}
