import { zodResolver } from '@hookform/resolvers/zod';
import { produce } from 'immer';
import { LoaderCircle } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { Button } from '@/components/ui/Button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/Card';
import { ROUTE_PATHS } from '@/config/constants';
import { useAudienceSetup } from '@/hooks/useAudienceSetup';
import { AudienceCalculateResponse } from '@/services/AudiencesService';

import { AudienceName } from './components/AudienceName';
import { AudienceSliderControl } from './components/AudienceSliderControl';
import { CalculateButton } from './components/CalculateButton';
import { CSVUpload } from './components/CSVUpload';
import { CustomPreferences } from './components/CustomPreferences';
import { InputType } from './components/InputType';
import { ProductList } from './components/ProductList';
import { DiscountFilter } from './components/ProductListFilters';

export type AppliedFilters = {
  brokenStock: {
    active: boolean;
  };
  discount: {
    type: DiscountFilter;
    range: {
      from: number;
      to: number;
    };
    active: boolean;
  };
  price: {
    range: {
      from: number;
      to: number;
    };
    active: boolean;
  };
  lowStock: {
    stockLimit: number;
    active: boolean;
  };
};

export const appliedFiltersInitialState: AppliedFilters = {
  brokenStock: {
    active: false,
  },
  discount: {
    type: DiscountFilter.ALL,
    range: {
      from: 0,
      to: 0,
    },
    active: false,
  },
  price: {
    range: {
      from: 0,
      to: 0,
    },
    active: false,
  },
  lowStock: {
    stockLimit: 0,
    active: false,
  },
};

const audienceSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  inputType: z.enum(['CUSTOM', 'PRODUCTS', 'USERS']),
  inputValue: z.string().min(1, { message: 'Value is required' }),
  file: z
    .union([
      z.instanceof(File).refine((file) => file.type === 'text/csv', {
        message: 'File must be a CSV',
      }),
      z.null(),
    ])
    .refine((file) => (file ? file.size <= 10 * 1024 * 1024 : true), {
      message: 'File must be less than 10MB',
    }),
});

export type AudienceFormData = z.infer<typeof audienceSchema>;

export enum InputTypes {
  CUSTOM = 0,
  PRODUCTS = 1,
  USERS = 2,
}

export type CalculatedProducts = AudienceCalculateResponse['products'];

export function AudiencesCreate() {
  const [audienceId, setAudienceId] = useState<number | null>(null);

  const [audienceSize, setAudienceSize] = useState({
    from: 0,
    to: 0,
    suggestion: 0,
    value: 0,
  });

  const [productsInSet, setProductsInSet] = useState({
    from: 0,
    to: 0,
    suggestion: 0,
    value: 0,
  });

  const [transformedValue, setTransformedValue] = useState<null | string>(null);

  const [isCalculated, setIsCalculated] = useState(false);

  const [isCalculating, setIsCalculating] = useState(false);

  const [unchangeCalculatedProducts, setUnchangeCalculatedProducts] =
    useState<CalculatedProducts>([]);

  const [calculatedProducts, setCalculatedProducts] =
    useState<CalculatedProducts>([]);

  const [appliedFilters, setAppliedFilters] = useState(
    appliedFiltersInitialState,
  );

  const [isFiltering, setIsFiltering] = useState(false);

  const calculatedProductsByProductsInSet = useMemo(() => {
    return [...calculatedProducts]
      .sort((a, b) => b.score - a.score)
      .slice(0, productsInSet.value);
  }, [calculatedProducts, productsInSet]);

  const form = useForm<AudienceFormData>({
    resolver: zodResolver(audienceSchema),
    mode: 'onSubmit',
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = form;

  const { audienceSetup } = useAudienceSetup();

  const navigate = useNavigate();

  const handleCalculatedProducts = useCallback(
    (products: CalculatedProducts) => {
      setCalculatedProducts(products);
    },
    [],
  );

  const handleResetAppliedFilters = useCallback(() => {
    setAppliedFilters(appliedFiltersInitialState);
  }, []);

  function handleCalculate(audienceCalculated: AudienceCalculateResponse) {
    const { audience_size, product_size } = audienceCalculated.engine;

    setAudienceId(audienceCalculated.audienceId);

    setAudienceSize({
      from: audience_size.from,
      to: audience_size.to,
      suggestion: audience_size.suggestion,
      value: audience_size.suggestion,
    });

    setProductsInSet({
      from: product_size.from,
      to: product_size.to,
      suggestion: product_size.suggestion,
      value: product_size.suggestion,
    });

    setUnchangeCalculatedProducts(audienceCalculated.products);
    setCalculatedProducts(audienceCalculated.products);
    setIsCalculated(true);
  }

  function handleIsCalculating(value: boolean) {
    setIsCalculating(value);
  }

  function handleToggleActiveCalculatedProduct(id: number, active: boolean) {
    setCalculatedProducts(
      produce((draft) => {
        draft.find((product) => product.id === id)!.active = active;
      }),
    );
  }

  function handleResetCalculate() {
    setCalculatedProducts([]);
    setIsCalculated(false);
  }

  function handleTransformedValue(value: null | string) {
    setTransformedValue(value);
  }

  function handleChangeAudienceSize(value: number) {
    setAudienceSize((state) => ({
      ...state,
      value,
    }));
  }

  function handleChangeProductsInSet(value: number) {
    setProductsInSet((state) => ({
      ...state,
      value,
    }));
  }

  async function handleCreateAudience(data: AudienceFormData) {
    if (!audienceId || isSubmitting) {
      return;
    }

    const input_type = InputTypes[data.inputType];

    await audienceSetup({
      id: audienceId,
      name: data.name,
      input_value: transformedValue || data.inputValue,
      input_type,
      audience_size: audienceSize.value,
      products_in_set: productsInSet.value,
      config_audience_score: false,
      config_productset_score: false,
      audience_products: calculatedProductsByProductsInSet.map(
        (calculatedProduct) => ({
          productId: calculatedProduct.productId,
          active: calculatedProduct.active,
        }),
      ),
      filters: appliedFilters,
    });

    navigate(ROUTE_PATHS.AUDIENCES);
  }

  return (
    <FormProvider {...form}>
      <div className="w-full py-10">
        <Card>
          <CardHeader>
            <CardTitle>Create New Strategy</CardTitle>
            <CardDescription>
              Define your audience and product set based on preferences,
              products, or users.
            </CardDescription>
          </CardHeader>

          <CardContent>
            <form
              className="space-y-8"
              onSubmit={handleSubmit(handleCreateAudience)}
            >
              <AudienceName />
              <InputType
                onResetCalculate={handleResetCalculate}
                onTransformedValue={handleTransformedValue}
              />

              <CustomPreferences />

              <CSVUpload />

              <CalculateButton
                isCalculated={isCalculated}
                isCalculating={isCalculating}
                isFiltering={isFiltering}
                onCalculate={handleCalculate}
                onIsCalculating={handleIsCalculating}
                onTransformedValue={handleTransformedValue}
              />

              {isCalculated && !isCalculating && (
                <Card>
                  <CardHeader>
                    <CardTitle>Audience Preview</CardTitle>
                    <CardDescription>
                      Adjust the audience and product set sizes to fine-tune
                      your targeting.
                    </CardDescription>
                  </CardHeader>

                  <CardContent className="space-y-6">
                    <AudienceSliderControl
                      productsInSet={productsInSet}
                      audienceSize={audienceSize}
                      onChangeProductsInSet={handleChangeProductsInSet}
                      onChangeAudienceSize={handleChangeAudienceSize}
                    />

                    <ProductList
                      unchangeCalculatedProducts={unchangeCalculatedProducts}
                      calculatedProducts={calculatedProductsByProductsInSet}
                      appliedFilters={appliedFilters}
                      isFiltering={isFiltering}
                      onFiltering={setIsFiltering}
                      onActive={handleToggleActiveCalculatedProduct}
                      onCalculatedProducts={handleCalculatedProducts}
                      onAppliedFilters={setAppliedFilters}
                      onResetAppliedFilters={handleResetAppliedFilters}
                    />

                    <Button type="submit" disabled={isFiltering}>
                      {isSubmitting ? (
                        <LoaderCircle className="animate-spin" />
                      ) : (
                        'Create Strategy'
                      )}
                    </Button>
                  </CardContent>
                </Card>
              )}
            </form>
          </CardContent>
        </Card>
      </div>
    </FormProvider>
  );
}
