import { useState } from 'react';

import { Form } from './components/Form';
import { Success } from './components/Success';

export function AdminCustomersCreate() {
  const [isSuccess, setIsSuccess] = useState(false);

  function handleSuccess(value: boolean) {
    setIsSuccess(value);
  }

  return (
    <>
      {isSuccess && <Success onSuccess={handleSuccess} />}
      {!isSuccess && <Form onSuccess={handleSuccess} />}
    </>
  );
}
