import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/Card';
import { ChangePassword } from './ChangePassword';

export function Security() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Security Settings</CardTitle>

        <CardDescription>
          Manage your account&apos;s security settings
        </CardDescription>
      </CardHeader>

      <CardContent className="space-y-6">
        <ChangePassword />
      </CardContent>
    </Card>
  );
}
