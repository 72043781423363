import { useMutation } from '@tanstack/react-query';

import { queryClient } from '@/lib/queryClient';
import { MeResponse } from '@/services/AuthService';
import CustomerService from '@/services/CustomerService';

import { GET_ME_QUERY_KEY } from './useGetMe';

export function useGenerateVtexAffiliateURL() {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: () => CustomerService.generateVtexAffiliateURL(),
    onSuccess: (data) => {
      queryClient.setQueryData<MeResponse | undefined>(
        GET_ME_QUERY_KEY,
        (state): MeResponse | undefined => {
          if (state && state.customer) {
            return {
              ...state,
              customer: {
                ...state.customer,
                vtexAffiliateUrl: data.vtexAffiliateUrl,
              },
            };
          }
        },
      );
    },
  });

  return {
    generateVtexAffiliateURL: mutateAsync,
    isLoading: isPending,
  };
}
