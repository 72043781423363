import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import AudiencesService, {
  AudienceResponse,
  UpdateAudienceDto,
} from '@/services/AudiencesService';

import { GET_AUDIENCES_QUERY_KEY } from './useGetAudiences';

export function useUpdateAudience(audienceId: number) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (updateAudienceDto: UpdateAudienceDto) =>
      AudiencesService.updateAudience(audienceId, updateAudienceDto),
    onSuccess: (data) => {
      queryClient.setQueryData<InfiniteData<AudienceResponse>>(
        GET_AUDIENCES_QUERY_KEY,
        (state) => {
          state?.pages?.forEach((page) =>
            page?.items.forEach((item) => {
              if (item.id === audienceId) {
                item.audiencePlatform.push(data);
              }

              return item;
            }),
          );

          return state;
        },
      );
    },
  });

  return {
    updateAudience: mutateAsync,
    isLoading: isPending,
  };
}
