import { httpClient } from '@/lib/httpClient';

export enum Language {
  'PT-BR' = 'PT-BR',
  'EN-US' = 'EN-US',
  'ES-CO' = 'ES-CO',
}

export enum Platform {
  NONE = 'NONE',
  VTEX = 'VTEX',
}

export interface CreateCustomerDto {
  name: string;
  platform: Platform;
  url?: string;
  language: Language;
  seoLanguage: Language;
  email: string;
  password: string;
  freemium: boolean;
  freemiumMaxProductsPerCategory?: number;
  freemiumMaxTotalProducts?: number;
  hubspotToken?: string;
  vtexAppKey?: string;
  vtexAppToken?: string;
  vtexUrl?: string;
  vtexSalesChannel?: number;
  vtexAffiliateId?: string;
}

export interface CreateCustomerResponse {
  created: boolean;
}

export interface UpdateStoreURLDto {
  storeURL: string;
}

export interface UpdateVtexCredentialsDto {
  vtexUrl: string;
  vtexAppKey: string;
  vtexAppToken: string;
  vtexSalesChannel: number;
  vtexAffiliateId: string;
}

export interface UpdateVtexCredentialsResponse {
  updated: boolean;
}

export interface UpdateHubspotTokenDto {
  hubspotToken: string;
}

export interface UpdateHubspotTokenResponse {
  updated: boolean;
}

export interface GenerateVtexAffiliateURLResponse {
  vtexAffiliateUrl: string;
}

export interface VisionTriggerDto {
  needUpdateCategoryName: boolean;
  visionAutoRun: boolean;
}

export interface VisionTriggerResponse {
  needUpdateCategoryName: boolean;
  visionAutoRun: boolean;
}

export interface CategoriesSeoResponse {
  _id: string;
  customer_id: string;
  category_id: string;
  name: string;
  description: string;
  keywords: string;
  language: string;
  title: string;
}

export interface GetCustomersInfoResponse {
  id: number;
  name: string;
}

class CustomerService {
  constructor() {}

  async create(createCustomerDto: CreateCustomerDto) {
    const { data } = await httpClient.post<CreateCustomerResponse>(
      '/v1/customer/create',
      createCustomerDto,
    );

    return data;
  }

  async updateStoreURL(updateStoreURLDto: UpdateStoreURLDto) {
    const { data } = await httpClient.patch(
      '/v1/customer/update-store-url',
      updateStoreURLDto,
    );

    return data;
  }

  async updateVtexCredentials(
    updateVtexCredentialsDto: UpdateVtexCredentialsDto,
  ) {
    const { data } = await httpClient.patch<UpdateVtexCredentialsResponse>(
      '/v1/customer/update-vtex-credentials',
      updateVtexCredentialsDto,
    );

    return data;
  }

  async updateHubspotToken(updateHubspotTokenDto: UpdateHubspotTokenDto) {
    const { data } = await httpClient.patch<UpdateHubspotTokenResponse>(
      '/v1/customer/update-hubspot-token',
      updateHubspotTokenDto,
    );

    return data;
  }

  async generateVtexAffiliateURL() {
    const { data } = await httpClient.patch<GenerateVtexAffiliateURLResponse>(
      '/v1/customer/generate-vtex-affiliate-url',
      null,
    );

    return data;
  }

  async visionTrigger(visionTriggerDto: VisionTriggerDto) {
    const { data } = await httpClient.post<VisionTriggerResponse>(
      '/v1/customer/vision-trigger',
      visionTriggerDto,
    );

    return data;
  }

  async getCategoriesSeo() {
    const { data } = await httpClient.get<CategoriesSeoResponse[]>(
      '/v1/customer/get-categories-seo',
    );

    return data;
  }

  async getCustomersInfo() {
    const { data } = await httpClient.get<GetCustomersInfoResponse[]>(
      '/v1/customer/get-customers-info',
    );

    return data;
  }
}

export default new CustomerService();
