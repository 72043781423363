export function Guide() {
  return (
    <div className="space-y-4 rounded-lg bg-gray-100 p-4">
      <h3 className="mb-2 text-lg font-semibold">
        How to set up HubSpot Integration
      </h3>

      <div className="space-y-2">
        <h4 className="mb-2 font-semibold">
          Set Up Your HubSpot Private App and Obtain an Access Token
        </h4>

        <ol className="list-inside list-decimal space-y-2 pl-4">
          <li>Log into HubSpot and go to Settings.</li>
          <li>
            In the left sidebar, navigate to Integrations &gt; Private Apps.
          </li>
          <li>Give your app a name (e.g., API List Creation App).</li>
          <li>
            Under Scopes/Permissions, select the necessary scopes for managing
            contacts and lists.
          </li>
          <li>
            Create the app and copy the Access Token that HubSpot provides.
          </li>
        </ol>
      </div>
    </div>
  );
}
