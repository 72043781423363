import { Eye, TriangleAlert } from 'lucide-react';
import { toast } from 'sonner';

import { Label } from '@/components/ui/Label';
import { Switch } from '@/components/ui/Switch';
import { useAuth } from '@/contexts/AuthContext';
import { useVisionTrigger } from '@/hooks/useVisionTrigger';
import { formatSubmissionDate } from '@/lib/utils';
import { VisionTriggerDto } from '@/services/CustomerService';

export function VisionProducts() {
  const { user } = useAuth();

  const needUpdateCategoryName = !!user?.customer.needUpdateCategoryName;
  const visionAutoRun = !!user?.customer.visionAutoRun;
  const isChecked = needUpdateCategoryName && visionAutoRun;

  const { updateNeedVisionRun } = useVisionTrigger();

  async function handleToggleAutoRun() {
    try {
      const visionTriggerDto: VisionTriggerDto = {
        needUpdateCategoryName: !needUpdateCategoryName,
        visionAutoRun: !visionAutoRun,
      };

      if (
        (!needUpdateCategoryName && visionAutoRun) ||
        (needUpdateCategoryName && !visionAutoRun)
      ) {
        visionTriggerDto.needUpdateCategoryName = false;
        visionTriggerDto.visionAutoRun = false;
      }

      const data = await updateNeedVisionRun(visionTriggerDto);

      if (data.needUpdateCategoryName && data.visionAutoRun) {
        toast('Auto Run enabled.');
      } else {
        toast('Auto Run disabled.');
      }
    } catch {
      toast.error('Interal server error');
    }
  }

  return (
    <div className="space-y-4">
      <div>
        <div className="flex items-center gap-4">
          <Label
            htmlFor="vision-toggle"
            className="flex flex-col space-y-1 text-lg font-semibold"
          >
            Vision Enrichment
          </Label>

          <Switch
            id="vision-toggle"
            checked={isChecked}
            onCheckedChange={handleToggleAutoRun}
          />
        </div>

        <p className="text-sm text-muted-foreground">
          Enable or disable automatic Vision AI processing for your products.
        </p>
      </div>

      <div className="flex items-center gap-4">
        <p className="text-xs text-muted-foreground">
          Last updated:{' '}
          <strong>
            {formatSubmissionDate(user?.customer.visionLastSubmission)}
          </strong>
        </p>

        <div className="flex items-center space-x-2">
          <Eye
            className={`h-4 w-4 ${isChecked ? 'text-green-500' : 'text-gray-400'}`}
          />

          <span
            className={`text-sm ${isChecked ? 'text-green-500' : 'text-gray-400'}`}
          >
            {isChecked ? 'Active' : 'Inactive'}
          </span>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <TriangleAlert size={20} className="text-yellow-500" />

        <p className="text-sm">
          After enabling, it may take up to 24 hours for processing to complete
          after all integrations are set up.
        </p>
      </div>
    </div>
  );
}
