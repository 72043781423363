import { useMutation } from '@tanstack/react-query';

import AuthService, { LoginDto } from '@/services/AuthService';

export function useLogin() {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (loginDto: LoginDto) => AuthService.login(loginDto),
  });

  return {
    loginFn: mutateAsync,
    isLoggingIn: isPending,
  };
}
