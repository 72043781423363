import { Affiliate } from './Affiliate';
import { Form } from './Form';
import { PermissionsTable } from './PermissionsTable';
import { Tab } from '../Tab';

export function Vtex() {
  return (
    <Tab
      title="VTEX Settings"
      description="Enter your VTEX API credentials for data synchronization"
    >
      <PermissionsTable />
      <Affiliate />
      <Form />
    </Tab>
  );
}
