import { useQuery } from '@tanstack/react-query';

import CustomerService from '@/services/CustomerService';

export const GET_CATEGORIES_SEO_QUERY_KEY = ['categories-seo'];

export function useGetCategoriesSeo() {
  const { data, isFetching } = useQuery({
    queryKey: GET_CATEGORIES_SEO_QUERY_KEY,
    queryFn: () => CustomerService.getCategoriesSeo(),
  });

  return {
    categoriesSeo: data ?? [],
    isLoading: isFetching,
  };
}
