import { Download, FileText, LoaderCircle, Upload, X } from 'lucide-react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/Button';
import { CardDescription } from '@/components/ui/Card';
import { Input } from '@/components/ui/Input';
import { Label } from '@/components/ui/Label';
import { cn } from '@/lib/utils';

import { AudienceFormData } from '..';

export function CSVUpload() {
  const [isLoading, setIsLoading] = useState(false);

  const { watch, setValue } = useFormContext<AudienceFormData>();

  const inputType = watch('inputType');
  const file = watch('file');

  function handleDownloadCSVExample() {
    const url =
      inputType === 'PRODUCTS'
        ? 'https://storage.googleapis.com/fashion-audiences-dev/product_id_example.csv'
        : 'https://storage.googleapis.com/fashion-audiences-dev/user_id_example.csv';

    window.open(url, '_blank');
  }

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];

    if (!file) return;

    setIsLoading(true);

    try {
      await file.text();
      setValue('file', file);
      setValue('inputValue', file.name);
    } catch (error) {
      console.error('Error reading file:', error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleRemoveFile() {
    setValue('file', null);
  }

  if (!inputType || inputType === 'CUSTOM') {
    return null;
  }

  return (
    <div className="space-y-2">
      <Label>{inputType === 'PRODUCTS' ? 'Product List' : 'User List'}</Label>

      <CardDescription className="mb-2">
        {inputType === 'PRODUCTS'
          ? 'Select specific products to create an audience based on interest or engagement with those items. We analyze product attributes, design features, and customer preferences to find users with similar interests, allowing for precise targeting based on your selected product set.'
          : 'Upload or define a list of users who share common behaviors or preferences. We leverage these user profiles to generate the best product set and a new audience with comparable characteristics, preferences, and shopping patterns, helping you engage customers with similar profiles or buying habits.'}
      </CardDescription>

      {file && (
        <div className="flex items-center justify-between rounded-lg bg-muted p-4">
          <div className="flex items-center space-x-2">
            <FileText className="h-6 w-6 text-primary" />
            <span className="font-medium">{file.name}</span>
          </div>
          <Button variant="ghost" size="sm" onClick={handleRemoveFile}>
            <X className="h-4 w-4" />
            <span className="sr-only">Remove file</span>
          </Button>
        </div>
      )}

      {!file && (
        <div className="flex w-full items-center justify-center">
          <label
            htmlFor="file-upload"
            className={cn(
              'flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed bg-muted',
              !isLoading && 'hover:bg-muted/50',
            )}
          >
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              {!isLoading && (
                <>
                  <Upload className="mb-4 h-8 w-8 text-muted-foreground" />

                  <p className="mb-2 text-sm text-muted-foreground">
                    <span className="font-semibold">Click to upload</span>
                  </p>
                  <p className="text-xs text-muted-foreground">
                    CSV or Excel file (max. 10MB)
                  </p>
                </>
              )}

              {isLoading && <LoaderCircle className="animate-spin" />}
            </div>

            <Input
              id="file-upload"
              type="file"
              className="hidden"
              accept=".csv"
              onChange={handleFileChange}
            />
          </label>
        </div>
      )}

      <div className="flex justify-end">
        <Button variant="outline" size="sm" onClick={handleDownloadCSVExample}>
          <Download className="mr-2 h-4 w-4" />
          Download Sample File
        </Button>
      </div>
    </div>
  );
}
