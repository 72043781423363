import { X, ShieldAlert } from 'lucide-react';
import { toast } from 'sonner';

import { cn } from '@/lib/utils';

interface ToastProps {
  id: number | string;
  title: string;
  description: string;
  variant?: 'normal' | 'error';
}

export function Toast({
  id,
  title,
  description,
  variant = 'normal',
}: ToastProps) {
  return (
    <div
      className={cn(
        'flex flex-col gap-[10px] rounded-lg px-10 py-5 text-xs shadow-2xl',
        variant === 'normal' && 'bg-white text-[#494949]',
        variant === 'error' && 'bg-[#EA4335] text-white',
      )}
    >
      <div className="flex items-center justify-between">
        <p className="flex items-center gap-[10px]">
          <ShieldAlert /> {title}
        </p>

        <button onClick={() => toast.dismiss(id)}>
          <X />
        </button>
      </div>

      <p>{description}</p>
    </div>
  );
}
