import enWelcome from '@/locales/en/welcome.json';
import esWelcome from '@/locales/es/welcome.json';
import ptWelcome from '@/locales/pt/welcome.json';

export const resources = {
  en: {
    welcome: enWelcome,
  },
  pt: {
    welcome: ptWelcome,
  },
  es: {
    welcome: esWelcome,
  },
};
