import { httpClient } from '@/lib/httpClient';

export enum Status {
  Active = 1,
  Inactive = 2,
}

export enum Role {
  User = 2,
  Admin = 3,
}

export interface CreateUserDto {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: {
    id: Role.User | Role.Admin;
  };
  status: {
    id: Status.Active | Status.Inactive;
  };
  customer: {
    id: number;
  };
}

export interface CreateUserResponse {
  created: boolean;
}

class UserService {
  constructor() {}
  async create(createUserDto: CreateUserDto) {
    const { data } = await httpClient.post<CreateUserDto>(
      `/v1/users`,
      createUserDto,
    );
    return data;
  }
}

export default new UserService();
